/* ======================== */
/*   Syed Sahar Ali Raza    */
/* ======================== */
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

//body {
//  background-color: #eee;
//}

//#generic_price_table {
//  background-color: #f0eded;
//}

/*PRICE COLOR CODE START*/

#generic_price_table {
  .generic_content {
    background-color: #fff;

    .generic_head_price {
      background-color: #f6f6f6;

      .generic_head_content {
        .head_bg {
          border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
        }

        .head span {
          color: #525252;
        }
      }

      .generic_price_tag {
        .price {
          .sign, .currency, .cent {
            color: #414141;
          }
        }

        .month {
          color: #414141;
        }
      }
    }

    .generic_feature_list ul li {
      color: #a7a7a7;

      span {
        color: #414141;
      }

      &:hover {
        background-color: #E4E4E4;
        border-left: 5px solid #20aee5;
      }
    }

    &.basic .generic_feature_list ul li:hover {
      background-color: #E4E4E4;
      border-left: 5px solid #f61d6a;
    }

    .generic_price_btn a {
      border: 1px solid #20aee5;
      color: #20aee5;
    }

    &.basic .generic_price_btn a {
      border: 1px solid #f61d6a;
      color: #f61d6a;
    }

    &.advance .generic_price_btn a {
      border: 1px solid #20aee5;
      color: #20aee5;
    }

    &.basic .generic_head_price .generic_head_content .head_bg {
      border-color: #f61d6a rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f61d6a;
      color: #fff;
    }

    &.advance .generic_head_price .generic_head_content .head_bg {
      border-color: #20aee5 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #20aee5;
      color: #fff;
    }

    &.active .generic_head_price .generic_head_content .head_bg {
      border-color: #2ECC71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ECC71;
      color: #fff;
    }

    &.generic_head_price .generic_head_content .head span, &.basic .generic_head_price .generic_head_content .head span, &.advance .generic_head_price .generic_head_content .head span, &.active .generic_head_price .generic_head_content .head span {
      color: #fff;
    }

    &:hover .generic_price_btn a {
      background-color: #20aee5;
      color: #fff;
    }

    &.basic .generic_price_btn a {
      background-color: #f61d6a;
      color: #fff;
    }

    &.advance .generic_price_btn a {
      background-color: #20aee5;
      color: #fff;
    }

    &.active .generic_price_btn a {
      background-color: #2ECC71;
      color: #fff;
    }
  }

  & {
    margin: 50px 0 50px 0;
    font-family: 'Raleway', sans-serif;
  }
}

.row .table {
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;

  .generic_head_price {
    margin: 0 0 20px 0;

    .generic_head_content {
      margin: 0 0 50px 0;

      .head_bg {
        border-style: solid;
        border-width: 90px 1411px 23px 399px;
        position: absolute;
      }

      .head {
        padding-top: 40px;
        position: relative;
        z-index: 1;

        span {
          font-family: "Raleway", sans-serif;
          font-size: 28px;
          font-weight: 400;
          letter-spacing: 2px;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
        }
      }
    }

    .generic_price_tag {
      padding: 0 0 20px;

      .price {
        display: block;

        .sign {
          display: inline-block;
          font-family: "Lato", sans-serif;
          font-size: 28px;
          font-weight: 400;
          vertical-align: middle;
        }

        .currency {
          font-family: "Lato", sans-serif;
          font-size: 60px;
          font-weight: 300;
          letter-spacing: -2px;
          line-height: 60px;
          padding: 0;
          vertical-align: middle;
        }

        .cent {
          display: inline-block;
          font-family: "Lato", sans-serif;
          font-size: 24px;
          font-weight: 400;
          vertical-align: bottom;
        }
      }

      .month {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 3px;
        vertical-align: bottom;
      }
    }
  }

  .generic_feature_list ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-family: "Lato", sans-serif;
      font-size: 18px;
      padding: 15px 0;
      transition: all 0.3s ease-in-out 0s;

      &:hover {
        transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        -webkit-transition: all 0.3s ease-in-out 0s;
      }

      .fa {
        padding: 0 10px;
      }
    }
  }

  .generic_price_btn {
    margin: 20px 0 32px;

    a {
      border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      -webkit-border-radius: 50px;
      display: inline-block;
      font-family: "Lato", sans-serif;
      font-size: 18px;
      outline: medium none;
      padding: 12px 30px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  & {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }

  &:hover, .generic_head_price .generic_head_content .head_bg, &:hover .generic_head_price .generic_head_content .head_bg, .generic_head_price .generic_head_content .head h2, &:hover .generic_head_price .generic_head_content .head h2, .price, &:hover .price, .generic_price_btn a, &:hover .generic_price_btn a {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }
}

@media (max-width: 320px) {
}

@media (max-width: 767px) {
  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table {
    .col-md-3, .col-md-4 {
      float: left;
      width: 50%;
    }

    .generic_content {
      margin-bottom: 75px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) {
}

#generic_price_table_home {
  font-family: 'Raleway', sans-serif;
}

.text-center h1 {
  color: #7885CB;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;

  a {
    color: #7885CB;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
  }
}

.demo-pic {
  margin: 0 auto;

  &:hover {
    opacity: 0.7;
  }
}

#generic_price_table_home {
  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
  }

  li {
    float: left;

    + li {
      margin-left: 10px;
      padding-bottom: 10px;
    }

    a {
      display: block;
      width: 50px;
      height: 50px;
      font-size: 0px;
    }
  }

  .blue {
    background: #3498DB;
    transition: all 0.3s ease-in-out 0s;
  }

  .emerald {
    background: #2ECC71;
    transition: all 0.3s ease-in-out 0s;
  }

  .grey {
    background: #7F8C8D;
    transition: all 0.3s ease-in-out 0s;
  }

  .midnight {
    background: #34495E;
    transition: all 0.3s ease-in-out 0s;
  }

  .orange {
    background: #E67E22;
    transition: all 0.3s ease-in-out 0s;
  }

  .purple {
    background: #9B59B6;
    transition: all 0.3s ease-in-out 0s;
  }

  .red {
    background: #E74C3C;
    transition: all 0.3s ease-in-out 0s;
  }

  .turquoise {
    background: #1ABC9C;
    transition: all 0.3s ease-in-out 0s;
  }

  .blue:hover, .emerald:hover, .grey:hover, .midnight:hover, .orange:hover, .purple:hover, .red:hover, .turquoise:hover {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    transition: all 0.3s ease-in-out 0s;
  }

  .divider {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;

    span {
      width: 100%;
      display: table;
      height: 2px;
      background: #ddd;
      margin: 50px auto;
      line-height: 2px;
    }
  }

  .itemname {
    text-align: center;
    font-size: 50px;
    padding: 50px 0 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 300;
  }

  .itemnametext {
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
  }

  .footer {
    padding: 40px 0;
  }
}

.price-heading {
  text-align: center;

  h1 {
    color: #666;
    margin: 0;
    padding: 0 0 50px 0;
  }
}

.demo-button {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  outline-color: -moz-use-text-color;
  outline-style: none;
  outline-width: medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.bottom_btn {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 28px;
  margin: 60px auto 20px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
}

.demo-button:hover, .bottom_btn:hover {
  background-color: #666;
  color: #FFF;
  text-decoration: none;
}

.u-price-container {
  max-width: 750px;
  margin: 100px auto;
  font: 16px Helvetica;
  background: rgba(236, 240, 241,1.0);
}

.u-price-container h1 {
  margin: 20px 0 10px 0;
  font-size: 1.25em;
  color: rgba(0,0,0,0.8);
}

.u-price-container h2 {
  font-size: .75em;
  color: rgba(0,0,0,.6);
  font-weight: 100;
  letter-spacing: 1px;
}

.u-price-container p {
  color: rgba(0,0,0,.4);
  margin: 10px 0;
  font-weight: 100;
  font-size: .75em;
}

.u-price-container span {
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: inline-block;
  width: 125px;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 1px;
  color: rgba(0,0,0,.5);
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.u-price-container .price {
  height: 100px;
  width: 100px;
  text-align: center;
  background-color: rgba(231, 76, 60,1.0);
  border-radius: 50%;
  line-height: 100px;
  color: #fff;
  font-size: 1.5em;
  font-weight: 100;
  margin: 20px auto;
}

.u-price-container button {
  display: block;
  margin: 20px auto;
  width: 150px;
  height: 35px;
  background: rgba(231, 76, 60,1.0);
  border: none;
  border-radius: 5px;
  color: rgba(255,255,255,1);
  font-size: .75em;
  font-weight: 100;
  transition: all ease-in-out .2s;

  &:hover {
    background: rgba(192, 57, 43,1.0);
  }
}

.u-price-silver, .plat {
  float: left;
  width: 250px;
  background: rgba(255,255,255,1);
  text-align: center;
}

.u-price-gold {
  float: left;
  position: relative;
  width: 250px;
  top: -50px;
  padding: 50px 0;
  background: rgba(52, 152, 219,1.0);
  text-align: center;
  border-radius: 5px;

  & > .price {
    background: rgba(255,255,255,1);
    color: rgba(0,0,0,.7);
  }

  & > h1, & > h2, & > p, & > span {
    color: rgba(255,255,255,1);
  }
}