.p-news-01 {
  &__section-lv1 {
    position: relative;
    padding-bottom: 60px;

    @include mq {
      padding-top: 180px;
    }
    @include mq(medium, max) {
      z-index: 1;
    }

    &__inner {
      @include mq(medium, max) {
        padding-top: 70px;
      }
    }

    &__panel-lv1 {
      @include mq {
        margin-top: 60px;
        @include pc-inner-width;
        @include auto;
        display: flex;
      }
      @include mq(medium, max) {
        margin-top: 30px;
        @include auto;
        @include sp-inner(p);
        display: block;
      }

      &__thumbnail {
        position: relative;
        @include mq {
          width: 50%;
          margin-right: 30px;
        }

        &__img {
          @include mq {
            overflow: hidden;
            transition: 0.5s;
          }

          &:hover {
            border-radius: 50px;
          }

          img {
            @include mq {
              display: block;
              transition-duration: 0.3s; /*変化に掛かる時間*/
              -moz-transition: -moz-transform 0.5s linear;
              -webkit-transition: -webkit-transform 0.5s linear;
              -o-transition: -o-transform 0.5s linear;
              transition: transform 0.5s linear;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            &:hover {
              @include mq {
                transform: scale(1.2);
              }
            }
          }
        }

        &__item {
          @include mq {
            width: 60px;
            height: 60px;
            @include box;
            background: url("../images/new_icon.svg");
            background-size: cover;
            position: absolute;
            z-index: 3;
            right: -20px;
            top: -20px;
          }
          @include mq(medium, max) {
            width: 50px;
            height: 50px;
            @include box;
            background: url("../images/new_icon.svg");
            background-size: cover;
            position: absolute;
            z-index: 3;
            right: -20px;
            top: -20px;
          }
        }
      }

      &__box {
        @include mq {
          position: relative;
          width: 50%;
        }
        @include mq(medium, max) {
          margin-top: 20px;
        }

        &__inner {
          @include mq {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &__title {
          @include mq {
            font-size: 2.4rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          color: $color-lv7;
          @include mq {
            @include default-height;
            display: inline-block;
          }
          @include mq(medium, max) {
            @include default-height;
            display: inline-block;
          }

          &:nth-of-type(1) {
            @include mq {
              // margin-top: 30px;
              line-height: 1.0;
              display: -webkit-inline-box;
              margin-top: 10px;
              padding-right: 5px;
            }
            @include mq(medium, max) {
              line-height: 1.0;
              display: -webkit-inline-box;
              margin-top: 10px;
              padding-right: 5px;
            }
          }

          &:not(:nth-of-type(1)) {
            @include mq {
              line-height: 1.0;
              display: -webkit-inline-box;
              margin-top: 12px;
              padding-right: 10px;
            }
            @include mq(medium, max) {
              line-height: 1.0;
              display: -webkit-inline-box;
              margin-top: 12px;
              padding-right: 10px;
            }
          }


          &::before {
            @include mq {
              content: "#";
              color: $default-black;
              margin-right: 2px;
            }
            @include mq(medium, max) {
              content: "#";
              color: $default-black;
              margin-right: 2px;
            }
          }
        }

        &__item-lv2 {
          font-style: italic;
          @include mq {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }

    &__slider {
      @include mq {
        margin-top: 50px;
        @include auto;
      }
      @include mq(medium, max) {
        margin-top: 30px;
        @include auto;
      }

      &__box {
        //@include mq {
        //    //width: calc(calc(1200px / 4) - calc(140px / 3));
        //    //display: flex;
        //}

        //&:not(:last-child) {
        //    @include mq {
        //        margin-right: 35px;
        //    }
        //}

        &__thumbnail {
          position: relative;
          @include mq {
            margin-right: 20px;
          }

          &__img {
            @include mq {
              margin-right: 30px;
              overflow: hidden;
              transition: 0.5s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            &:hover {
              border-radius: 50px;
            }

            img {
              @include mq {
                display: block;
                transition-duration: 0.3s; /*変化に掛かる時間*/
                -moz-transition: -moz-transform 0.5s linear;
                -webkit-transition: -webkit-transform 0.5s linear;
                -o-transition: -o-transform 0.5s linear;
                transition: transform 0.5s linear;
              }
              @include mq(medium, max) {
                border-radius: 25px;
                display: block;
                height: 222px;
                padding: 7px;
              }

              &:hover {
                @include mq {
                  transform: scale(1.2);
                }
              }
            }

            &__new {
              @extend .p-news-01__section-lv1__slider__box__thumbnail;

              &:after {
                width: 60px;
                height: 60px;
                @include box;
                background: url("../images/new_icon.svg");
                background-size: cover;
                position: absolute;
                z-index: 3;
                right: -20px;
                top: -20px;
              }
            }
          }

          &__item {
            @include mq {
              width: 60px;
              height: 60px;
              @include box;
              background: url("../images/new_icon.svg");
              background-size: cover;
              position: absolute;
              z-index: 3;
              right: -20px;
              top: -20px;
            }
            @include mq(medium, max) {
              width: 50px;
              height: 50px;
              @include box;
              background: url("../images/new_icon.svg");
              background-size: cover;
              position: absolute;
              z-index: 3;
              right: -20px;
              top: -20px;
            }
          }
        }

        &__content {
          @include mq {
            padding: 25px;
          }
          @include mq(medium, max) {
            // padding-top: 20px;
            padding: 12px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          color: $color-lv7;
          @include mq {
            // display: block;
            // margin-top: 35px;
            display: -webkit-inline-box;
            margin-top: 25px;
            padding-right: 10px;
          }
          @include mq(medium, max) {
            @include default-height;
            // display: block;
            // margin-top: 30px;
            line-height: 1.0;
            display: -webkit-inline-box;
            margin-top: 12px;
            padding-right: 10px;
          }

          &::before {
            @include mq {
              content: "#";
              color: $default-black;
              margin-right: 2px;
            }
            @include mq(medium, max) {
              content: "#";
              color: $default-black;
              margin-right: 2px;
            }
          }
        }

        &__item-lv2 {
          font-style: italic;
          @include mq {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }
  }

  &__section-lv2 {
    position: relative;
    @include mq {
      padding-top: 180px;
    }
    @include mq(medium, max) {
    }

    &__inner {
      @include mq(medium, max) {
        padding-top: 70px;
      }
    }

    &__list-lv1 {
      @include mq {
        margin-top: 60px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__inner {
        @include mq {
          @include pc-inner-width;
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
          background-color: #f7f8fa;
          position: relative;
          z-index: 2;
        }

        &--lv2 {
          @include mq {
            left: -600px;
            position: relative;
          }
        }
      }

      &__box {
        @include mq {
          width: calc(calc(100% / 3) - 20px);
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:not(:nth-of-type(3n)) {
          @include mq {
            margin-right: 30px;
          }
        }

        &:nth-of-type(n+4) {
          @include mq {
            margin-top: 45px;
          }
        }

        @for $number from 1 through 12 {
          &:nth-of-type(#{$number}) {
            .p-news-01__section-lv2__list-lv1__box__thumbnail__item {
              &::before {
                @include mq {
                  @include box;
                  width: 50px;
                  height: 50px;
                  content: "#{$number}";
                  font-size: 4rem;
                  @include bold;
                }
              }
            }
          }
        }

        &__thumbnail {
          position: relative;
          @include mq {
            margin-right: 30px;
          }

          &__img {
            @include mq {
              overflow: hidden;
              transition: 0.5s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            &:hover {
              border-radius: 50px;
            }

            img {
              @include mq {
                display: block;
                transition-duration: 0.3s; /*変化に掛かる時間*/
                -moz-transition: -moz-transform 0.5s linear;
                -webkit-transition: -webkit-transform 0.5s linear;
                -o-transition: -o-transform 0.5s linear;
                -ms-transition: -ms-transform 0.5s linear;
                transition: transform 0.5s linear;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }

              &:hover {
                @include mq {
                  transform: scale(1.2);
                }
              }
            }

            &__new {
              @extend .p-news-01__section-lv2__list-lv1__box__thumbnail__img;

              &:after {
                @include mq {
                  width: 60px;
                  height: 60px;
                  @include box;
                  background: url("../images/new_icon.svg");
                  background-size: cover;
                  position: absolute;
                  z-index: 3;
                  left: 340px;
                  top: -20px;
                }
                @include mq(medium, max) {
                  width: 60px;
                  height: 60px;
                  @include box;
                  background: url("../images/new_icon.svg");
                  background-size: cover;
                  position: absolute;
                  z-index: 3;
                  left: 340px;
                  top: -20px;
                }
              }
            }
          }

          &__item {
            position: absolute;
            left: 20px;
            top: 10px;
          }
        }

        &__content {
          @include mq {
            padding: 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          color: $color-lv7;
          @include mq {
            margin-top: 25px;
            display: -webkit-inline-box;
            padding-right: 14px;
            @include default-height;
          }
          @include mq(medium, max) {
            @include default-height;
            display: -webkit-inline-box;
            margin-top: 10px;
            padding-right: 5px;
          }

          &::before {
            content: "#";
            color: $default-black;
            margin-right: 2px;
          }
        }

        &__item-lv2 {
          font-style: italic;
          @include mq {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }

    &__list-lv2 {
      @include mq {
        margin-top: 70px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__inner {
        @include mq {
          display: flex;
          flex-wrap: wrap;
          @include pc-inner-width;
          @include auto;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
        }
      }

      &__box {
        @include mq {
          width: calc(100% / 2);
          display: flex;
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:nth-of-type(n+3) {
          @include mq {
            margin-top: 45px;
          }
        }

        &__thumbnail {
          @include mq {
            width: 50%;
          }

          &__img {
            @include mq {
              transition: 0.3s;
            }

            &:hover {
              @include mq {
                border-radius: 50px;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }
            }
          }
        }

        &__content {
          @include mq {
            width: 50%;
            padding: 0 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          @include mq {
            margin-top: 30px;
            display: block;
            @include default-height;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 30px;
          }

          &::before {
            content: "#";
            color: $default-black;
            margin-right: 20px;
          }
        }

        &__item-lv2 {
          font-style: italic;
          @include mq {
            margin-top: 10px;
            display: block;
            @include default-height;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }
  }

  &__section-lv3 {
    position: relative;
    @include mq {
      padding-top: 180px;
    }
    @include mq(medium, max) {
    }

    &__inner {
      @include mq(medium, max) {
        padding-top: 100px;
      }
    }

    &__list {
      @include mq {
        margin-top: 25px;
      }
      @include mq(medium, max) {
        margin-top: 25px;
      }

      &__inner {
        @include mq {
          @include pc-inner-width;
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
      }

      &__box {
        @include mq {
          width: calc(calc(100% / 3) - calc(40px / 3));
        }

        &:not(:nth-of-type(3n)) {
          @include mq {
            margin-right: 20px;
          }
        }

        &:nth-of-type(n+4) {
          @include mq {
            margin-top: 20px;
          }
        }

        &__thumbnail {
          @include mq {
            position: relative;
            transition: 0.3s;
          }
          @include mq(medium, max) {
            position: relative;
          }

          &:hover {
            .p-news-01__section-lv3__list__box__thumbnail__img {
              @include mq {
                border-radius: 50px;
              }
            }

            .p-news-01__section-lv3__list__box__overlay {
              @include mq {
                border-radius: 50px;
              }
            }
          }

          &__img {
            @include mq {
              height: 115px;
              position: relative;
              z-index: 1;
              transition: 0.3s;
            }
            @include mq(medium, max) {
              height: 120px;
              position: relative;
              z-index: 1;
            }
          }
        }

        &__overlay {
          @include mq {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include centering(xy);
            width: 100%;
            height: 100%;
            background-color: $default-black;
            opacity: 0.5;
            z-index: 2;
            transition: 0.3s;
          }
          @include mq(medium, max) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include centering(xy);
            width: 100%;
            height: 100%;
            background-color: $default-black;
            opacity: 0.5;
            z-index: 2;
          }
        }

        &__tag {
          @include mq {
            position: absolute;
            @include centering(xy);
            z-index: 3;
            display: flex;
          }
          @include mq(medium, max) {
            position: absolute;
            @include centering(xy);
            z-index: 3;
            display: flex;
          }

          &__icon {
            @include mq {
              @include box;
              width: 25px;
              height: 25px;
              background: url("../images/tag_icon_white.svg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              margin-right: 10px;
              position: relative;
              top: 2px;
              flex: none;
            }
            @include mq(medium, max) {
              @include box;
              width: 15px;
              height: 15px;
              background: url("../images/tag_icon_white.svg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              margin-right: 10px;
              position: relative;
              top: 2px;
              flex: none;
            }
          }

          &__text {
            @include mq {
              color: $reverse-color;
              font-size: 2.0rem;
            }
            @include mq(medium, max) {
              color: $reverse-color;
            }
          }
        }
      }
    }
  }

  &__section-lv4 {
    position: relative;
    @include mq {
      padding-top: 180px;
    }

    &__inner {
      @include mq(medium, max) {
        padding-top: 100px;
        position: relative;
        z-index: 2;
      }
    }

    &__list-lv1 {
      @include mq {
        margin-top: 60px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__inner {
        @include mq {
          @include pc-inner-width;
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
        }
      }

      &__box {
        @include mq {
          width: calc(calc(100% / 3) - 20px);
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:not(:nth-of-type(3n)) {
          @include mq {
            margin-right: 30px;
          }
        }

        &:nth-of-type(n+4) {
          @include mq {
            margin-top: 45px;
          }
        }

        @for $number from 1 through 12 {
          &:nth-of-type(#{$number}) {
            .p-news-01__section-lv4__list-lv1__box__thumbnail__item-lv1 {
              &::before {
                @include mq {
                  @include box;
                  width: 50px;
                  height: 50px;
                  content: "#{$number}";
                  font-size: 4rem;
                  @include bold;
                  color: $color-lv7;
                  @include default-height;
                }
                @include mq(medium, max) {
                  @include box;
                  width: 50px;
                  height: 50px;
                  content: "#{$number}";
                  font-size: 3.5rem;
                  @include bold;
                  color: $color-lv7;
                  @include default-height;
                }
              }
            }
          }
        }

        &:hover {
          @include mq {
            border-radius: 50px;
          }

          .p-news-01__section-lv4__list-lv1__box__thumbnail__img {
            @include mq {
              border-radius: 50px;
            }
          }

          .p-news-01__section-lv4__list-lv1__box__thumbnail img {
            @include mq {
              -webkit-transform: scale(1.2);
              -moz-transform: scale(1.2);
              -o-transform: scale(1.2);
              -ms-transform: scale(1.2);
              transform: scale(1.2);
            }
          }
        }

        &__thumbnail {
          position: relative;

          &__img {
            @include mq {
              overflow: hidden;
              transition: 0.5s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            img {
              @include mq {
                -moz-transition: -moz-transform 0.5s linear;
                -webkit-transition: -webkit-transform 0.5s linear;
                -o-transition: -o-transform 0.5s linear;
                -ms-transition: -ms-transform 0.5s linear;
                transition: transform 0.5s linear;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }

            }
          }

          &__item-lv1 {
            @include mq {
              position: absolute;
              top: 10px;
              left: 20px;
            }
            @include mq(medium, max) {
              position: absolute;
              top: 10px;
              left: 20px;
            }
          }

          &__item-lv2 {
            @include mq {
              position: absolute;
              right: 20px;
              top: 10px;
              @include box;
              background-color: $reverse-color;
              width: 40px;
              height: 40px;
            }
            @include mq(medium, max) {
              position: absolute;
              right: 20px;
              top: 10px;
              @include box;
              background-color: $reverse-color;
              width: 35px;
              height: 35px;
            }
          }
        }

        &__content {
          @include mq {
            padding: 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          @include mq {
            margin-top: 30px;
            display: block;
            @include default-height;
            font-size: 1.0rem;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 30px;
          }
        }

        &__item-lv2 {
          @include mq {
            @include default-height;
            display: block;
            margin-top: 10px;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
          }
        }
      }
    }

    &__list-lv2 {
      @include mq {
        margin-top: 70px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__inner {
        @include mq {
          @include pc-inner-width;
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
        }
      }

      &__box {
        @include mq {
          width: calc(100% / 2);
          display: flex;
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:nth-of-type(n+3) {
          @include mq {
            margin-top: 45px;
          }
        }

        @for $number from 1 through 10 {
          &:nth-of-type(#{$number}) {
            .p-news-01__section-lv4__list-lv2__box__thumbnail__item-lv1 {
              &::before {
                @include mq {
                  @include box;
                  width: 50px;
                  height: 50px;
                  content: "#{$number}";
                  font-size: 4rem;
                  @include bold;
                  color: $color-lv7;
                  @include default-height;
                }
                @include mq(medium, max) {
                  @include box;
                  width: 50px;
                  height: 50px;
                  content: "#{$number}";
                  font-size: 3.5rem;
                  @include bold;
                  color: $color-lv7;
                  @include default-height;
                }
              }
            }
          }
        }

        &:hover {
          @include mq {
            border-radius: 50px;
          }

          .p-news-01__section-lv4__list-lv2__box__thumbnail__img {
            @include mq {
              border-radius: 50px;
            }
          }

          .p-news-01__section-lv4__list-lv2__box__thumbnail img {
            @include mq {
              -webkit-transform: scale(1.2);
              -moz-transform: scale(1.2);
              -o-transform: scale(1.2);
              -ms-transform: scale(1.2);
              transform: scale(1.2);
            }
          }
        }

        &__thumbnail {
          position: relative;
          @include mq {
            width: 50%;
          }
          @include mq(medium, max) {
            width: 100%;
          }

          &__img {
            @include mq {
              overflow: hidden;
              transition: 0.5s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            img {
              @include mq {
                -moz-transition: -moz-transform 0.5s linear;
                -webkit-transition: -webkit-transform 0.5s linear;
                -o-transition: -o-transform 0.5s linear;
                -ms-transition: -ms-transform 0.5s linear;
                transition: transform 0.5s linear;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }

            }
          }

          &__item-lv1 {
            @include mq {
              position: absolute;
              top: 10px;
              left: 20px;
            }
            @include mq(medium, max) {
              position: absolute;
              top: 10px;
              left: 20px;
            }
          }

          &__item-lv2 {
            @include mq {
              position: absolute;
              right: 20px;
              top: 10px;
              @include box;
              background-color: $reverse-color;
              width: 40px;
              height: 40px;
            }
            @include mq(medium, max) {
              position: absolute;
              right: 20px;
              top: 10px;
              @include box;
              background-color: $reverse-color;
              width: 35px;
              height: 35px;
            }
          }
        }

        &__content {
          @include mq {
            width: 50%;
            padding: 0 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          @include mq {
            margin-top: 30px;
            display: block;
            @include default-height;
            font-size: 1.0rem;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 30px;
          }
        }

        &__item-lv2 {
          @include mq {
            @include default-height;
            display: block;
            margin-top: 10px;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
