.l-main-lv1 {
  @include mq {
    margin-top: 80px;
    position: relative;
  }
  @include mq(medium, max) {
    margin-top: 60px;
    position: relative;
  }
}

.l-min-height {
  @include mq {
    min-height: calc(100vh - 327px);
  }
  @include mq(medium, max) {
    min-height: calc(100vh - 302px);
  }
}

.l-main-section {
  @include mq {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
  @include mq(medium, max) {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}
