.l-header-lv1 {
  position: fixed;
  z-index: 1000000;
  @include mq {
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    //width: 100%;
  }
  @include mq(medium, max) {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    //width: 100%;
  }

  &__inner {
    @include mq {
      height: 80px;
      background-color: $color-black;;
      position: relative;
    }
    @include mq(medium, max) {
      height: 60px;
      background-color: $color-black;;
      position: relative;
    }
  }

  &__box-lv1 {
    @include mq {
      transition: 0.3s;
      position: fixed;
      background-color: $color-navy;
      @include box;
      width: 400px;
      height: 300px;
    }
    @include mq(medium, max) {
      @include box;
      width: 60px;
      height: 25px;
      background: url("../images/apo_white.svg");
      background-repeat: no-repeat;
      @include centering(xy);
    }

    .l-header-lv1--move & {
      @include mq {
        opacity: 0;
      }
    }

    .l-header-lv1--move-top & {
      @include mq {
        opacity: 0;
      }
    }

    &__inner {
      @include mq {
        position: relative;
        width: 400px;
        height: 300px;
        transition: 0.5s;
        background-color: #131747;
      }

      .l-header-lv1--move & {
        @include mq {
          height: 0;
          opacity: 0;
        }
      }

      .l-header-lv1--move-top & {
        @include mq {
          height: 0;
          opacity: 0;
        }
      }

      span {
        @include mq {
          @include bold;
          position: absolute;
          left: 0;
          color: $reverse-color;
          z-index: 3;
          transform: rotate(-90deg) translateY(-50%);
          top: 50%;
          font-size: 1.4rem;
          letter-spacing: 2px;
        }
        @include mq(medium, max) {
          display: none;
        }
      }
    }

    &__logo {
      @include mq {
        background: url("../images/apo_white.svg");
        @include box;
        width: 200px;
        height: 60px;
        left: 60% !important;
        // width: 100px;
        // height: 50px;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        position: absolute;
        @include centering(xy);
        transition: 0.5s;
      }
      @include mq(medium, max) {
      }
    }

    &__kitacore-logo {
      @include mq {
        background: url("../images/apo_white.svg");
        @include box;
        width: 200px;
        height: 50px;
        background-repeat: no-repeat;
        position: absolute;
        @include centering(xy);
        transition: 0.5s;
      }
    }

    .l-header-lv1--move & {
      @include mq {
        height: 0;
        opacity: 0;
      }
    }

    .l-header-lv1--move-top & {
      @include mq {
        height: 0;
        opacity: 0;
      }
    }
  }

  &__box-lv2 {
    @include mq {
      @include box;
      width: 400px;
      height: 80px;
      transition: 0.5s;
      position: absolute;
      //left: 6%;
      //top: 55%;
      //transform: translateY(-50%);
    }

    .l-header-lv1--move & {
      @include mq {
        background-color: $color-black;
        position: absolute;
        //top: 50%;
        //left: 15%;
        //transform: translate(0%, -50%);
      }
    }

    .l-header-lv1--move-top & {
      @include mq {
        background-color: $color-black;
        position: absolute;
        //top: 50%;
        //left: 15%;
        //transform: translate(0%, -50%);
      }
    }

    span {
      @include mq {
        transition: 0.5s;
        position: absolute;
        left: 0;
        top: 13%;
        //transform: translateY(-50%);
        opacity: 0;
      }

      .l-header-lv1--move & {
        @include mq {
          background: url("../images/apo_white.svg");
          @include box;
          width: 100px;
          height: 50px;
          background-repeat: no-repeat;
          position: absolute;
          @include centering(xy);
          opacity: 1;
        }
      }

      .l-header-lv1--move-top & {
        @include mq {
          background: url("../images/apo_white.svg");
          @include box;
          width: 125px;
          height: 70px;
          background-repeat: no-repeat;
          position: absolute;
          //@include centering(xy);
          //top: 55%;
          left: 33%;
          //transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }

  &__box-lv3 {
    @include mq {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      z-index: 3;
      display: flex;
    }
    @include mq(medium, max) {
      display: none;
    }

    &__text {
      @include mq {
        @include box;
        margin-right: 20px;
        margin-left: 20px;
        color: $reverse-color;
      }

      &__last {
        @include mq {
          @include box;
          padding-right: 20px;
          padding-left: 20px;
          border-left: 2px solid;
          color: $reverse-color;
        }
      }
    }

    &__linkto {
      @include mq {
        @include box;
        width: 40px;
        height: 40px;
        background-color: $color-navy;
        border-radius: 50%;
        transition: 0.5s;
        position: relative;
      }

      &:hover {
        @include mq {
          @include pink-to-orange;
        }
      }

      &:not(:last-child) {
        @include mq {
          margin-right: 15px;
        }
      }

      span {
        @include mq {
          @include box;
          width: 18px;
          height: 18px;
          position: absolute;
          @include centering(xy);
        }
      }

      &__fb {
        @include mq {
          background: url("../images/facebook_icon.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &__in {
        @include mq {
          background: url("../images/instagram_icon.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &__tw {
        @include mq {
          background: url("../images/twitter_icon.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &__line {
        @include mq {
          background: url("../images/line_icon.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &__btn {
    // position: fixed;
    position: absolute;
    right: 20px;
    display: block;
    width: 25px;
    height: 20px;
    z-index: 99999;
    box-sizing: border-box;
    top: 19px;
    @include mq {
      display: none;
    }

    .is-active__menu & {
      width: 30px;
      height: 30px;
      top: 20px;
      color: $color-navy;
    }

    &__bar {
      display: inline-block;
      transition: all 0.4s;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      width: 100%;
      height: 2px;
      margin: 0 auto;
      z-index: 9999999;
      @include mq {
        background-color: $color-navy;
      }
      @include mq(medium, max) {
        background-color: $reverse-color;
      }

      .is-active__menu & {
        @include mq(medium, max) {
          background-color: $color-navy;
        }
      }

      &--01 {
        @include mq(medium, max) {
          top: 0;
        }

        .is-active__menu & {
          transform: rotate(45deg) translateY(9px) translateX(9px);
        }
      }

      &--02 {
        @include mq(medium, max) {
          top: 9px;
        }

        .is-active__menu & {
          display: none;
        }
      }

      &--03 {
        @include mq(medium, max) {
          bottom: 0;
        }

        .is-active__menu & {
          bottom: 0;
          transform: rotate(-45deg) translateX(11px) translateY(-11px);
        }
      }
    }
  }

  &__menu {
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 4;
    background-color: $reverse-color;

    &__inner {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 30px;
    }

    &__item {
      @include mq {
        margin-top: 40px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      a {
        font-size: 1.8rem;
        @include mq {
          transition: 0.3s;
        }

        &:hover {
          @include mq {
            color: $color-lv3;
          }
        }
      }
    }

    &__title {
      @include mq(medium, max) {
        text-align: left;
      }

      &__main {
        @include mq {
          @include bold;
          font-size: 3.6rem;
        }
        @include mq(medium, max) {
          font-size: 2.4rem;
          @include bold;
          @include default-height;
          white-space: nowrap;
          display: inline-block;
          color: $color-navy;
        }
      }

      &__border {
        @include mq {
          @include box;
          width: 40px;
          height: 1px;
          background-color: $color-lv2;
          margin-top: 30px;
          margin-left: 10px;
          margin-right: 10px;
        }
        @include mq(medium, max) {
          @include box;
          display: inline-block;
          width: 40px;
          height: 1px;
          background-color: $color-lv2;
          margin-top: 0;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;
          top: -8px;
        }
      }

      &__sub {
        @include mq {
          font-size: 1.2rem;
          padding-top: 22px;
        }
        @include mq(medium, max) {
          font-size: 1.4rem;
          padding-top: 10px;
          @include default-height;
          color: $color-navy;
          @include bold;
        }
      }
    }

    &__sns-icon {
      @include mq(medium, max) {
        display: flex;
        margin-top: 30px;
      }

      &__linkto {
        position: relative;
        @include mq(medium, max) {
          @include box;
          width: 40px;
          height: 40px;
          background-color: $color-navy;
          border-radius: 50%;
        }

        &:not(:last-child) {
          @include mq(medium, max) {
            margin-right: 15px;
          }
        }

        span {
          @include box;
          width: 20px;
          height: 20px;
          position: absolute;
          @include centering(xy);
        }

        &__fb {
          @include mq(medium, max) {
            background: url("../images/facebook_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &__in {
          @include mq(medium, max) {
            background: url("../images/instagram_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &__tw {
          @include mq(medium, max) {
            background: url("../images/twitter_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &__line {
          @include mq(medium, max) {
            background: url("../images/line_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    &__copy {
      margin-top: 30px;
      display: block;
      text-align: left;
      font-size: 1.6rem;
      @include middle;
      color: $color-navy;
    }
  }
}

.l-header-list {
  @include mq {
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 40px;
    display: flex;
    transform: translateY(-50%);
  }
  @include mq(medium, max) {
  }

  &__inner {
    @include mq {
      margin: 20px;
    }
    @include mq(medium, max) {
    }
  }

  &__inner05 {
    @include mq {
      border-left: solid 2px;
      display: flex;
      padding-left: 35px;
      height: 25px;
    }
    @include mq(medium, max) {
    }
  }

  &__inner06 {
    @include mq {
      border-right: solid 2px;
      display: flex;
      padding-right: 35px;
      height: 25px;
    }
    @include mq(medium, max) {
    }
  }
}

.l-header-lv1-top {
  position: fixed;
  z-index: 1000000;
  @include mq {
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  @include mq(medium, max) {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.l-header-top-sp {
  @include mq(medium, max) {
    position: fixed;
    z-index: 1000000;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.l-header-top {
  &__sp-logo {
    @include mq(medium, max) {
      display: block;
      content: "";
      width: 70px;
      height: 40px;
      background: url("../images/apo_white.svg");
      background-repeat: no-repeat;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__sp-link {
    @include mq(medium, max) {
      display: flex;
      padding-top: 10px;
    }
  }
}

.l-header-lv2 {
  position: fixed;
  z-index: 1000000;
  @include mq {
    height: 120px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  @include mq(medium, max) {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &__box-lv3 {
    @include mq {
      @include box;
      width: 400px;
      height: 120px;
      transition: 0.5s;
      position: absolute;
      //left: 6%;
      top: 50%;
      transform: translateY(-50%);
    }

    .l-header-lv1--move & {
      @include mq {
        background-color: $color-navy;
        position: absolute;
        top: 50%;
        //left: 15%;
        transform: translate(0%, -50%);
      }
    }

    .l-header-lv1--move-top & {
      @include mq {
        background-color: $color-navy;
        position: absolute;
        top: 50%;
        //left: 15%;
        transform: translate(0%, -50%);
      }
    }

    span {
      @include mq {
        transition: 0.5s;
        position: absolute;
        left: 0;
        top: 13%;
        //transform: translateY(-50%);
        opacity: 0;
      }

      .l-header-lv1--move & {
        @include mq {
          background: url("../images/apo_white.svg");
          @include box;
          width: 100px;
          height: 50px;
          background-repeat: no-repeat;
          position: absolute;
          @include centering(xy);
          opacity: 1;
        }
      }

      .l-header-lv1--move-top & {
        @include mq {
          background: url("../images/apo_white.svg");
          @include box;
          width: 200px;
          height: 50px;
          background-repeat: no-repeat;
          position: absolute;
          // @include centering(xy);
          top: 55%;
          left: 57%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }
}

.l-header_link--toPortals{
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}