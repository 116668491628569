//@import 'bourbon'; // http://bourbon.io/

@import "../partials/variables";
// colors, fonts etc...

@import "../partials/mixins";
// custom mixins

@import "../partials/layout";
// responsive grid and media queries
@import "../../foundation/_mixin.scss";

/* -------------------------------- 

Primary style

-------------------------------- */

//*, *::after, *::before {
//	box-sizing: border-box;
//}
//
//html {
//	font-size: 62.5%;
//}
//
//body {
//	font: {
//		size: 1.6rem;
//		family: $primary-font; // variables inside partials > _variables.scss
//	}
//	color: $color-1;
//	background-color: $color-3;
//}
//
//a {
//	color: $color-navy;
//	text-decoration: none;
//}
//
//section {
//	/* used just to separate different styles */
//	border-bottom: 1px solid darken($color-3, 10%);
//	padding: 4em 0;
//
//	h2 {
//		width: 90%;
//		margin: 0 auto 2em;
//		color: $color-1;
//		font-size: 2rem;
//		font-weight: 700;
//		text-align: center;
//	}
//
//	@include MQ(L) {
//		padding: 6em 0;
//
//		h2 {
//			margin: 0 auto 3em;
//		}
//	}
//}

/* -------------------------------- 

Basic Style

-------------------------------- */

.cd-breadcrumb,
.cd-multi-steps {
  width: 90%;
  max-width: $M;
  padding: 0.5em 1em;
  margin: 1em auto;
  background-color: $color-4;
  border-radius: 0.25em;
  //@include clearfix;

  li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;

    &::after {
      @include mq {
        /* this is the separator between items */
        display: inline-block;
        content: "\00bb";
        margin: 0 0.6em;
        color: tint($color-1, 50%);
      }
      @include mq(medium, max) {
        display: inline-block;
        content: "";
        position: inherit;
        top: -116px;
        left: 10px;
        width: calc(100% + 40px);
        height: 2px;
        z-index: -1;
        background-color: #edeff0;
      }
    }

    &:last-of-type::after {
      /* hide separator after the last item */
      display: none;
    }
  }

  li > * {
    @include mq {
      /* single step */
      display: inline-block;
      font-size: 1.4rem;
      color: $color-1;
    }
    @include mq(medium, max) {
      display: inline-grid;
      text-align: center;
      padding-right: 17px;
      padding-left: 17px;
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }

  li.current > * {
    /* selected step */
    color: $color-navy;
  }

  .no-touch & a:hover {
    /* steps already visited */
    color: $color-navy;
  }

  &.custom-separator li::after {
    /* replace the default arrow separator with a custom icon */
    content: "";
    height: 16px;
    width: 16px;
    background: url(../../../../../../../../Downloads/breadcrumbs-and-multistep-indicator-master/img/cd-custom-separator.svg) no-repeat center center;
    vertical-align: middle;
  }

  &.custom-icons li > *::before {
    /* add a custom icon before each item */
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 0.4em;
    margin-top: -2px;
    background: url(../../../../../../../../Downloads/breadcrumbs-and-multistep-indicator-master/img/cd-custom-icons-01.svg) no-repeat 0 0;
    vertical-align: middle;
  }

  &.custom-icons li:not(.current):nth-of-type(2) > *::before {
    /* change custom icon using image sprites */
    background-position: -20px 0;
  }

  &.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
  }

  &.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
  }

  &.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
  }

  &.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
  }

  &.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
  }

  &.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
  }

  @include MQ(M) {
    padding: 0 1.2em;

    li {
      margin: 1.2em 0;

      &::after {
        margin: 0 1em;
      }
    }

    li > * {
      font-size: 1.6rem;
    }
  }
}

/* -------------------------------- 

Triangle breadcrumb

-------------------------------- */

@include MQ(M) {
  .cd-breadcrumb.triangle {
    /* reset basic style */
    background-color: transparent;
    padding: 0;

    li {
      position: relative;
      padding: 0;
      margin: 4px 4px 4px 0;

      &:last-of-type {
        margin-right: 0;
      }
    }

    li > * {
      position: relative;
      padding: 1em 0.8em 1em 2.5em;
      color: $color-1;
      background-color: $color-4;
      /* the border color is used to style its ::after pseudo-element */
      border-color: $color-4;
    }

    li.current > * {
      /* selected step */
      color: $color-3;
      background-color: $color-navy;
      border-color: $color-navy;
    }

    li:first-of-type > * {
      padding-left: 1.6em;
      border-radius: 0.25em 0 0 0.25em;
    }

    li:last-of-type > * {
      padding-right: 1.6em;
      border-radius: 0 0.25em 0.25em 0;
    }

    .no-touch & a:hover {
      /* steps already visited */
      color: $color-3;
      background-color: $color-1;
      border-color: $color-1;
    }

    li::after,
    li > *::after {
      /* 
				li > *::after is the colored triangle after each item
				li::after is the white separator between two items
			*/
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      content: "";
      height: 0;
      width: 0;
      /* 48px is the height of the <a> element */
      border: 24px solid transparent;
      border-right-width: 0;
      border-left-width: 20px;
    }

    li::after {
      /* this is the white separator between two items */
      z-index: 1;
      transform: translateX(4px);
      border-left-color: $color-3;
      /* reset style */
      margin: 0;
    }

    li > *::after {
      /* this is the colored triangle after each element */
      z-index: 2;
      border-left-color: inherit;
    }

    li:last-of-type::after,
    li:last-of-type > *::after {
      /* hide the triangle after the last step */
      display: none;
    }

    &.custom-separator li::after {
      /* reset style */
      background-image: none;
    }

    &.custom-icons li::after,
    &.custom-icons li > *::after {
      /* 50px is the height of the <a> element */
      border-top-width: 25px;
      border-bottom-width: 25px;
    }
  }

  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li > *::after {
      /* fix a bug on Firefix - tooth edge on css triangle */
      border-left-style: dashed;
    }
  }
}

/* -------------------------------- 

Custom icons hover effects - breadcrumb and multi-steps

-------------------------------- */

@include MQ(M) {
  .cd-breadcrumb.triangle,
  .cd-multi-steps.text-center {
    .no-touch &.custom-icons li:first-of-type a:hover::before,
    &.custom-icons li.current:first-of-type em::before {
      /* change custom icon using image sprites - hover effect or current item */
      background-position: 0 -40px;
    }

    .no-touch &.custom-icons li:nth-of-type(2) a:hover::before,
    &.custom-icons li.current:nth-of-type(2) em::before {
      background-position: -20px -40px;
    }

    .no-touch &.custom-icons li:nth-of-type(3) a:hover::before,
    &.custom-icons li.current:nth-of-type(3) em::before {
      background-position: -40px -40px;
    }

    .no-touch &.custom-icons li:nth-of-type(4) a:hover::before,
    &.custom-icons li.current:nth-of-type(4) em::before {
      background-position: -60px -40px;
    }
  }
}

/* -------------------------------- 

Multi steps indicator 

-------------------------------- */

// @include MQ(M) {
.cd-multi-steps {
  @include mq {
    /* reset style */
    background-color: transparent;
    padding: 0;
    text-align: center;
    margin-top: 80px;
  }
  @include mq(medium, max) {
    background-color: transparent;
    display: flex;
    height: 170px;
  }
}

.cd-multi-steps li {
  @include mq {
    position: relative;
    float: none;
    margin: 0.4em 40px 0.4em 0;
  }
  @include mq(medium, max) {
  }

  &:last-of-type {
    margin-right: 0;
  }

  &::after {
    @include mq {
      /* this is the line connecting 2 adjacent items */
      position: absolute;
      content: "";
      height: 4px;
      background: $color-4;
      /* reset style */
      margin: 0;
    }
    @include mq(medium, max) {
      position: absolute;
      content: "";
      background: $color-4;
      margin: 0;
    }
  }

  &.visited::after {
    background-color: $color-navy;
  }

  & > *,
  &.current > * {
    position: relative;
    color: $color-1;
  }
}

.cd-multi-steps.custom-separator li::after {
  /* reset style */
  height: 4px;
  background: $color-4;
}

.cd-multi-steps.text-center {
  li::after {
    width: 100%;
    top: 50%;
    left: 100%;
    transform: translateY(-50%) translateX(-1px);
  }

  li > * {
    z-index: 1;
    padding: 0.6em 1em;
    border-radius: 0.25em;
    background-color: $color-4;
  }

  .no-touch & a:hover {
    background-color: $color-1;
  }

  li.current > *,
  li.visited > * {
    color: $color-3;
    background-color: $color-navy;
  }

  &.custom-icons li.visited a::before {
    /* change the custom icon for the visited item - check icon */
    background-position: 0 -60px;
  }
}

.cd-multi-steps.text-top,
.cd-multi-steps.text-bottom {
  li {
    @include mq {
      width: 150px;
      text-align: center;
    }
    @include mq(medium, max) {
      text-align: center;
    }

    &::after {
      position: absolute;
      left: 50%;
      width: calc(100% + 20px);
    }
  }

  li > *::before {
    /* this is the spot indicator */
    content: "";
    position: absolute;
    z-index: 1;
    @include center(x);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: $color-4;
  }

  li.visited > *::before,
  li.current > *::before {
    background-color: $color-navy;
    color: #ffffff;
  }

  .no-touch & a:hover {
    color: $color-2;

    &::before {
      box-shadow: 0 0 0 3px rgba($color-2, 0.3);
    }
  }
}

.cd-multi-steps.text-top {
  li::after {
    /* this is the line connecting 2 adjacent items */
    bottom: 4px;
  }

  li > * {
    padding-bottom: 20px;

    &::before {
      /* this is the spot indicator */
      bottom: 0;
    }
  }
}

.cd-multi-steps.text-bottom {
  li::after {
    @include mq {
      /* this is the line connecting 2 adjacent items */
      top: 3px;
    }
    @include mq(medium, max) {
      top: 60px;
      left: 30px;
    }
  }

  li > * {
    @include mq {
      padding-top: 20px;
    }
    @include mq(medium, max) {
      padding-top: 65px;
    }

    &::before {
      /* this is the spot indicator */
      top: 0;
    }
  }
}

// }

// .cd-multi-steps {
//   @include mq(medium, max) {
//     background-color: transparent;
//     display: flex;
//     height: 150px;
//   }
// }

/* -------------------------------- 

Add a counter to the multi-steps indicator 

-------------------------------- */

.cd-multi-steps.count li {
  counter-increment: steps;
}

.cd-multi-steps.count li > *::before {
  @include mq {
    content: counter(steps) " - ";
  }
  @include mq(medium, max) {
    content: counter(steps) " ";
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 1.9rem;
    color: #787878;
    border: 1px solid #edeff8;
    border-radius: 50%;
    // background-color: #131747;
    margin: 0 auto;
  }
}

@include MQ(M) {
  .cd-multi-steps.text-top.count li > *::before,
  .cd-multi-steps.text-bottom.count li > *::before {
    /* this is the spot indicator */
    content: counter(steps);
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 1.9rem;
    color: $color-3;
  }

  .cd-multi-steps.text-top.count li:not(.current) em::before,
  .cd-multi-steps.text-bottom.count li:not(.current) em::before {
    /* steps not visited yet - counter color */
    color: $color-1;
  }

  .cd-multi-steps.text-top.count li::after {
    bottom: 11px;
  }

  .cd-multi-steps.text-top.count li > * {
    padding-bottom: 34px;
  }

  .cd-multi-steps.text-bottom.count li::after {
    top: 27px;
  }

  .cd-multi-steps.text-bottom.count li > * {
    padding-top: 60px;
    font-size: 80%;
  }
}
