.l-header-top {
  @include mq {
    width: 200px;
    position: fixed;
    z-index: 100;
    background-color: $reverse-color;
    height: 100%;
  }
  // @include mq(medium, max) {
  //   height: 60px;
  //   background-color: $color-navy;
  //   position: relative;
  // }
}

.l-main-top {
  @include mq {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  @include mq(medium, max) {
  }
}

.l-main-top-image {
  @include mq {
    margin-bottom: 30px;
  }
  @include mq(medium, max) {
    position: relative;
    z-index: 1;
    display: block;
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.l-header-logo {
  @include mq {
    position: fixed;
    background-color: $color-navy;
    display: block;
    content: "";
    width: 200px;
    height: 200px;
  }
  @include mq(medium, max) {
  }

  &__image {
    @include mq {
      display: block;
      content: "";
      width: 120px;
      height: 120px;
      background: url("../images/kitacore_logo_tate_a.svg") no-repeat;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 54%;
    }
    @include mq(medium, max) {
    }
  }
}

.l-header-nav-area {
  @include mq {
    margin-top: 300px;
    height: 100%;
  }
  @include mq(medium, max) {
    display: none;
  }

  ul {
    @include mq {
      text-align: center;
    }
    @include mq(medium, max) {
    }
  }

  li {
    @include mq {
      margin: 18px;
    }
    @include mq(medium, max) {
    }
  }

  &__bottom {
    @include mq {
      position: absolute;
      bottom: 0;
      width: 200px;
      height: 100px;
      text-align: center;
    }
    @include mq(medium, max) {
    }

    &__text {
    }
  }
}

.l-trainer-matching-image {
  @include mq {
    display: block;
    content: "";
    width: 1000px;
    height: 537px;
    //background: url("../images/mv-4.png") no-repeat;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    overflow: hidden;
    transition: 0.5s;
    position: relative;
  }
  @include mq(medium, max) {
    margin: 20px 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  &__text-area {
    @include mq {
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      height: 70px;
      color: #f61d6a; //非対応のブラウザでの文字色を設定
      background: linear-gradient(to right, #f61d6a, #ff6b01); //背景色にグラデーションを指定
      z-index: 2;
      bottom: 0;
    }
    @include mq(medium, max) {
      display: block;
      content: "";
      width: 100%;
      height: 55px;
      color: #f61d6a;
      background: linear-gradient(to right, #f61d6a, #ff6b01);
      z-index: 999999;
      bottom: 0;
    }

    p {
      @include mq {
        @include bold;
        color: $reverse-color;
        text-align: left;
        margin-left: 50px;
        font-size: 2.1rem;
        transform: translateY(48%);
      }
      @include mq(medium, max) {
        font-weight: 700;
        color: #ffffff;
        margin-left: 16px;
        transform: translateY(48%);
        line-height: 28px;
      }
    }

    span {
      @include mq {
        z-index: 2;
        display: block;
        content: "";
        width: 100px;
        height: 20px;
        background: url(../images/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translateY(-50%);
        transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
      }
      @include mq(medium, max) {
        z-index: 2;
        display: block;
        content: "";
        width: 70px;
        height: 12px;
        background: url(../images/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        right: 30px;
        transform: translateY(-50%);
        transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
      }
    }
  }

  &:hover {
    border-radius: 30px;

    span {
      @include mq {
        -webkit-transform: translate(15px, -50%);
        transform: translate(15px, -50%);
      }
    }
  }

  img {
    @include mq {
      display: block;
      transition-duration: 0.3s; /*変化に掛かる時間*/
    }
    @include mq(medium, max) {
    }

    &:hover {
      @include mq {
        transform: scale(1.05);
        position: absolute;
      }
    }
  }
}

.l-top-title {
  @include mq {
  }
  @include mq(medium, max) {
  }

  &__gradation-right {
    @include mq {
      filter: blur(170px);
      display: block;
      content: "";
      width: 300px;
      height: 200px;
      background-color: red;
      position: absolute;
      //transform: translateY(100%);
      right: -60px;
      z-index: -1;
    }
    @include mq(medium, max) {
    }
  }

  &__gradation-left {
    @include mq {
      filter: blur(170px);
      display: block;
      content: "";
      width: 300px;
      height: 200px;
      background-color: red;
      position: absolute;
      //transform: translateY(100%);
      left: -60px;
      z-index: -1;
    }
    @include mq(medium, max) {
    }
  }
}

.l-top-section {
  @include mq {
    padding-top: 60px;
    position: relative;
    padding-bottom: 60px;
  }
  @include mq(medium, max) {
  }
}

.l-top-border {
  @include mq {
    display: block;
    content: "";
    width: 40px;
    height: 2px;
    background-color: #cccccc;
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @include mq(medium, max) {
  }
}

.l-trainer-janre {
  @include mq {
    width: 100%;
    //height: 100%;
  }
  @include mq(medium, max) {
  }

  &__name {
    @include mq {
      @include bold;
    }
    @include mq(medium, max) {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }

  &__name-kana {
    @include mq {
      font-size: 1.1rem;
      @include bold;
    }
    @include mq(medium, max) {
      font-weight: 700;
      font-size: 1.3rem;
    }
  }

  &__text {
    @include mq {
      display: flex;
      @include bold;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    @include mq(medium, max) {
      margin-top: 10px;
      font-weight: 700;
      font-size: 1.6rem;
      align-items: center;
      display: flex;
    }

    &::after {
      @include mq {
        display: block;
        content: "";
        width: 30px;
        height: 1px;
        background-color: #222222;
        margin-top: 10px;
        margin-left: 10px;
      }
      @include mq(medium, max) {
        content: "";
        border-top: 1px solid;
        // -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 5px;
        max-width: 25px;
      }
    }
  }

  &__icon {
    @include mq {
      display: flex;
      flex-wrap: wrap;
    }
    @include mq(medium, max) {
      margin-top: 10px;
      display: inline-block;
      // padding-bottom: 60px;
    }

    img {
      @include mq {
        width: 55px;
        height: 55px;
        margin-top: 10px;
        margin-right: 10px;
      }
      @include mq(medium, max) {
        width: 55px;
        height: auto;
      }
    }

    img:not(:nth-of-type(5n)) {
      @include mq {
        margin-right: 10px;
      }
      @include mq(medium, max) {
        float: left;
        margin-right: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.l-top-questions-area {
  @include mq {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
  }
  @include mq(medium, max) {
    padding: 20px 10px;
  }

  &__box {
    @include mq {
      width: calc((100% - 80px) / 4);
      height: 150px;
      background: $color-navy;
      margin: 10px;
    }
    @include mq(medium, max) {
      height: 100px;
      background: #131747;
      margin: 1px;
      width: 100%;
    }

    &.active {
      @include mq {
        width: calc((100% - 80px) / 4);
        height: 150px;
        background: linear-gradient(to right, $color-pink, $color-orange);
        margin: 10px;
        border-radius: 30px;
        transition: 0.5s;
      }
      @include mq(medium, max) {
        height: 100px;
        background: linear-gradient(to right, $color-pink, $color-orange);
        margin: 1px;
        border-radius: 30px;
        transition: 0.5s;
      }
    }
  }

  &__text {
    @include mq {
      color: $reverse-color;
    }
    @include mq(medium, max) {
      color: $reverse-color;
      line-height: 0;
      height: 45px;
      font-size: 1.5rem;
      text-align: center;
    }

    &::after {
      @include mq(medium, max) {
        content: "+";
        font-size: 2em;
        float: right;
        transform: translate(-100%, -50%);
      }
    }
  }

  &__icon-area {
    @include mq {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      margin-top: 45px;
      margin-bottom: 30px;
    }
    @include mq(medium, max) {
    }
  }

  &__icon {
    @include mq {
      width: 50px;
      height: 50px;
      display: block;
      content: "";
      transform: translate(-50%, -15%);
      //top: 50%;
      left: 50%;
      position: relative;
    }
    @include mq(medium, max) {
      width: 50px;
      height: 50px;
      display: block;
      content: "";
      transform: translate(0%, 50%);
      top: 50%;
      left: 10%;
      position: relative;
    }

    &__yasekata {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/yasekata_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/yasekata_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/yasekata_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/yasekata_icon_white.svg") no-repeat center;
        }
      }
    }

    &__syokuji {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/syokuji_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/syokuji_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/syokuji_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/syokuji_icon_white.svg") no-repeat center;
        }
      }
    }

    &__kintore {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/kintore_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/kintore_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/kintore_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/kintore_icon_white.svg") no-repeat center;
        }
      }
    }

    &__shiseikaizen {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/shiseikaizen_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/shiseikaizen_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/shiseikaizen_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/shiseikaizen_icon_white.svg") no-repeat center;
        }
      }
    }

    &__biyou {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/biyou_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/biyou_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/biyou_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/biyou_icon_white.svg") no-repeat center;
        }
      }
    }

    &__kyuuyou {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/kyuuyou_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/kyuuyou_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/kyuuyou_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/kyuuyou_icon_white.svg") no-repeat center;
        }
      }
    }

    &__joseinonayami {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/joseinonayami_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/joseinonayami_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/joseinonayami_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/joseinonayami_icon_white.svg") no-repeat center;
        }
      }
    }

    &__toutsuukaizen {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/toutsuukaizen_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/toutsuukaizen_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/toutsuukaizen_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/toutsuukaizen_icon_white.svg") no-repeat center;
        }
      }
    }

    &__sonota {
      @extend .l-top-questions-area__icon;
      @include mq {
        background: url("../images/sonota_icon.svg") no-repeat center;
      }
      @include mq(medium, max) {
        background: url("../images/sonota_icon.svg") no-repeat center;
      }

      &.active {
        @extend .l-top-questions-area__icon;
        @include mq {
          background: url("../images/sonota_icon_white.svg") no-repeat center;
        }
        @include mq(medium, max) {
          background: url("../images/sonota_icon_white.svg") no-repeat center;
        }
      }
    }
  }
}

.l-top-title-area {
  @include mq {
    margin-right: auto;
    margin-left: auto;
    width: 1000px;
    display: flex;
  }
  @include mq(medium, max) {
    padding: 50px 20px 0;
  }

  &__text {
    @include mq {
      font-weight: 700;
      font-size: 3.6rem;
      z-index: 1;
    }
    @include mq(medium, max) {
      font-size: 1.8rem;
      font-weight: 700;
      float: left;
      color: $color-navy;
    }

    &:first-letter {
      color: #f61d6a; //非対応のブラウザでの文字色を設定
      background: linear-gradient(to right, #f61d6a, #ff6b01); //背景色にグラデーションを指定
      -webkit-background-clip: text; //テキストでくり抜く
      -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
    }
  }

  &__text-second {
    @include mq {
      font-weight: 700;
      font-size: 2.6rem;
      z-index: 1;
      margin-right: 10px;
    }
    @include mq(medium, max) {
      font-size: 1.6rem;
      font-weight: 700;
      display: contents;
      color: $color-navy;
    }
  }

  &__border {
    @include mq {
      display: block;
      content: "";
      width: 40px;
      height: 2px;
      background-color: rgb(204, 204, 204);
      margin-top: 25px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include mq(medium, max) {
      display: block;
      content: "";
      width: 40px;
      height: 2px;
      background-color: #cccccc;
      float: left;
      margin-top: 12px;
      margin-left: 10px;
    }
  }

  &__border-second {
    @include mq {
      display: block;
      content: "";
      width: 40px;
      height: 2px;
      background-color: rgb(204, 204, 204);
      margin-top: 25px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include mq(medium, max) {
      display: block;
      content: "";
      width: 25px;
      height: 2px;
      background-color: #cccccc;
      float: left;
      margin-top: 12px;
      margin-right: 10px;
    }
  }

  &__sub {
    @include mq {
      font-size: 1.2rem;
      font-weight: 700;
      transform: translateY(25%);
    }
    @include mq(medium, max) {
      font-weight: 700;
      clear: both;
      color: $color-navy;
    }
  }

  &__sub-second {
    @include mq {
      font-size: 1.2rem;
      font-weight: 700;
      transform: translateY(25%);
    }
    @include mq(medium, max) {
      font-weight: 700;
      padding-left: 35px;
      color: $color-navy;
    }
  }
}

.br-sp {
  @include mq {
    display: none;
  }
  @include mq(medium, max) {
    display: inline-block;
  }
}

.sp-dsp {
  @include mq {
    display: none;
  }

  &__gradation-right {
    @include mq(medium, max) {
      filter: blur(60px);
      display: block;
      content: "";
      width: 300px;
      height: 60px;
      background-color: #ed8a8c;
      position: absolute;
      right: -160px;
      top: 10px;
      z-index: -1;
    }
  }

  &__gradation-left {
    @include mq(medium, max) {
      filter: blur(60px);
      display: block;
      content: "";
      width: 300px;
      height: 60px;
      background-color: #ed8a8c;
      position: absolute;
      left: -160px;
      top: 10px;
      z-index: -1;
    }
  }
}

.l-main-trainer-page {
  img {
    @include mq {
    }
    @include mq(medium, max) {
      width: 330px;
      height: 230px;
      border-radius: 20px;
      margin-right: auto;
      margin-left: auto;
      object-fit: cover;
    }
  }
}
