.c-advertise {
  @include mq {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-around;
  }
  @include mq(medium, max) {
    img {
      margin: 30px auto;
    }
  }
}