.u-hidden-pc {
  @include mq {
    display: none;
  }
}

.u-hidden-sp {
  @include mq(medium, max) {
    display: none;
  }
}

@for $number from 1 through 60 {
  .u-mt#{$number} {
    margin-top: $number + px;
  }
}

// sp tabpanel
.u-sp-news-tabpanel {
  @include mq {
    display: none;
  }
  @include mq(medium, max) {
    margin-top: 30px;
    @include auto;
    display: flex;
    justify-content: center;
  }

  &__box {
    @include mq {
      width: 50%;
    }
    @include mq(medium, max) {
      width: 50%;
      text-align: center;
    }

    &__inner {
      @include mq {
        display: flex;
        justify-content: center;
        background-color: $color-lv3;
        padding: 20px 0;
        position: relative;
      }
      @include mq(medium, max) {
        display: flex;
        justify-content: center;
        background-color: $color-lv3;
        padding: 20px 0;
        position: relative;
      }

      &--reverse {
        @include mq {
          @include pink-to-orange;
        }
        @include mq(medium, max) {
          @include pink-to-orange;
        }
      }
    }

    &__icon {
      @include mq(medium, max) {
        @include box;
        width: 24px;
        height: 24px;
        background-color: $reverse-color;
        border-radius: 50%;
        margin-right: 10px;
      }

      &-lv1 {
        position: relative;
        @include pink-to-orange;

        &::after {
          @include mq(medium, max) {
            @include box;
            position: absolute;
            top: 3px;
            left: 3px;
            width: 18px;
            height: 18px;
            background: url("../images/new-arrival_icon_white.svg");
            background-position: center;
            background-repeat: no-repeat;
            z-index: 3;
          }
        }

        &--reverse {
          position: relative;

          &::after {
            @include mq(medium, max) {
              @include box;
              position: absolute;
              top: 3px;
              left: 3px;
              width: 18px;
              height: 18px;
              background: url("../images/new-arrival_icon_color.svg");
              background-position: center;
              background-repeat: no-repeat;
              z-index: 3;
            }
          }
        }
      }

      &-lv2 {
        @include pink-to-orange;
        position: relative;

        &::after {
          @include mq(medium, max) {
            @include box;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 18px;
            height: 18px;
            background: url("../images/popular_icon_white.svg");
            background-position: center;
            background-repeat: no-repeat;
            z-index: 3;
          }
        }

        &--reverse {
          position: relative;
          background-color: $reverse-color;

          &::after {
            @include mq(medium, max) {
              @include box;
              position: absolute;
              top: 4px;
              left: 4px;
              width: 18px;
              height: 18px;
              background: url("../images/popular_icon_color.svg");
              background-position: center;
              background-repeat: no-repeat;
              z-index: 3;
            }
          }
        }
      }
    }

    &__text {
      @include mq(medium, max) {
        //padding-top: 10px;
        font-size: 1.6rem;
        @include bold;
      }

      &--reverse {
        color: $reverse-color;
      }
    }
  }
}

.u-sp-category {
  @include mq {
    display: none;
  }
  @include mq(medium, max) {
    padding-right: 25px;
    padding-left: 25px;
    flex-wrap: wrap;
    position: relative;

    &__title {
      margin-right: auto;
      margin-left: auto;
      //padding-right: 10px;
      //padding-left: 10px;
      color: $color-navy;
      font-size: 1.4rem;
      padding-top: 10px;
      @include bold;
      @include default-height;
    }

    &__select {
      position: relative;
      margin-top: 10px;
      border-radius: 3px;
      background-color: $color-lv2;
      opacity: 0.5;
      height: 40px;
      width: 100%;
      padding: 10px 20px;
      display: block;

      &::after {
        @include bold;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -21px;
        //margin-right: -10px;
        content: "＋";
        //color: #ccc;
        font-size: 2.8rem;
        //font-family: fontAwesome, serif;
        z-index: -1;
      }

      select {
        width: 100%;
      }

      //&__plus {
      //    position: relative;
      //    padding: 10px 20px;
      //    transform: translateX(-50%);
      //    margin-top: 10px;
      //    border-radius: 3px;
      //    background-color: $color-lv2;
      //    opacity: 0.5;
      //    height: 40px;
      //    width: 100%;
      //}

    }
  }
}
