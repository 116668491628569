* {
  color: $default-black;
  line-height: 1.5;
  @include mq {
    font-size: 1.4rem;
  }
  @include mq(medium, max) {
    font-size: 1.4rem;
  }
}

html {
  background-color: $color-lv9;
}
