//color
$color-fb: #375191;
$color-go: #5186ec;
$color-white: #fff;
$color-re: #131747;
$color-black: #000;
$color-pa: #313131;
$color-red: #e40000;
$color-input: #e5e5e5;
$color-mokuji: #5eb8cf;
$color-border: #e6e6e6;
$color-gray: #808080;
$color-error: #ffdbc9;
$color-error-bg: #fab1af;
$color-orange: #df8645;

.c-sub-title {
  @include mq {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 85px;
  }
  @include mq(medium, max) {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 40px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.c-button {
  @include mq {
    background-color: #121f32;
    padding: 15px 40px;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
  }
  @include mq(medium, max) {
    background-color: #121f32;
    padding: 10px 20px;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
  }

  &__text {
    @include mq {
      color: #fff;
      z-index: 1;
      font-weight: 700;
    }
    @include mq(medium, max) {
      color: #fff;
      z-index: 1;
      font-weight: 700;
    }
  }
}

.c-button-area {
  @include mq {
    text-align: center;
    margin-top: 85px;
  }
  @include mq(medium, max) {
    text-align: center;
  }

  button:focus {
    outline: 0;
  }

  &__fb {
    @include mq {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 47px 15px 47px;
      background-color: $color-fb;
      border-color: $color-fb;
      color: $color-white;
      margin: 35px;
      font-weight: 700;
    }
    @include mq(medium, max) {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 47px 15px 47px;
      background-color: $color-fb;
      border-color: $color-fb;
      color: $color-white;
      margin: 35px;
      font-weight: 700;
      text-align: center;
      display: block;
    }
  }

  &__go {
    @include mq {
      border: 3px solid;
      background-color: $color-go;
      border-radius: 40px;
      padding: 15px 54px 15px 55px;
      border-color: $color-go;
      color: $color-white;
      margin: 35px;
      font-weight: 700;
    }
    @include mq(medium, max) {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 47px 15px 47px;
      background-color: $color-go;
      border-color: $color-go;
      color: $color-white;
      margin: 35px;
      font-weight: 700;
      text-align: center;
      display: block;
    }
  }

  &__re {
    @include mq {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 40px 15px 40px;
      color: $color-white;
      background-color: $color-re;
      border-color: $color-re;
      margin: 35px;
      font-weight: 700;
    }
    @include mq(medium, max) {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 40px 15px 40px;
      color: #fff;
      background-color: #131747;
      border-color: #131747;
      margin: 35px;
      font-weight: 700;
      display: block;
      text-align: center;
      position: relative;
      z-index: 1;
    }
  }

  &__to {
    @include mq {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 59px 15px 59px;
      color: $color-re;
      background-color: $color-white;
      border-color: $color-re;
      margin: 35px;
      font-weight: 700;
    }
    @include mq(medium, max) {
      border: 3px solid;
      border-radius: 40px;
      padding: 15px 59px 15px 59px;
      color: #131747;
      background-color: #fff;
      border-color: #131747;
      margin: 35px;
      font-weight: 700;
      text-align: center;
      display: block;
      position: relative;
      z-index: 1;
    }
  }

  &__ro {
    @include mq {
      width: 300px;
      height: 71px;
      flex-direction: column-reverse;
      margin-right: auto;
      margin-left: auto;
      background-color: #000000;
      border-radius: 50px;
      padding: 25px;
      display: inline-block;
      transition: 0.3s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
    }
    @include mq(medium, max) {
    }

    &::after {
      @include mq {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(to right, #f61d6a, #ff6b01) !important;
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
        left: 0;
        transform: scaleX(0.8);
        border-radius: 45px;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        overflow: hidden;
      }
      @include mq(medium, max) {
      }
    }

    &:hover {
      opacity: 1;

      &::after {
        @include mq {
          opacity: 1;
          transform: scaleX(1.1);
        }
        @include mq(medium, max) {
        }
      }
    }
  }

  &__text {
    color: #fff;
    z-index: 1;
    font-weight: 700;
  }

  &__se {
    @include mq {
      width: 300px;
      height: 71px;
      flex-direction: column-reverse;
      margin-bottom: 35px;
      margin-right: 10px;
      margin-left: 10px;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      display: inline-block;
      transition: 0.3s;
      position: relative;
      //overflow: hidden;
      z-index: 1;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
    }
    @include mq(medium, max) {
      width: 300px;
      height: 71px;
      flex-direction: column-reverse;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      display: block;
      transition: 0.3s;
      position: relative;
      //overflow: hidden;
      z-index: 2;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      text-align: center;
      margin: 35px auto;
    }

    &::after {
      @include mq {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(to right, #f61d6a, #ff6b01) !important;
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
        left: 0;
        transform: scaleX(0.8);
        border-radius: 45px;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        overflow: hidden;
      }
      @include mq(medium, max) {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(to right, #f61d6a, #ff6b01) !important;
        position: absolute;
        z-index: -1;
        opacity: 0;
        top: 0;
        left: 0;
        transform: scaleX(0.8);
        border-radius: 45px;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        overflow: hidden;
      }
    }

    &:hover {
      opacity: 1;

      &::after {
        @include mq {
          opacity: 1;
          transform: scaleX(1);
        }
        @include mq(medium, max) {
        }
      }
    }
  }
}

.c-button-areas {
  @include mq {
    text-align: center;
    margin-top: 20px;
  }
  @include mq(medium, max) {
    text-align: center;
    text-decoration: underline;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }

  &__pa {
    @include mq {
      text-decoration: underline;
      color: $color-pa;
    }
    @include mq(medium, max) {
    }
  }
}

.c-button-display {
  @include mq {
    display: block;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  @include mq(medium, max) {
    display: block;
    text-align: center;
    margin-top: 30px;
    // margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-button-big {
  @include mq {
    width: 600px;
    height: 120px;
    flex-direction: column-reverse;
    margin-right: auto;
    margin-left: auto;
    border-radius: 80px;
    border: solid 2px $color-navy;
    padding: 25px;
    display: inline-block;
    transition: 0.3s;
    position: relative;
    //overflow: hidden;
    z-index: 1;
  }
  @include mq(medium, max) {
    width: 350px;
    height: 10rem;
    flex-direction: column-reverse;
    margin-right: auto;
    margin-left: auto;
    border-radius: 80px;
    border: solid 3px $color-navy;
    padding: 25px;
    display: inline-block;
    transition: 0.3s;
    position: relative;
    //overflow: hidden;
    z-index: 1;
  }

  &__text-area {
    transform: translateY(0px);
  }

  &__text {
    @include bold;
    color: $color-navy;

    &__big {
      @extend .c-button-big__text;
      @include mq {
        font-size: 2.2rem;
      }
      @include mq(medium, max) {
      }
    }
  }
}

.c-login-address {
  @include mq {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 30px auto;
    max-width: 700px;
    align-items: center;
    display: flex;
  }
  @include mq(medium, max) {
    // text-align: center;
    font-size: 1.8rem;
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    font-weight: 700;
    margin-top: 30px;
  }

  &::before,
  &::after {
    @include mq {
      content: "";
      border-top: 2px solid;
      flex-grow: 1;
    }
    @include mq(medium, max) {
      content: "";
      border-top: 1px solid;
      flex-grow: 1;
    }
  }

  &::before {
    @include mq {
      margin-right: 1rem;
    }
    @include mq(medium, max) {
      margin-right: 1rem;
      // max-width: 50px;
    }
  }

  &::after {
    @include mq {
      margin-left: 1rem;
    }
    @include mq(medium, max) {
      margin-left: 1rem;
      // max-width: 50px;
    }
  }

  &__input {
    @include mq {
      justify-content: center;
      display: flex;
      text-align: center;
      font-weight: 700;
      font-size: 1.6rem;
    }
    @include mq(medium, max) {
    }
  }
}

span.required {
  @include mq {
    border: 1px solid;
    border-radius: 4px;
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
    padding: 5px;
    width: 40px;
    height: 26px;
    margin-right: 10px;
    transform: translateY(-5px);
  }
  @include mq(medium, max) {
    border: 1px solid;
    border-radius: 4px;
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
    padding: 5px;
    width: 50px;
    height: 33px;
    margin-right: 10px;
    transform: translateY(-5px);
  }
}

.c-span-optional {
  @include mq {
    border-radius: 4px;
    background-color: $color-input;
    border: 1px solid $color-input;
    padding: 5px;
    width: 40px;
    height: 26px;
    margin-right: 10px;
    transform: translateY(-5px);
  }
  @include mq(medium, max) {
    border-radius: 4px;
    background-color: $color-input;
    border: 1px solid $color-input;
    padding: 5px;
    width: 40px;
    height: 26px;
    margin-right: 10px;
    transform: translateY(-5px);
  }

  &__after {
    @include mq {
      width: calc(100% - 50px);
    }
    @include mq(medium, max) {
    }
  }
}

.c-form-asterisk {
  @include mq(medium, max) {
    padding-top: 10px;
  }
}

.c-input-form {
  @include mq {
    border: 2px solid;
    width: 100%;
    height: 50px;
    border-color: $color-input;
    background-color: $color-input;
    font-size: 15px;
    padding-left: 10px;
    border-radius: 3px;
    margin-right: 5px;
  }
  @include mq(medium, max) {
    border: 2px solid;
    width: 330px;
    height: 50px;
    border-color: $color-input;
    background-color: $color-input;
    font-size: 15px;
    padding: 10px;
    // padding-left: 10px;
    border-radius: 3px;
    // margin-right: 5px;
    z-index: 2;
    position: relative;
  }

  &__th {
    @include mq {
      padding-right: 120px;
      font-size: 1.5rem;
    }
    @include mq(medium, max) {
    }
  }
}

.c-text-area {
  @include mq {
    padding: 10px;
    border: 2px solid;
    width: 100%;
    border-color: $color-input;
    background-color: $color-input;
    border-radius: 3px;
    margin-right: 5px;
  }
  @include mq(medium, max) {
    padding: 10px;
    border: 2px solid;
    width: 100%;
    border-color: $color-input;
    background-color: $color-input;
    border-radius: 3px;
    margin-right: 5px;
  }
}

.c-select-form {
  @include mq {
    border: 2px solid;
    width: 100%;
    height: 50px;
    border-color: $color-input;
    background-color: $color-input;
    font-size: 15px;
    padding-left: 10px;
    border-radius: 3px;
  }
  @include mq(medium, max) {
    border: 2px solid;
    width: 330px;
    height: 50px;
    border-color: #e5e5e5;
    background-color: #e5e5e5;
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    z-index: 2;
    position: relative;
  }

  &__short {
    @extend .c-select-form;
    @include mq {
      width: 49%;
    }
    @include mq(medium, max) {
      width: 49%;
    }
  }
}

.c-reset-pass {
  &__text {
    @include mq {
      font-weight: 700;
      font-size: 1.5rem;
      text-align: center;
      margin-top: 35px;
    }
    @include mq(medium, max) {
      font-weight: 700;
      font-size: 1.5rem;
      text-align: center;
      margin-top: 35px;
    }
  }
}

.c-mail-subtitle {
  @include mq {
    text-align: center;
    @include bold;
    font-size: 18px;
  }
  @include mq(medium, max) {
    text-align: center;
    @include bold;
    font-size: 2rem;
    color: #787878;
    padding-top: 30px;
    // padding-bottom: 20px;
  }
}

.c-mail-details {
  @include mq {
    max-width: 680px;
    margin: 75px auto;
    text-align: -webkit-left;
    border-top: 2px solid $color-border;
    border-bottom: 2px solid $color-border;
    padding: 35px 0;
  }
  @include mq(medium, max) {
    border-top: 2px solid #cccccc;
    border-bottom: 2px solid #cccccc;
    margin-right: auto;
    margin-left: auto;
    width: 330px;
  }

  &__short {
    @include mq {
      font-weight: 700;
      margin-top: 35px;
    }
    @include mq(medium, max) {
      font-weight: 700;
      font-size: 1.6rem;
      color: #616161;
      padding-top: 20px;
      padding-bottom: 5px;
    }
  }

  &__short2 {
    @include mq {
      margin-top: 35px;
    }
    @include mq(medium, max) {
      padding-top: 20px;
    }
  }

  &__text {
    @include mq(medium, max) {
      padding-bottom: 30px;
    }
  }

  &__text2 {
    @include mq {
      font-weight: 700;
    }
    @include mq(medium, max) {
      font-weight: 700;
      font-size: 2rem;
      color: #616161;
    }
  }
}

.c-404-not-found {
  @include mq {
    font-weight: 700;
    font-size: 3.6rem;
    margin: 70px 105px auto;
    width: 1200px;
  }
  @include mq(medium, max) {
  }
}

.c-not-found {
  &__short {
    @include mq {
      font-weight: 700;
      font-size: 2.2rem;
      width: 1200px;
      margin: 3% 22% 3% 22%;
    }
    @include mq(medium, max) {
      margin: 15%;
      font-weight: 700;
      text-align: center;
      max-width: 90%;
    }
  }

  &__text {
    @include mq {
      max-width: 552px;
      margin: 0 auto;
    }
    @include mq(medium, max) {
      max-width: 90%;
      margin: 22% 15%;
    }
  }
}

.c-news-side-title {
  @include mq {
    color: $color-lv1;
    position: absolute;
    @include default-height;
    font-size: 5rem;
    display: block;
    @include bold;
    text-shadow: 0 0 500px $color-lv1;
  }
  @include mq(medium, max) {
    color: $color-lv1;
    position: absolute;
    z-index: -1;
    @include default-height;
    font-size: 4rem;
    display: block;
    @include bold;
    text-shadow: 0 0 500px $color-lv1;
    white-space: nowrap;
  }

  &::before {
    @include mq {
      filter: blur(200px);
      @include box;
      width: 300px;
      height: 150px;
      background-color: $color-lv1;
      position: absolute;
      transform: translateY(100%);
      left: 10%;
    }
    @include mq(medium, max) {
      filter: blur(60px);
      @include box;
      width: 200px;
      height: 200px;
      background-color: $color-lv1;
      position: absolute;
      //left: 10%;
      top: -50%;
    }
  }

  &__right {
    @extend .c-news-side-title;
    @include mq {
      top: -50px;
      right: 40px;
      transform: rotate(90deg) translate(100%, 100%);
      transform-origin: 100% 100%;
    }
    @include mq(medium, max) {
      top: -30px;
      right: 0;
      transform: rotate(90deg) translate(100%, 100%);
      transform-origin: 100% 100%;
    }
  }

  &__left {
    @extend .c-news-side-title;
    @include mq {
      top: 185px;
      left: -155px;
      transform: rotate(-90deg);
    }
    @include mq(medium, max) {
      top: 120px;
      left: -135px;
      transform: rotate(-90deg);
    }

    &__tabpanel {
      @extend .c-news-side-title;
      @include mq {
        top: 185px;
        left: -130px;
        transform: rotate(-90deg);
      }
      @include mq(medium, max) {
        top: 120px;
        left: -108px;
        transform: rotate(-90deg);
      }
    }
  }
}

.c-new-icon {
  &__pc {
    @include mq {
      width: 60px;
      height: 60px;
      @include box;
      background: url("../images/new_icon.svg");
      background-size: cover;
      position: absolute;
      z-index: 1;
      flex: none;
    }
    @include mq(medium, max) {
      width: 60px;
      height: 60px;
      @include box;
      background: url("../images/new_icon.svg");
      background-size: cover;
      position: absolute;
      z-index: 1;
    }

    &__big {
      @extend .c-new-icon__pc;
      @include mq {
        position: relative;
        right: 60px;
        transform: translateY(-30%);
      }
      @include mq(medium, max) {
        display: none;
      }
    }
  }

  &__tabpanel {
    @extend .c-new-icon__pc;
    @include mq {
      left: 340px;
      //transform: translateY(90%);
      //position: relative;
    }
    @include mq(medium, max) {
      right: 7px;
      transform: translateY(-30%);
    }
  }

  &__sp {
    @include mq {
      display: none;
    }
    @include mq(medium, max) {
      width: 60px;
      height: 60px;
      @include box;
      background: url("../images/new_icon.svg");
      background-size: cover;
      position: absolute;
      z-index: 1;
    }

    &__big {
      @extend .c-new-icon__sp;
      @include mq(medium, max) {
        right: 7px;
        transform: translateY(-30%);
        //left: 305px;
        //top: -20px;
      }
    }
  }
}

.c-social-button-area {
  @include mq {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: solid $color-lv6;
    border-width: 2px 0 2px 0;
  }
  @include mq(medium, max) {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: solid $color-lv6;
    border-width: 2px 0 2px 0;
  }

  &__inner {
    @include mq {
      text-align: center;
      position: relative;
    }
    @include mq(medium, max) {
      text-align: center;
      position: relative;
    }
  }
}

.c-form-error {
  color: red;

  &__td {
    border-color: red;
    background-color: $color-error-bg;
  }
}

.c-form-table {
  @include mq {
    width: 700px;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
  }

  @include mq(medium, max) {
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
    z-index: 1;
    position: relative;
  }

  table {
    width: 100%;
  }

  tr {
    @include mq {
      width: 100%;
    }
    @include mq(medium, max) {
      max-width: 330px;
      // display: flex;
      line-height: 35px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  th {
    @include mq(medium, max) {
      display: block;
      width: 330px;
      text-align: left;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__sp-check {
    td:nth-child(even) {
      margin-bottom: 25px;
    }
  }

  &__sp {
    @include mq(medium, max) {
      display: flex;
    }
  }

  &__text {
    @include mq(medium, max) {
      position: relative;
      top: -15px;
    }
  }

  &__file {
    @include mq {
    }
    @include mq(medium, max) {
      border: 2px solid;
      width: 330px;
      height: 200px;
      border-color: #e5e5e5;
      background-color: #e5e5e5;
      font-size: 15px;
      padding: 10px;
      border-radius: 3px;
      z-index: 2;
      position: relative;
      margin-bottom: 15px;
    }
  }

  &__th {
    @include mq {
      @include bold;
      padding: 2rem;
      text-align: left;
      width: 40%;
    }
    @include mq(medium, max) {
    }

    &__display {
      @include mq {
        @include bold;
        padding: 2rem;
        text-align: left;
        //display: flex;
      }
      @include mq(medium, max) {
      }
    }
  }

  &__td {
    @include mq {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 3.5rem;
      text-align: left;
      width: 60%;
    }
    @include mq(medium, max) {
      display: block;
      // padding: 15px 15px 40px;
      margin-top: 15px;
      margin-bottom: 40px;
      margin-right: auto;
      margin-left: auto;
      width: 330px;
    }

    &__left {
      @include mq {
        @include bold;
        //padding: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 3.5rem;
        text-align: left;
        width: 40%;
        vertical-align: top;
      }
      @include mq(medium, max) {
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 325px;
        @include bold;
      }
    }

    &__error {
      @include mq {
        padding-left: 2rem;
        text-align: left;
        font-size: 12px;
        color: $color-red;
      }
      @include mq(medium, max) {
        display: block;
        margin-right: auto;
        margin-left: auto;
        color: $color-red;
        width: 330px;
        @include bold;
      }
    }

    &__image-area {
      @include mq {
        display: flex;
        margin-bottom: 20px;
      }
      @include mq(medium, max) {
        padding-top: 20px;
      }

      span {
        @include mq {
          width: 45%;
          margin-right: 20px;
        }
        @include mq(medium, max) {
        }
      }

      img {
        @include mq {
        }
        @include mq(medium, max) {
          height: 230px;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      video {
        @include mq {
          width: 200%;
        }
        @include mq(medium, max) {
          width: 100%;
          height: 200px;
          object-fit: cover;
          margin-bottom: 20px;
        }
      }
    }
  }

  &__error {
    @include mq {
      font-size: 12px;
      color: $color-red;
    }
    @include mq(medium, max) {
    }
  }

  &__radio:not(:last-child) {
    @include mq {
      margin-right: 2.5rem;
    }
    @include mq(medium, max) {
      padding-right: 50px;
      padding-bottom: 10px;
    }
  }

  &__check-area {
    @include mq {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    @include mq(medium, max) {
      width: 330px;
      margin-right: auto;
      margin-left: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__title {
      @include mq {
        margin-top: 30px;
        margin-bottom: 10px;
        @include bold;
      }
      @include mq(medium, max) {
        margin-top: 30px;
        margin-bottom: 10px;
        margin-right: auto;
        margin-left: auto;
        width: 330px;
        font-weight: 700;
      }
    }

    &__each {
      @include mq {
        //width: calc(calc(100% / 4) - calc(60px / 4));
        margin: 15px;
      }
      @include mq(medium, max) {
      }

      //&:not(:nth-of-type(4n))
      //{
      //  @include mq {
      //    margin-right: 20px;
      //  }
      //  @include mq(medium, max) {
      //  }
      //}
    }

    input {
      @include mq(medium, max) {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        margin-right: 0.5em;
        margin-top: 10px;
      }
    }

    label {
      @include mq(medium, max) {
        display: inline-grid;
        max-width: 130px;
      }
    }

    li {
      @include mq(medium, max) {
        padding-bottom: 10px;
      }
    }
  }
}

.c-praivacy-policy {
  @include mq {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-input;
    border-radius: 3px;
    overflow: scroll;
    height: 245px;
  }
  @include mq(medium, max) {
    width: 330px;
    height: 200px;
    // height: 185px;
    margin-right: auto;
    margin-left: auto;
    background-color: #e5e5e5;
    border-radius: 3px;
    // border: 2px solid #e5e5e5;
    padding: 15px;
    overflow: scroll;
  }

  &__text {
    @include mq {
      color: $color-gray;
      padding: 15px;
      text-align: left;
    }
    @include mq(medium, max) {
      padding-bottom: 20px;
    }
  }

  &__check {
    @include mq {
      margin: 40px;
    }
    @include mq(medium, max) {
      margin-right: auto;
      margin-left: auto;
      width: 300px;
      margin-top: 25px;
    }
  }
}

.c-is-invalid {
  border-color: $color-red;
  background-color: $color-error;
}

.c-page-title-area {
  @include mq {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  @include mq(medium, max) {
    position: relative;
    z-index: 1;
  }

  &__sub {
    @include mq {
      @include bold;
      font-size: 2.2rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include mq(medium, max) {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 15px;
      width: 330px;
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
    }
  }

  &__short-bold {
    @include mq {
      font-weight: 700;
    }
    @include mq(medium, max) {
      margin-right: auto;
      margin-left: auto;
      width: 330px;
      font-weight: 700;
    }
  }

  &__short-normal {
    @include mq(medium, max) {
      margin-right: 15px;
      margin-left: 15px;
      width: 330px;
    }
  }

  &__short-complete {
    @include mq(medium, max) {
      margin-right: auto;
      margin-left: auto;
      width: 330px;
    }
  }
}

.c-bold {
  @include mq {
    @include bold;
  }
  @include mq(medium, max) {
    @include bold;
  }
}

.c-with {
  @include mq {
    &__13px {
      width: 13px !important;
    }

    &__21px {
      width: 21px !important;
    }

    &__25 {
      width: 25% !important;
    }

    &__25px {
      width: 25px !important;
    }

    &__30 {
      width: 30% !important;
    }

    &__30px {
      width: 30px !important;
    }

    &__36 {
      width: 36% !important;
    }

    &__40px {
      width: 40px !important;
    }

    &__40 {
      width: 40% !important;
    }

    &__45 {
      width: 45% !important;
    }

    &__50 {
      width: 50% !important;
    }

    &__50px {
      width: 50px !important;
    }

    &__55px {
      width: 55px !important;
    }

    &__60 {
      width: 60% !important;
    }

    &__60px {
      width: 60px !important;
    }

    &__70 {
      width: 70% !important;
    }

    &__70px {
      width: 70px !important;
    }

    &__80 {
      width: 80% !important;
    }

    &__80px {
      width: 80px !important;
    }

    &__90 {
      width: 90% !important;
    }

    &__100 {
      width: 100% !important;
    }

    &__100px {
      width: 100px !important;
    }

    &__150 {
      width: 150px !important;
    }

    &__180px {
      width: 180px !important;
    }

    &__195 {
      width: 195px !important;
    }

    &__200 {
      width: 200px !important;
    }

    &__236 {
      width: 236px !important;
    }

    &__250 {
      width: 250px !important;
    }

    &__300 {
      width: 300px !important;
    }

    &__330 {
      width: 330px !important;
    }

    &__400 {
      width: 400px !important;
    }

    &__600 {
      width: 600px !important;
    }

    &__700 {
      width: 700px !important;
    }

    &__800 {
      width: 800px !important;
    }

    &__850 {
      width: 850px !important;
    }

    &__900 {
      width: 900px !important;
    }

    &__990 {
      width: 990px !important;
    }

    &__1000 {
      width: 1000px !important;
    }

    &__1020 {
      width: 1020px !important;
    }

    &__1200 {
      width: 1200px !important;
    }

    &__divide2 {
      width: calc(100% / 2) !important;
    }

    &__divide3 {
      width: calc(100% / 3) !important;
    }

    &__divide4 {
      width: calc(100% / 4) !important;
    }
  }
  @include mq(medium, max) {
    &__sp25px {
      width: 25px !important;
    }
    &__sp30 {
      width: 30% !important;
    }
    &__sp30px {
      width: 30px;
    }
    &__sp40 {
      width: 40%;
    }
    &__sp40px {
      width: 40px;
    }
    &__sp32 {
      width: 32%;
    }
    &__sp47 {
      width: 47%;
    }
    &__sp70 {
      width: 70%;
    }
    &__sp100 {
      width: 100% !important;
    }
    &__sp50px {
      width: 50px !important;
    }
    &__sp55px {
      width: 55px !important;
    }
    &__sp60px {
      width: 60px !important;
    }
    &__sp70px {
      width: 70px !important;
    }
    &__sp79px {
      width: 79px !important;
    }
    &__sp80px {
      width: 80px !important;
    }
    &__sp90px {
      width: 90px !important;
    }
    &__sp100px {
      width: 100px !important;
    }
    &__sp110px {
      width: 110px !important;
    }
    &__sp120px {
      width: 120px !important;
    }
    &__sp140px {
      width: 140px !important;
    }
    &__sp170px {
      width: 170px !important;
    }
    &__sp160px {
      width: 160px !important;
    }
    &__sp180px {
      width: 180px !important;
    }
    &__sp200px {
      width: 200px !important;
    }
    &__sp280px {
      width: 280px !important;
    }
    &__sp300px {
      width: 300px !important;
    }
    &__sp325px {
      width: 325px !important;
    }
    &__sp330px {
      width: 330px !important;
    }
    &__sp334px {
      width: 334px !important;
    }
    &__sp335px {
      width: 335px !important;
    }
    &__sp350px {
      width: 350px !important;
    }
    &__sp375 {
      width: 375px;
    }
    &__sp-auto {
      width: auto !important;
    }
    &__sp-divide2 {
      width: calc(100% / 2) !important;
    }

    &__sp-divide4 {
      width: calc(100% / 4) !important;
    }
  }
}

.c-max-width {
  @include mq {
    &__990px {
      max-width: 990px !important;;
    }

    &__none {
      max-width: none !important;;
    }

    &__100 {
      max-width: 100% !important;
    }
  }
  @include mq(medium, max) {

  }
}

.c-height {
  @include mq {
    &__auto {
      height: auto !important;
    }

    &__21px {
      height: 21px !important;
    }

    &__25px {
      height: 25px !important;
    }

    &__30px {
      height: 30px !important;
    }

    &__40px {
      height: 40px !important;
    }

    &__50px {
      height: 50px !important;
    }

    &__60px {
      height: 60px !important;
    }

    &__70px {
      height: 70px !important;
    }

    &__80 {
      height: 80px !important;
    }

    &__150 {
      height: 150px !important;
    }

    &__200 {
      height: 200px !important;
    }

    &__300 {
      height: 300px !important;
    }

    &__400 {
      height: 400px !important;
    }

    &__450 {
      height: 450px !important;
    }

    &__540 {
      height: 540px !important;
    }

    &__550 {
      height: 550px !important;
    }

    &__557 {
      height: 557px !important;
    }
  }
  @include mq(medium, max) {
    &__sp25px {
      height: 25px !important;
    }
    &__sp30 {
      height: 30px !important;
    }
    &__sp40 {
      height: 40px !important;
    }
    &__sp50 {
      height: 50px !important;
    }
    &__sp60 {
      height: 60px !important;
    }
    &__sp71 {
      height: 71px !important;
    }
    &__sp110 {
      height: 110px !important;
    }
    &__sp120 {
      height: 120px !important;
    }
    &__sp150 {
      height: 150px !important;
    }
    &__sp155 {
      height: 155px !important;
    }
    &__sp188 {
      height: 188px !important;
    }
    &__sp200 {
      height: 200px !important;
    }
    &__sp210 {
      height: 210px !important;
    }
    &__sp300 {
      height: 300px !important;
    }
    &__sp370 {
      height: 370px !important;
    }
    &__sp450 {
      height: 450px !important;
    }
    &__sp-auto {
      height: auto !important;
    }
  }
}

.c-transform {
  @include mq {
    &__none {
      transform: none !important;
    }

    &__y40-x-100 {
      transform: translateY(40px) translateX(-100px) !important;
    }

    &__y10 {
      transform: translateY(10px) !important;
    }

    &__y-20 {
      transform: translateY(-20px) !important;
    }

    &__y30 {
      transform: translateY(30px) !important;
    }

    &__x32per {
      transform: translateX(32%) !important;
    }
  }
  @include mq(medium, max) {
    &__y-10-sp {
      transform: translateY(-10%) !important;
    }

    &__y-20-sp {
      transform: translateY(-20%) !important;
    }

    &__sp-x50-y100 {
      transform: translateX(50%) translateY(100%) !important;
    }
  }
}

.c-margin-top {
  @include mq {
    &__0 {
      margin-top: 0 !important;
    }

    &__10 {
      margin-top: 10px !important;
    }

    &__20 {
      margin-top: 20px !important;
    }

    &__30 {
      margin-top: 30px !important;
    }

    &__40 {
      margin-top: 40px !important;
    }

    &__50 {
      margin-top: 50px !important;
    }

    &__60 {
      margin-top: 60px !important;
    }

    &__70px {
      margin-top: 70px !important;
    }
  }
  @include mq(medium, max) {
    &__0sp {
      margin-top: 0 !important;
    }
    &__20 {
      margin-top: 20px;
    }
    &__20sp {
      margin-top: 20px;
    }
    &__sp10 {
      margin-top: 10px;
    }
    &__sp30 {
      margin-top: 30px !important;
    }
    &__sp80 {
      margin-top: 80px !important;
    }
  }
}

.c-margin-bottom {
  &__0 {
    margin-bottom: 0 !important;
  }

  &__10 {
    margin-bottom: 10px !important;
  }

  &__20px {
    margin-bottom: 20px !important;
  }

  &__40 {
    margin-bottom: 40px !important;
  }

  &__50 {
    margin-bottom: 50px !important;
  }

  @include mq {
    &__50 {
      margin-bottom: 50px !important;
    }

    &__85 {
      margin-bottom: 85px !important;
    }
  }
  @include mq(medium, max) {
    &__sp0 {
      margin-bottom: 0 !important;
    }
    &__sp5 {
      margin-bottom: 5px !important;
    }
    &__sp10 {
      margin-bottom: 10px !important;
    }
    &__sp20 {
      margin-bottom: 20px !important;
    }
    &__sp30 {
      margin-bottom: 30px !important;
    }
    &__sp50 {
      margin-bottom: 50px !important;
    }
    &__20 {
      margin-bottom: 20px;
      display: block;
      width: 100%;
      padding-right: 60px;
    }
  }
}

.c-margin-auto {
  @include mq {
    margin-left: auto;
    margin-right: auto;
  }

  @include mq(medium, max) {
    &__sp {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}

.c-margin-right {
  @include mq {
    &__0 {
      margin-right: 0 !important;
    }
    &__10 {
      margin-right: 10px !important;
    }
    &__20 {
      margin-right: 20px !important;
    }
    &__30 {
      margin-right: 30px !important;
    }
  }
  @include mq(medium, max) {
    &__sp5 {
      margin-right: 5px;
    }
    &__sp15 {
      margin-right: 15px !important;
    }
  }
}

.c-margin-left {
  @include mq {
    &__0 {
      margin-left: 0 !important;
    }
    &__-45 {
      margin-left: -45px;
    }
  }
  @include mq(medium, max) {
    &__sp0 {
      margin-left: 0 !important;
    }
    &__sp15px {
      margin-left: 15px !important;
    }
  }
}

.c-margin-LR {
  @include mq {
    &__5 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    &__7 {
      margin-left: 7% !important;
      margin-right: 7% !important;
    }

    &__10 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    &__20 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    &__30 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }

    &__220 {
      margin-left: 220px !important;
      margin-right: 220px !important;
    }

    &__290 {
      margin-left: 290px !important;
      margin-right: 290px !important;
    }
  }
  @include mq(medium, max) {
    &__sp0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    &__sp5 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    &__sp10 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    &__sp30 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
  }
}

.c-padding {
  @include mq {
    &__0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__left0 {
      padding-left: 0 !important;
    }

    &__5-all {
      padding: 5px !important;
    }

    &__2em-all {
      padding: 2em !important;
    }

    &__LR10 {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }

    &__TB1em {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }

    &__LR2em {
      padding-right: 2em !important;
      padding-left: 2em !important;
    }

    &__10 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    &__20 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }

    &__bottom10 {
      padding-bottom: 10px !important;
    }

    &__bottom20 {
      padding-bottom: 20px !important;
    }

    &__20-all {
      padding: 20px !important;
    }

    &__30 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }

    &__40 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    &__300 {
      padding-left: 300px !important;
      padding-right: 300px !important;
    }

    &__top0 {
      padding-top: 0 !important;
    }

    &__top20 {
      padding-top: 20px !important;
    }

    &__top30 {
      padding-top: 30px !important;
    }

    &__top40 {
      padding-top: 40px !important;
    }

    &__left-1em {
      padding-left: 1em !important;
    }

    &__left-3em {
      padding-left: 3em !important;
    }

    &__left-6em {
      padding-left: 6em !important;
    }

    &__accordion {
      padding: 40px 20px 40px 75px;
    }
  }
  @include mq(medium, max) {
    &__sp0 {
      padding: 0 !important;
    }
    &__sp1em-all {
      padding: 1em !important;
    }
    &__sp-LR5 {
      padding-right: 5px !important;
      padding-left: 5px !important;
    }
    &__sp-LR1em {
      padding-right: 1em !important;
      padding-left: 1em !important;
    }
    &__sp10 {
      padding: 10px !important;
    }
    &__sp-TB1em {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
    &__sp-TB10 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    &__sp-LR15 {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    &__sp20 {
      padding: 20px;
    }
    &__sp20-all {
      padding: 20px !important;
    }
    &__sp-right {
      padding-right: 20px;
      display: block;
      width: 125px;
    }
    &__sp-left-1em {
      padding-left: 1em !important;
    }
    &__sp-left20 {
      padding-left: 20px;
    }
    &__sp-left45 {
      padding-left: 45px !important;
    }
    &__sp-top10 {
      padding-top: 10px !important;
    }
    &__sp-top0 {
      padding-top: 0 !important;
    }
    &__sp-top20 {
      padding-top: 20px !important;
    }
    &__sp-top30 {
      padding-top: 30px !important;
    }
    &__sp-top50 {
      padding-top: 50px;
    }
    &__sp-bottom0 {
      padding-bottom: 0 !important;
    }
    &__sp-bottom20 {
      padding-bottom: 20px !important;
    }
    &__sp-bottom25 {
      padding-bottom: 25px !important;
    }
    &__accordion-sp {
      padding: 28px 18px 31px 18px;
    }
  }
}

.c-border {
  @include mq {
    border: 2px solid;
  }
  @include mq(medium, max) {
    border: 2px solid;
  }
}

.c-border {
  &__top {
    @include mq {
      border-top: 2px solid #cccccc;
    }
    @include mq(medium, max) {
      border-top: 2px solid #cccccc;
      width: 330px;
    }
  }

  &__short {
    @include mq {
      display: block;
      content: "";
      width: 6px;
      height: 2px;
      background-color: #222;
      margin-top: 12px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include mq(medium, max) {
      display: block;
      content: "";
      width: 6px;
      height: 2px;
      background-color: #222;
      margin-top: 12px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__color-gray {
    @include mq {
      border-color: #cccccc !important;
    }
    @include mq(medium, max) {
      border-color: #cccccc !important;
    }
  }

  &__color-red {
    @include mq {
      border-color: red !important;
    }
    @include mq(medium, max) {
      border-color: red !important;
    }
  }

  &__2px-solid {
    @include mq {
      border: 2px solid #cccccc;
    }
    @include mq(medium, max) {
      border: 2px solid #cccccc;
    }
  }

  &__2px-solid-sp {
    @include mq(medium, max) {
      border: 2px solid;
    }
  }

  &__bottom {
    @include mq {
      border-bottom: 2px solid #cccccc !important;
    }
    @include mq(medium, max) {
      border-bottom: 2px solid #cccccc !important;;
      // width: 330px;
    }
  }

  &__radius-3px {
    @include mq {
      border-radius: 3px !important;
    }
    @include mq(medium, max) {
      border-radius: 3px !important;
    }
  }

  &__radius-5px {
    @include mq {
      border-radius: 5px !important;
    }
    @include mq(medium, max) {
      border-radius: 5px !important;
    }
  }

  &__radius15-sp {
    @include mq(medium, max) {
      border-radius: 15px !important;
    }
  }

  &__radius50-sp {
    @include mq(medium, max) {
      border-radius: 50px !important;
    }
  }

  &__radius-none-pc {
    @include mq {
      border-radius: 0 !important;
    }
  }

  &__radius-none-sp {
    @include mq(medium, max) {
      border-radius: 0 !important;
    }
  }

  &__right-2px {
    @include mq {
      border-right: 2px solid #cccccc !important;
    }
    @include mq(medium, max) {
      border-right: 2px solid #cccccc !important;
    }
  }

  &__right-none-pc {
    @include mq {
      border-right: none !important;
    }
  }

  &__left-none-pc {
    @include mq {
      border-left: none !important;
    }
  }

  &__none-sp {
    @include mq(medium, max) {
      border: none !important;
    }
  }

  &__radius {
    border-radius: 50% !important;
  }

  &__notification-sp {
    @include mq(medium, max) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &__orange-sp {
    @include mq(medium, max) {
      border-left: 4px solid #f61d6a;
      border-right: 4px solid #ff6b01;
    }
  }
}

.c-display {
  @include mq {
    display: flex !important;
  }
  @include mq {
    &__none-pc {
      display: none !important;
    }
    &__inline-block {
      display: inline-block !important;
    }
    &__table {
      display: table !important;
    }
  }
  @include mq(medium, max) {
    &__none-sp {
      display: none !important;
    }
    &__block-sp {
      display: block !important;
    }
    &__flex-sp {
      display: flex !important;
    }
    &__inline-block-sp {
      display: inline-block !important;
    }
    &__grid-sp {
      display: grid !important;
    }
  }
}

.c-vertical-align {
  @include mq {
    &__middle {
      vertical-align: middle !important;
    }
  }
  @include mq(medium, max) {
    &__sp-middle {
      vertical-align: middle !important;
    }
  }
}

.c-justify-content {
  @include mq {
    &__space-between-pc {
      justify-content: space-between !important;
    }
    &__flex-start {
      justify-content: flex-start !important;
    }
    &__center {
      justify-content: center !important;
    }
    &__space-around {
      justify-content: space-around !important;
    }
  }
  @include mq(medium, max) {
    &__space-between-sp {
      justify-content: space-between !important;
    }
    &__initial-sp {
      justify-content: initial !important;
    }
    &__flex-start-sp {
      justify-content: flex-start !important;
    }
    &__center-sp {
      justify-content: center !important;
    }
  }
}

.c-align-items {
  @include mq {
    &__center {
      align-items: center !important;
    }
  }
  @include mq(medium, max) {
    &__center-sp {
      align-items: center !important;
    }
  }
}

.c-text-decoration {
  text-decoration: underline;

  &__none {
    text-decoration: none;
  }

  &__line-through {
    text-decoration: line-through;
  }
}

.c-table-blue {
  @include mq {
    border: 2px solid #c9cbd7;
    table-layout: fixed;
  }
  @include mq(medium, max) {
    border: 2px solid #c9cbd7;
    table-layout: fixed;
  }

  th:nth-child(1) {
    @include mq {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      background-color: #e5e5e5;
      width: 17%;
    }
    @include mq(medium, max) {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      background-color: #e5e5e5;
      width: 60px;
      display: table-cell;
    }
  }

  th:nth-child(2) {
    @include mq {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      text-align: center;
      background-color: #e5e5e5;
      width: 25%;
      vertical-align: middle;
    }
    @include mq(medium, max) {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      text-align: center;
      background-color: #e5e5e5;
      width: 70px;
      vertical-align: middle;
      display: table-cell;
    }
  }

  th:nth-child(3) {
    @include mq {
      text-align: center;
      background-color: #aaacc1;
      color: #fff;
      width: 25%;
      vertical-align: middle;
    }
    @include mq(medium, max) {
      text-align: center;
      background-color: #aaacc1;
      color: #fff;
      width: 70px;
      vertical-align: middle;
      display: table-cell;
    }
  }

  td:nth-child(1) {
    @include mq {
      text-align: center;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      color: #13164b;
      padding: 0 0 10px;
      vertical-align: middle;
    }
    @include mq(medium, max) {
      text-align: center;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      color: #13164b;
      vertical-align: middle;
    }
  }

  td:nth-child(2) {
    @include mq {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      text-align: center;
      color: #13164b;
      padding: 15px;
      vertical-align: middle;
    }
    @include mq(medium, max) {
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #c9cbd7;
      text-align: center;
      color: #13164b;
      padding: 15px;
      vertical-align: middle;
    }
  }

  td:nth-child(3) {
    @include mq {
      color: #fff;
      background-color: #13164b;
      border-bottom: 2px solid;
      text-align: center;
      vertical-align: middle;
    }
    @include mq(medium, max) {
      color: #fff;
      background-color: #13164b;
      border-bottom: 2px solid;
      text-align: center;
      vertical-align: middle;
    }
  }

  tr {
    @include mq {
      height: 77px;
    }
    @include mq(medium, max) {
      height: 77px;
    }
  }

  tr:last-child td:last-child {
    color: #fff;
    background-color: #13164b;
    text-align: center;
    border-bottom: 2px solid;
    border-color: #c9cbd7;
    vertical-align: middle;
  }

  &__text {
    color: #787878;
    margin-top: 10px;
  }
}

.c-border-text {
  @include mq {
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  @include mq(medium, max) {
  }

  &__left {
    @include mq {
      @include bold;
    }
    @include mq(medium, max) {
    }
  }

  &__border {
    @include mq {
      display: block;
      content: "";
      width: 30px;
      height: 1px;
      background-color: #222222;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    @include mq(medium, max) {
    }
  }

  &__right {
    @include mq {
    }
    @include mq(medium, max) {
    }
  }
}

.c-trainer-merit-area {
  @include mq {
    display: flex;
  }
  @include mq(medium, max) {
  }
}

.c-trainer-merit1 {
  @include mq {
    width: 208px;
    //float: left;
  }
  @include mq(medium, max) {
    margin-right: 15px;
    margin-left: 15px;
    padding-bottom: 20px;
  }
}

.c-trainer-merit {
  @include mq {
    width: 208px;
    //float: left;
    margin-left: 38px;
  }
  @include mq(medium, max) {
    margin-right: 15px;
    margin-left: 15px;
    padding-bottom: 20px;
  }
}

.c-circle-wrapper {
  @include mq {
    display: flex;
    justify-content: center;
  }
  @include mq(medium, max) {
  }
}

.c-circle-number {
  @include mq {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    background: #131747;
    font-size: 24px;
    margin-bottom: 20px;
  }
  @include mq(medium, max) {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    background: #131747;
    font-size: 24px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  span {
    color: $reverse-color;
  }
}

.c-merit-title {
  @include mq {
    height: 70px;
    text-align: center;
    font-weight: 700;
    color: #131747;
  }
  @include mq(medium, max) {
    text-align: center;
    font-weight: 700;
    font-size: 1.6rem;
    color: #131747;
    margin-bottom: 10px;
  }
}

.c-merit-text {
  @include mq {
    color: #131747;
  }
  @include mq(medium, max) {
    color: #131747;
  }
}

.c-payment-area {
  @include mq {
    width: 700px;
    background-color: $color-input;
    margin: 30px auto 60px;
    padding: 30px;
  }
  @include mq(medium, max) {
    width: auto;
    background-color: #e5e5e5;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 15px;
    position: relative;
    z-index: 1;
    text-align: center;
  }

  &__text-area {
    @include mq {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      @include bold;
      font-size: 1.6rem;
    }
    @include mq(medium, max) {
      @include bold;
      font-size: 1.4rem;
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  table {
    width: 100%;
    //border-top: 2px solid;

    .left {
      text-align: left;
      padding-top: 20px;
      padding-left: 30px;
    }

    .right {
      text-align: right;
      padding-right: 30px;
    }
  }

  p,
  td {
    @include bold;
    color: $color-navy;
  }

  td {
    width: 50%;
  }

  &__caution {
    @include mq {
      width: 700px;
      margin: 30px auto 60px;
      padding: 30px;
      border: 2px solid $color-red;
    }
    @include mq(medium, max) {
      width: auto;
      margin: 30px 15px;
      padding: 15px;
      border: 2px solid $color-red;
      font-size: 1.2rem;
    }

    &__logo {
      @include mq {
        display: flex;
        margin-top: 20px;
        img {
          margin-right: 5px;
          width: 50px;
          height: 37px;
        }
      }
      @include mq(medium, max) {
        display: flex;
        margin-top: 20px;
        img {
          margin-right: 5px;
          width: 40px;
          height: 30px;
        }
      }
    }
  }
}

.c-kitacore-style {
  @include mq {
    width: 300px;
    display: block;
    justify-content: space-between;
    @include auto;
    background-color: $color-navy;
    border-radius: 50px;
    padding: 25px;
    display: flex;
    margin-top: 60px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
  }
  @include mq(medium, max) {
    display: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    @include pink-to-orange;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99), -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
    z-index: 1;
  }

  &:hover {
    &::before {
      @include mq {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: left;
        transform-origin: left;
      }
    }

    span:nth-of-type(2) {
      @include mq {
        -webkit-transform: translate(15px, -50%);
        transform: translate(15px, -50%);
      }
    }
  }

  span {
    color: $reverse-color;
    pointer-events: relative;
    z-index: 2;

    &:nth-of-type(1) {
      @include mq {
        @include bold;
        display: block;
        letter-spacing: 2px;
      }
      @include mq(medium, max) {
        @include bold;
        display: block;
        letter-spacing: 3px;
      }
    }

    &:nth-of-type(2) {
      @include mq {
        z-index: 2;
        @include box;
        width: 100px;
        height: 20px;
        background: url("../images/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 50%;
        right: 28px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99),
        -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
      }
      @include mq(medium, max) {
        content: "";
        background: url("../images/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: block;
        width: 100px;
        height: 20px;
        color: $reverse-color;
      }
    }
  }
}

.c-article {
  position: relative;
  padding-bottom: 60px;
  @include mq {
    padding-top: 180px;
  }
  @include mq(medium, max) {
    z-index: 1;
  }

  &__inner {
    @include mq {
      @include auto;
      width: 700px;
      max-width: 100%;
    }
    @include mq(medium, max) {
      // padding-top: 70px;
      @include pc-inner(p);
    }

    p {
      @include mq {
        font-size: 1.6rem;
      }
    }

    img {
      @include mq {
        margin-top: 35px;
        border-radius: 15px;
      }
      @include mq(medium, max) {
        // margin-top: 35px;
        border-radius: 15px;
      }
    }
  }

  &__title {
    @include mq {
      @include auto;
      width: 700px;
      max-width: 100%;
      padding-bottom: 30px;
      border-bottom: 2px solid $color-lv6;
    }
    @include mq(medium, max) {
      @include pc-inner(p);
    }

    &__question {
      @include mq {
        padding-bottom: 30px;
        border-bottom: 2px solid $color-lv6;
      }
      @include mq(medium, max) {
      }
    }
  }

  h1 {
    @include mq {
      font-size: 2.5rem;
      @include bold;
    }
    @include mq(medium, max) {
      font-size: 2.1rem;
      @include bold;
    }
  }
}

.c-section {
  @include mq {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
  }
  @include mq(medium, max) {
    //background-color: #f7f8fa;
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    //position: relative;
    //z-index: 1;
  }
}

.c-flex {
  display: flex;
  justify-content: center;

  //img {
  //  //@include mq {
  //  //  width: 30%;
  //  //  height: auto;
  //  //  object-fit: cover;
  //  //  margin-right: 10px;
  //  //  margin-left: 10px;
  //  //}
  //  //@include mq(medium, max) {
  //  //  width: 100px;
  //  //  height: 100px;
  //  //  object-fit: cover;
  //  //  margin-right: 10px;
  //  //  margin-left: 10px;
  //  //  float: left;
  //  //}
  //}

  &__wrap {
    flex-wrap: wrap;
  }
}

.c-flex {
  @include mq {
    &__pc {
      display: flex;
    }
  }

  @include mq(medium, max) {
    &__sp {
      display: flex;
    }
  }
}

.c-text-align {
  &__left {
    @include mq {
      text-align: left;
    }
  }

  &__right {
    text-align: right;
  }

  &__center {
    @include mq {
      text-align: center !important;
    }
  }

  &__center-sp {
    @include mq(medium, max) {
      text-align: center !important;
    }
  }
}

.c-font {
  @include mq {
    &__12 {
      font-size: 1.2rem !important;
    }

    &__13 {
      font-size: 1.3rem !important;
    }

    &__15 {
      font-size: 1.5rem !important;
    }

    &__16 {
      font-size: 1.6rem !important;
    }

    &__16px {
      font-size: 16px !important;
    }

    &__17 {
      font-size: 1.7rem !important;
    }

    &__18 {
      font-size: 1.8rem !important;
    }

    &__20 {
      font-size: 2rem !important;
    }

    &__22 {
      font-size: 2.2rem !important;
    }

    &__24 {
      font-size: 2.4rem !important;
    }

    &__26 {
      font-size: 2.6rem !important;
    }

    &__28 {
      font-size: 2.8rem !important;
    }

    &__34 {
      font-size: 3.4rem !important;
    }

    &__38 {
      font-size: 3.8rem !important;
    }
    &__bold {
      font-weight: 700 !important;
    }
  }
  @include mq(medium, max) {
    &__sp1 {
      font-size: 1rem !important;
    }
    &__sp12 {
      font-size: 1.2rem !important;
    }
    &__sp13 {
      font-size: 1.3rem !important;
    }
    &__sp16 {
      font-size: 1.6rem !important;
    }
    &__sp16px {
      font-size: 16px !important;
    }
    &__sp18 {
      font-size: 1.8rem !important;
      margin: 0;
    }
    &__sp23 {
      font-size: 2.3rem !important;
    }
    &__sp24 {
      font-size: 2.4rem !important;
    }
    &__sp26 {
      font-size: 2.6rem !important;
    }
    &__sp30 {
      font-size: 3rem !important;
    }
    &__sp34 {
      font-size: 3.4rem !important;
    }
    &__sp-normal {
      font-weight: normal !important;
    }
    &__sp-bold {
      font-weight: 700 !important;
    }
    &__sp-small {
      font-size: small !important;
    }
  }
}

.c-color {
  &__lightslategrey {
    color: lightslategrey;
  }

  &__black {
    color: $default-black !important;
  }

  &__navy {
    color: $color-navy !important;
  }

  &__bk-navy {
    background-color: $color-navy !important;
  }

  &__white {
    color: $reverse-color !important;
  }

  &__border-black {
    border-color: #222222 !important;
  }

  &__gray {
    color: $color-sub !important;
  }

  &__bk-black {
    background-color: #222222 !important;
  }

  &__bg-gray {
    background-color: #e5e5e5 !important;
  }

  &__sitemap {
    color: #3a718f !important;
  }

  &__blue {
    color: #3c89d9 !important;
  }

  &__red {
    color: red !important;
  }

  &__link {
    color: #0000fd !important;
  }

  &__bg-search {
    @include mq {
      background-color: #f7f8fa !important;
    }
    @include mq(medium, max) {
      background-color: #f7f8fa !important;
    }
  }

  &__orange {
    @include bold;
    color: $color-pink; //非対応のブラウザでの文字色を設定
    background: linear-gradient(to right, $color-pink, $color-orange); //背景色にグラデーションを指定
    -webkit-background-clip: text; //テキストでくり抜く
    -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
  }

  &__gray-text {
    @include bold;
    background: gray; //背景色にグラデーションを指定
    -webkit-background-clip: text; //テキストでくり抜く
    -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
  }

  &__pure-orange {
    color: orange !important;
  }

  @include mq(medium, max) {
    &__initial-sp {
      color: initial;
    }
    &__bg-navy-sp {
      background-color: $color-navy !important;
    }
    &__bg-initial-sp {
      background-color: initial !important;
    }
    &__bg-white-sp {
      background-color: #fff !important;
    }
    &__border-white-sp {
      border-color: #fff;
    }
    &__bg-initial-sp {
      background-color: initial;
    }
    &__green-sp {
      color: #6e93a6;
    }
    &__bg-qa-sp {
      background-color: #f7f8fa;
    }
    &__bg-gray-sp {
      background-color: #e5e5e5;
    }
    &__bg-orange-sp {
      background: linear-gradient(to right, $color-pink, $color-orange);
    }
    &__sp-transparent {
      background-color: transparent;
    }
    &__sp-analytics {
      background-color: #f7f8fa;
    }
  }
}

.c-supplement {
  @include mq {
    width: 100%;
  }

  @include mq {
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
  }
  @include mq(medium, max) {
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    text-align: center;
    font-size: 2.2rem;
    color: $color-gray;
    margin-bottom: 20px;
  }

  p {
    color: $color-gray;
    margin-bottom: 20px;
  }
}

.c-white-area {
  @include mq {
    width: 100%;
    background-color: $reverse-color;
  }
  @include mq(medium, max) {
  }
}

.c-search-detail {
  @include mq {
    display: flex;
    padding: 20px 90px;
  }
  @include mq(medium, max) {
    display: flex;
    padding-top: 20px;
  }

  select {
    @include mq {
      position: relative;
      z-index: 2;
      background-color: #cccccc;
      opacity: 0.5;
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 1.8rem;
    }
    @include mq(medium, max) {
      position: relative;
      z-index: 2;
      background-color: #cccccc;
      opacity: 0.5;
      display: block;
      width: 100%;
      padding: 10px;
    }
  }

  select:nth-child(1) {
    @include mq {
      border-radius: 50px 0 0 50px;
      border-right: 2px solid;
    }
    @include mq(medium, max) {
      border-radius: 50px 0 0 50px;
      border-right: 2px solid;
    }
  }

  select:nth-child(2) {
    @include mq {
      border-radius: 0 50px 50px 0;
    }
    @include mq(medium, max) {
      border-radius: 0 50px 50px 0;
    }
  }
}

.c-flex-img-text {
  display: flex;

  @include mq {
    height: 70px;
  }
  @include mq(medium, max) {
    padding-bottom: 10px;
    float: left;
    padding-right: 4px;
  }

  p {
    text-align: center;
    line-height: 40px;
    margin-left: 10px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  &__sp-none {
    @include mq(medium, max) {
      display: none;
    }
  }
}

.c-q-page {
  img {
    @include mq {
      //height: 450px;
      object-fit: contain;
    }
    @include mq(medium, max) {
      //height: 335px;
      height: 100%;
      object-fit: contain;
      //margin-bottom: 20px;
    }
  }

  video {
    @include mq {
      margin-top: 35px;
      width: 100%;
      height: 450px;
      object-fit: cover;
    }
    @include mq(medium, max) {
      width: 100%;
      height: 200px;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }
}

.c-category-search {
  @include mq {
  }
  @include mq(medium, max) {
    // display: none;
  }

  span {
    @include mq {
      display: flex;
    }
  }

  select {
    @include mq {
      position: relative;
      z-index: 2;
      background-color: #cccccc;
      opacity: 0.5;
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 1.8rem;
    }
    @include mq(medium, max) {
      position: relative;
      z-index: 2;
      background-color: #cccccc;
      opacity: 0.5;
      display: block;
      width: 100%;
      padding: 10px;
    }
  }

  select:nth-child(1) {
    @include mq {
      border-radius: 50px 0 0 50px;
      border-right: 2px solid;
      width: 335px;
    }
    @include mq(medium, max) {
      border-radius: 50px 0 0 50px;
      border-right: 2px solid;
    }
  }

  select:nth-child(2) {
    @include mq {
      border-right: 2px solid;
      width: 335px;
    }
    @include mq(medium, max) {
      border-radius: 0 50px 50px 0;
    }
  }

  // select:nth-child(3) {
  //   @include mq {
  //     border-right: 2px solid;
  //     width: 155.5px;
  //   }
  //   @include mq(medium, max) {
  //     border-radius: 0 50px 50px 0;
  //   }
  // }

  // select:nth-child(4) {
  //   @include mq {
  //     width: 155.5px;
  //   }
  //   @include mq(medium, max) {
  //     border-radius: 0 50px 50px 0;
  //   }
  // }
}

.c-image-circle {
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  @include mq(medium, max) {
    width: 100px;
    height: 100px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
    float: left;
  }
}

.c-image {
  &__object-fit-cover {
    object-fit: cover;
  }
}

.c-position {
  &__absolute {
    @include mq {
      position: absolute;
    }
  }

  &__relative {
    @include mq {
      position: relative !important;
    }
  }

  &__relative-sp {
    @include mq(medium, max) {
      position: relative !important;
    }
  }

  &__absolute-sp {
    @include mq(medium, max) {
      position: absolute !important;
    }
  }

  &__relative-sp-mypage {
    @include mq(medium, max) {
      position: relative;
      left: 20px;
      top: 0px;
    }
  }

  &__static-sp {
    @include mq(medium, max) {
      position: static !important;
    }
  }

  &__fixed-sp {
    @include mq(medium, max) {
      position: fixed !important;
    }
  }
}

.c-justify {
  &__left {
    justify-content: left !important;
  }
}

.c-line-height {
  @include mq {
    &__20 {
      line-height: 2.0 !important;
    }
    &__25 {
      line-height: 2.5;
    }
    &__30px {
      line-height: 30px;
    }

    &__40px {
      line-height: 40px;
    }

    &__50px {
      line-height: 50px;
    }

    &__80px {
      line-height: 40px;
    }
  }
  @include mq(medium, max) {
    &__sp28px {
      line-height: 28px !important;
    }
    &__sp40px {
      line-height: 40px !important;
    }
  }
}

.c-top {
  @include mq {
    &__11px {
      top: 11px !important;
    }
    &__15px {
      top: 15px !important;
    }
    &__-36px {
      top: -36px !important;
    }
  }
  @include mq(medium, max) {
    &__sp-5px {
      top: -5px !important;
    }
    &__sp10px {
      top: 10px !important;
    }
    &__sp15px {
      top: 15px !important;
    }
    &__sp-12px {
      top: -12px !important;
    }
    &__sp20px {
      top: 20px !important;
    }
    &__sp-20px {
      top: -20px !important;
    }
    &__sp22px {
      top: 22px !important;
    }
    &__sp25px {
      top: 25px !important;
    }
    &__sp50px {
      top: 50px !important;
    }
    &__sp60px {
      top: 60px !important;
    }
    &__sp-80px {
      top: -80px !important;
    }
    &__sp-100 {
      top: 100% !important;
    }
    &__sp220px {
      top: 220px !important;
    }
    &__sp-36px {
      top: -36px !important;
    }
    &__sp630px {
      top: 630px !important;
    }
    &__sp700px {
      top: 700px !important;
    }
  }
}

.c-right {
  @include mq {
    &__156px {
      right: 156px !important;
    }
    &__312px {
      right: 312px !important;
    }
  }
  @include mq(medium, max) {
    &__sp0 {
      right: 0 !important;
    }
    &__sp12px {
      right: 12px !important;
    }
    &__sp14px {
      right: 14px !important;
    }
    &__sp-17px {
      right: -17px !important;
    }
    &__sp20px {
      right: 20px !important;
    }
    &__sp35px {
      right: 35px !important;
    }
    &__sp80px {
      right: 80px !important;
    }
  }
}

.c-position {
  &__absolute {
    position: absolute;
  }

  @include mq {
    &__top10px {
      top: 10px;
    }

    &__right0 {
      right: 0;
    }

    &__right2 {
      right: 2%;
    }

    &__top60px {
      top: 60px !important;
    }

    &__top120px {
      top: 120px;
    }

    &__bottom0 {
      bottom: 0;
    }
  }
  @include mq(medium, max) {
    &__sp-top60px {
      top: 60px;
    }
  }
}

.c-left {
  @include mq {
    &__15px {
      left: 15px !important;
    }
  }
  @include mq(medium, max) {
    &__sp5px {
      left: 5px !important;
    }
    &__sp15px {
      left: 15px !important;
    }
    &__sp-98px {
      left: -98px !important;
    }
    &__sp-110px {
      left: -110px !important;
    }
    &__sp180px {
      left: 180px !important;
    }
  }
}

.c-z-index {
  @include mq {
    &__1 {
      z-index: 1 !important;
    }
    &__100 {
      z-index: 100 !important;
    }
  }
  @include mq(medium, max) {
    &__sp1 {
      z-index: 1 !important;
    }
    &__sp5 {
      z-index: 5 !important;
    }
  }
}

.c-object-fit {
  @include mq {
    &__cover {
      object-fit: cover;
    }
  }
  @include mq(medium, max) {
    &__cover-sp {
      object-fit: cover;
    }
  }
}

.c-help {
  @include mq {
    background-color: #e5e5e5;
    padding: 21px 50px 21px 30px;
    font-size: 1.4rem;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
    border-bottom: 1px solid #c5c5c5;
  }
  @include mq(medium, max) {
    position: relative;
    z-index: 1;
    padding: 20px 50px 5px 15px;
    line-height: 28px;
    font-weight: 700;
    cursor: pointer;
    background-color: #e5e5e5;
    border-bottom: 1px solid #c5c5c5;
  }

  &::after {
    @include mq {
      content: "+";
      font-size: 2em;
      float: right;
      transform: translate(100%, -5%);
      color: #8a8a8a;
    }
    @include mq(medium, max) {
      content: "+";
      font-size: 2em;
      color: #8a8a8a;
      position: absolute;
      top: 48%;
      right: 18px;
      margin-top: -12px;
    }
  }
}

.c-float {
  &__left {
    @include mq {
      float: left !important;
    }
    @include mq(medium, max) {
      float: left !important;
    }
  }

  &__none {
    @include mq {
      float: none !important;
    }
    @include mq(medium, max) {
      float: none !important;
    }
  }
}

.c-accordion {
  @include mq {
    border: 1px solid #c5c5c5;
    margin-bottom: 20px;
  }
  @include mq(medium, max) {
    border: 1px solid #c5c5c5;
    margin-bottom: 20px;
  }
}

.c-list-style {
  &__disc {
    list-style-type: disc;
    @include mq(medium, max) {
      list-style-type: disc;
    }
  }

  &__decimal {
    list-style-type: decimal;
  }

  @include mq(medium, max) {
    &__none-sp {
      list-style: none;
    }
  }
}

.c-table-layout {
  @include mq {
    &__fixed {
      table-layout: fixed;
    }
    &__break-word {
      td {
        word-break: break-all;
      }
    }
  }
  @include mq(medium, max) {
    &__break-word-sp {
      td {
        word-break: break-all;
      }
    }
  }
}

.c-word-break {
  &__break-all {
    word-break: break-all;
  }
}

.c-text-indent {
  &__sp-1em {
    text-indent: -1em;
  }

  @include mq(medium, max) {
    &__sp0 {
      text-indent: 0;
    }
  }
}

.list_parentheses {
  @include mq {
    // padding:0 0 0 2em;
    // margin:0;
    margin-left: 3.5em; /* サイトに合せて調整 */
  }
  @include mq(medium, max) {
    padding-left: 3rem !important;
    font-size: 1.8rem;
  }

  li {
    @include mq {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
    }
    @include mq(medium, max) {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      padding-left: 1rem !important;
      font-size: 1.8rem;
      color: initial !important;
      border: none !important;
    }

    &::before {
      // display: marker;
      // content: "(" counter(cnt) ") ";
      content: "(" counter(cnt) ")";
      display: inline-block;
      margin-left: -2em; /* サイトに合せて調整 */
      width: 2em; /* サイトに合せて調整 */
    }
  }
}

.accordion {
  ul {
    @include mq {
      // width:100%;
      width: 700px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
      padding: 0;
    }
    @include mq(medium, max) {
      width: 330px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
    }
  }

  li {
    position: relative;
    border-bottom: 1px solid #c5c5c5;
    list-style: none;
  }

  li:first-child {
    border: 1px solid #c5c5c5;
  }
}

.toggle {
  @include mq {
    border-bottom: 1px solid #c5c5c5;
    background-color: #e5e5e5;
  }
  @include mq(medium, max) {
    border-bottom: 1px solid #c5c5c5;
    background-color: #e5e5e5;
    position: relative;
    z-index: 2;
    display: flex;
  }
}

.accordion > ul > li > a {
  @include mq {
    display: block;
    text-decoration: none;
    cursor: pointer;
    // padding: 1.5em 1em;
    padding: 21px 50px 21px 30px;
    position: relative;
  }
  @include mq(medium, max) {
    // display: block;
    text-decoration: none;
    cursor: pointer;
    padding: 20px 50px 5px 15px;
    position: relative;
  }
}

.accordion > ul > li > a h5:hover {
  color: #111;
  text-decoration: none;
}

.accordion > ul > li > p {
  @include mq {
    display: none;
    text-align: left;
    // padding:0 .8em 1.4em 1em;
    padding: 40px 20px 40px 75px;
    // color:#555;
    line-height: 1.8;
  }
  @include mq(medium, max) {
    display: none;
    text-align: left;
    padding: 28px 20px 31px 60px;
    line-height: 1.8;
  }
}

.accordion li .accordion_icon,
.accordion li .accordion_icon span {
  display: inline-block;
  transition: all 0.3s;
  box-sizing: border-box;
}

.accordion li .accordion_icon {
  position: absolute;
  width: 20px;
  height: 18px;
  top: 32px;
  right: 28px;
}

.accordion li .accordion_icon span {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #8a8a8a;
}

.accordion li .accordion_icon span:nth-of-type(1) {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.accordion li .accordion_icon span:nth-of-type(2) {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.accordion li .accordion_icon.active span:nth-of-type(1) {
  display: none;
}

.accordion li .accordion_icon.active span:nth-of-type(2) {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.c-letter-spacing {
  &__sp0 {
    @include mq(medium, max) {
      letter-spacing: 0 !important;
    }
  }
}

.c-backface-visibility {
  &__hidden-sp {
    @include mq(medium, max) {
      backface-visibility: hidden;
    }
  }
}

.c-background-color {
  &__white {
    background-color: white !important;
  }

  &__pure-orange {
    background-color: orange !important;
  }

  &__orange {
    background-color: $color-orange !important;
  }

  &__grad-orange {
    background: linear-gradient(to right, #f61d6a, #ff6b01) !important;
  }

  &__gray {
    background-color: gray !important;
  }

  &__gray-white {
    background-color: #fafafa !important;
  }

  &__lightcoral {
    background-color: lightcoral !important;;
  }

  &__royalblue {
    background-color: royalblue !important;;
  }

  &__red {
    background-color: red !important;;
  }

  &__lightgray {
    background-color: $color-lightgray !important;;
  }

  &__green {
    background-color: green !important;
  }

  &__black {
    background-color: black !important;
  }
}

.c-text-stroke {
  @include mq {
    &__02px-white {
      -webkit-text-stroke: 0.2px #FFF;
    }
  }
  @include mq(medium, max) {
    &__sp02px-white {
      -webkit-text-stroke: 0.2px #FFF;
    }
  }
}

.c-cursor {
  &__pointer {
    cursor: pointer;
  }
}

.c-hover {
  &__opacity06:hover {
    opacity: 0.6 !important;
  }
}