.c-user-name-area {
  @include mq {
    @include pc-inner-width;
  }
  @include mq(medium, max) {
    width: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  &__text {
    @include mq {
      @include bold;
      font-size: 1.8rem;
      margin-bottom: 20px;
    }
    @include mq(medium, max) {
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 1.8rem;
    }
  }

  &__link {
    @include mq {
      text-decoration: underline;
      margin-right: 20px;
    }
    @include mq(medium, max) {
      text-decoration: underline;
      font-size: 1.8rem;
      margin-right: 15px;
    }
  }
}

.c-tab-main {
  @include mq {
    @include pc-inner-width;
    display: flex;
    background-color: $color-disabled;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @include mq(medium, max) {
    width: 100%;
    display: flex;
    background-color: $color-disabled;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }

  &__text {
    @include mq {
      @include bold;
      font-size: 1.6rem;
      padding-top: 10px;
    }
    @include mq(medium, max) {
      display: none;
    }
  }

  button {
    @include mq {
      width: calc(100% / 3);
      padding: 20px 0;
      border-right: 2px solid #cccccc;
    }
    @include mq(medium, max) {
      width: calc(100% / 3);
      padding: 20px 0;
      border-right: 1px solid #cccccc;
    }
  }

  button:last-child {
    @include mq {
      border-right: none;
    }
    @include mq(medium, max) {
      border-right: none;
    }
  }

  button:hover {
    background: linear-gradient(to right, #f61d6a, #ff6b01) !important;

    .c-tab-main__text {
      @include mq {
        @include bold;
        font-size: 1.6rem;
        padding-top: 10px;
        color: $reverse-color;
      }
      @include mq(medium, max) {
      }
    }

    //.c-tab-main__my-trainer {
    //  @include mq {
    //    @include box;
    //    background: url("../images/my-trainer_icon_white.svg");
    //    width: 40px;
    //    height: 40px;
    //    margin-right: 15px;
    //    background-repeat: no-repeat;
    //  }
    //  @include mq(medium, max) {
    //  }
    //}
    //.c-tab-main__favorite {
    //  @include mq {
    //    @include box;
    //    background: url("../images/like_icon_white.svg");
    //    background-repeat: no-repeat;
    //    width: 40px;
    //    height: 40px;
    //    margin-right: 15px;
    //  }
    //  @include mq(medium, max) {
    //  }
    //}
    //.c-tab-main__myqa {
    //  @include mq {
    //    @include box;
    //    background: url("../images/Q&A_icon_white.svg");
    //    background-repeat: no-repeat;
    //    width: 40px;
    //    height: 40px;
    //    margin-right: 15px;
    //    color: $reverse-color;
    //  }
    //  @include mq(medium, max) {
    //  }
    //}
  }

  button.active {
    background: linear-gradient(to right, #f61d6a, #ff6b01) !important;
    color: $reverse-color;

    .c-tab-main__text {
      @include mq {
        @include bold;
        font-size: 1.6rem;
        padding-top: 10px;
        color: $reverse-color;
      }
      @include mq(medium, max) {
        display: none;
        @include bold;
        font-size: 1.6rem;
        padding-top: 10px;
        color: $reverse-color;
      }
    }

    // .c-tab-main__my-trainer {
    //   @include mq {
    //     @include box;
    //     background: url("../images/my-trainer_icon_white.svg");
    //     width: 40px;
    //     height: 40px;
    //     margin-right: 15px;
    //   }
    //   @include mq(medium, max) {
    //     @include box;
    //     background: url("../images/my-trainer_icon_white.svg");
    //     width: 40px;
    //     height: 40px;
    //     margin-right: 15px;
    //   }
    // }
  }

  &__my-trainer {
    @include mq {
      @include box;
      background: url("../images/my-trainer_icon_black.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      background: url("../images/my-trainer_icon_black.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__my-trainer.active {
    @include mq {
      @include box;
      background: url("../images/my-trainer_icon_white.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/my-trainer_icon_white.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__favorite {
    @include mq {
      @include box;
      background: url("../images/like_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/like_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__favorite.active {
    @include mq {
      @include box;
      background: url("../images/like_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/like_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__myqa {
    @include mq {
      @include box;
      background: url("../images/Q&A_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/Q&A_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__myqa.active {
    @include mq {
      @include box;
      background: url("../images/Q&A_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/Q&A_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__analytics {
    @include mq {
      @include box;
      background: url("../images/popular_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/popular_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__analytics.active {
    @include mq {
      @include box;
      background: url("../images/popular_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/popular_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__answer {
    @include mq {
      @include box;
      background: url("../images/answer article_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/answer article_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__answer.active {
    @include mq {
      @include box;
      background: url("../images/answer article_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/answer article_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__article {
    @include mq {
      @include box;
      background: url("../images/blog_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/blog_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__article.active {
    @include mq {
      @include box;
      background: url("../images/blog_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/blog_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__popular {
    @include mq {
      @include box;
      background: url("../images/popular_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/popular_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__popular.active {
    @include mq {
      @include box;
      background: url("../images/popular_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/popular_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }

  &__new {
    @include mq {
      @include box;
      background: url("../images/new-arrival_icon_black.svg");
      width: 40px;
      height: 40px;
      margin-right: 15px;
      background-repeat: no-repeat;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/new-arrival_icon_black.svg");
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }

  &__new.active {
    @include mq {
      @include box;
      background: url("../images/new-arrival_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    @include mq(medium, max) {
      @include box;
      background: url("../images/new-arrival_icon_white.svg");
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
    }
  }
}

.tabcontent {
  position: relative;
}

.tablinks {
  display: flex;
  justify-content: center;
}

.c-tab-sub {
  @include mq {
    @include pc-inner-width;
    display: flex;
    justify-content: center;
  }
  @include mq(medium, max) {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 10px;
  }

  button {
    p {
      @include mq {
        @include bold;
        font-size: 2.2rem;
        margin-right: 20px;
        margin-left: 20px;
        color: $color-lv7;
      }
      @include mq(medium, max) {
        @include bold;
        font-size: 2rem;
        margin-right: 20px;
        margin-left: 20px;
        color: $color-lv7;
      }
    }
  }

  button.active {
    p {
      @include mq {
        @include bold;
        font-size: 2.2rem;
        text-decoration: underline;
        margin-right: 20px;
        margin-left: 20px;
        color: initial;
      }
      @include mq(medium, max) {
        @include bold;
        font-size: 2rem;
        text-decoration: underline;
        margin-right: 20px;
        margin-left: 20px;
        color: initial;
      }
    }
  }
}

.c-register-info {
  img {
    @include mq {
    }
    @include mq(medium, max) {
      width: 185px;
      height: 125px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.c-mypage-analytics-table {
  @include mq {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
  }
  @include mq(medium, max) {
  }

  tr {
    border-bottom: 2px solid $color-lv6;
  }

  td {
    padding: 10px;
    border-bottom: 2px solid $color-lv6;
  }

  td:nth-of-type(odd) {
    text-align: left;
  }

  td:nth-of-type(even) {
    @include bold;
    font-size: 1.8rem;
    text-align: right;
  }
}

.c-calendar {
  @include mq {
    font-weight: 700;
    color: #ffffff;
    border: 1px solid;
    background-color: $color-navy;
    width: 180px;
    height: 40px;
    line-height: 38px;
    border-radius: 20px;
  }
  @include mq(medium, max) {
    font-weight: 700;
    color: #ffffff;
    border: 1px solid;
    background-color: $color-navy;
    width: 160px;
    height: 40px;
    line-height: 38px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
}
