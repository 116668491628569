#footer {
  @include mq {
    background: $color-black;
    margin-top: 60px;
  }
  @include mq(medium, max) {
    background: $color-black;
    margin-top: 60px;
  }
}

.l-footer-lv1 {
  background-color: $color-navy;
  @include mq {
    margin-top: 75px;
  }
  @include mq(medium, max) {
    margin-top: 60px;
  }

  &__inner {
    @include mq {
      padding-bottom: 45px;
    }
    @include mq(medium, max) {
      padding-bottom: 45px;
    }
  }

  &__linkto {
    @include mq {
      background-color: $color-lv12;
      width: 100%;
      height: 75px;
      display: block;
      position: relative;
      transition: 0.5s;
      cursor: pointer;
    }
    @include mq(medium, max) {
      background-color: $color-lv12;
      width: 100%;
      height: 75px;
      display: block;
      position: relative;
    }

    &:hover {
      @include mq {
        width: 100%;
        height: 75px;
        display: block;
        @include pink-to-orange;
      }
    }

    &__item {
      @include mq {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $reverse-color;
        font-size: 3rem;
      }
      @include mq(medium, max) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $reverse-color;
        font-size: 3rem;
      }
    }
  }

  &__group-lv1 {
    @include mq {
      @include pc-inner-width;
    }
    @include mq(medium, max) {
      width: 100%;
    }

    &__inner {
      @include mq {
        display: flex;
        justify-content: space-between;
        padding-top: 45px;
      }
      @include mq(medium, max) {
        display: flex;
        justify-content: space-between;
        @include sp-inner(p);
        padding-top: 35px;
        padding-bottom: 20px;
      }
    }

    &__logo {
      @include mq {
        @include box;
        width: 200px;
        height: 60px;
        background: url("../images/apo_white.svg") no-repeat;
      }
      @include mq(medium, max) {
        @include box;
        width: 160px;
        height: 45px;
        background: url("../images/apo_white.svg") no-repeat;
      }
    }

    &__list {
      @include mq {
        display: flex;
      }
      @include mq(medium, max) {
        display: flex;
        width: calc(50% - 25px);
        top: 8px;
        position: relative;
      }

      &__linkto {
        position: relative;
        @include mq {
          @include box;
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          transition: 0.5s;
        }
        @include mq(medium, max) {
          @include box;
          width: 30px;
          height: 30px;
          background-color: #ffffff;
          border-radius: 50%;
        }

        &:hover {
          @include pink-to-orange;

          span.l-footer-lv1__group-lv1__list__linkto__fb {
            background: url("../images/facebook_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          span.l-footer-lv1__group-lv1__list__linkto__in {
            background: url("../images/instagram_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          span.l-footer-lv1__group-lv1__list__linkto__tw {
            background: url("../images/twitter_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          span.l-footer-lv1__group-lv1__list__linkto__line {
            background: url("../images/line_icon.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &:not(:last-child) {
          @include mq {
            margin-right: 15px;
          }
          @include mq(medium, max) {
            margin-right: 5px;
          }
        }

        span {
          @include mq {
            @include box;
            width: 18px;
            height: 18px;
            position: absolute;
            @include centering(xy);
          }
          @include mq(medium, max) {
            @include box;
            width: 15px;
            height: 15px;
            position: absolute;
            @include centering(xy);
          }
        }

        &__fb {
          background: url("../images/facebook_icon_navy.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        &__in {
          background: url("../images/instagram_icon_navy.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        &__tw {
          background: url("../images/twitter_icon_navy.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        &__line {
          background: url("../images/line_icon_navy.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  &__copy {
    color: $reverse-color;
    @include mq {
      margin-top: 30px;
      margin-left: auto;
      text-align: right;
      display: table;
      letter-spacing: 1px;
      position: relative;
    }
    @include mq(medium, max) {
      display: table;
      margin-left: auto;
      margin-right: 30px;
      letter-spacing: 1px;
      position: relative;
    }
  }
}

.l-footer-list {
  @include mq {
    position: absolute;
    margin-top: 10px;
    // letter-spacing: 3px;
  }
  @include mq(medium, max) {
  }

  ol {
    @include mq {
      display: flex;
    }
    @include mq(medium, max) {
      padding-left: 15px;
    }
  }

  li {
    @include mq {
      display: inline;
      list-style-type: none;
    }
    @include mq(medium, max) {
    }

    &::after {
      @include mq {
        content: "/";
        color: #fff;
      }
      @include mq(medium, max) {
      }
    }

    &:last-child:after {
      @include mq {
        content: none;
      }
      @include mq(medium, max) {
      }
    }
  }

  &__inner01 {
    @include mq {
      color: #fff;
    }
    @include mq(medium, max) {
      color: #fff;
    }
  }

  &__inner02 {
    @include mq {
      color: #fff;
    }
    @include mq(medium, max) {
      color: #fff;
    }
  }

  &__inner03 {
    @include mq {
      color: #fff;
    }
    @include mq(medium, max) {
      color: #fff;
    }
  }

  &__inner04 {
    @include mq {
      color: #fff;
    }
    @include mq(medium, max) {
      color: #fff;
    }
  }
}

.l-footer-box {
  @include mq {
    margin-left: 7%;
    margin-right: 7%;
  }
  @include mq(medium, max) {
  }
}

.l-main-footer {
  @include mq {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  @include mq(medium, max) {
  }
}