.p-news-03 {
  &__section-lv1 {
    position: relative;
    padding-bottom: 60px;
    @include mq {
      padding-top: 180px;
    }
    @include mq(medium, max) {
      z-index: 1;
    }

    &__inner {
      @include mq {
        @include auto;
        width: 700px;
      }
      @include mq(medium, max) {
        padding-top: 70px;
        @include pc-inner(p);
      }
    }

    &__heading {
      @include mq {
        display: flex;
        flex-wrap: wrap;
      }
      @include mq(medium, max) {
        display: flex;
        flex-wrap: wrap;
      }

      &__tag {
        @include mq {
          padding: 5px 20px;
          //   display: inline-block;
          border: 2px solid $default-black;
          border-radius: 25px;
          margin-right: 20px;
          //   display: flex;
          display: -webkit-box;
          width: max-content;
          margin-bottom: 10px;
        }
        @include mq(medium, max) {
          //   padding: 0 10px;
          //   display: inline-block;
          padding: 5px 10px;
          border: 1px solid $default-black;
          border-radius: 25px;
          margin-right: 10px;
          display: -webkit-box;
          width: fit-content;
          margin-bottom: 8px;
        }

        &__icon {
          @include mq {
            @include box;
            width: 15px;
            height: 15px;
            background: url(../images/tag_icon_black.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-right: 10px;
            position: relative;
            top: 6px;
          }
          @include mq(medium, max) {
            @include box;
            width: 15px;
            height: 15px;
            background: url(../images/tag_icon_black.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-right: 10px;
            position: relative;
            top: 4px;
          }
        }

        &__text {
          font-size: 1rem;
          padding-top: 2px;
        }
      }

      &__time {
        color: $color-lv7;
        @include middle;
        @include mq {
          padding: 5px 0;
          display: block;
        }
      }
    }

    h1 {
      @include mq {
        margin-top: 40px;
        font-size: 2.5rem;
        @include bold;
        padding-bottom: 35px;
        border-bottom: 2px solid $color-lv6;
      }
      @include mq(medium, max) {
        margin-top: 30px;
        font-size: 2.1rem;
        @include bold;
        padding-bottom: 30px;
        border-bottom: 2px solid $color-lv6;
      }
    }

    h2 {
      @include mq {
        font-size: 2.2rem;
        @include bold;
        margin-top: 75px;
        border-bottom: 2px solid $color-lv6;
        padding-bottom: 25px;
        position: relative;
        padding-left: 20px;
      }
      @include mq(medium, max) {
        font-size: 1.9rem;
        @include bold;
        margin-top: 35px;
        border-bottom: 2px solid $color-lv6;
        padding-bottom: 30px;
        position: relative;
        padding-left: 15px;
      }

      &::before {
        content: "●";
        display: block;
        color: $color-lv4;
        font-size: 1rem;
        position: absolute;
        top: 10px;
        left: 0px;
      }
    }

    h3 {
      @include mq {
        font-size: 2.2rem;
        @include bold;
        margin-top: 75px;
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        padding-left: 45px;
        background-color: $color-lv6;
      }
      @include mq(medium, max) {
        font-size: 1.9rem;
        @include bold;
        margin-top: 40px;
        padding-top: 30px;
        padding-bottom: 25px;
        position: relative;
        padding-left: 40px;
        background-color: $color-lv6;
      }

      &::before {
        content: "●";
        display: block;
        color: $color-lv4;
        font-size: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
      }
    }

    h4 {
      @include mq {
        @include bold;
        margin-top: 35px;
        font-size: 1.6rem;
      }
      @include mq(medium, max) {
        @include bold;
        margin-top: 35px;
        font-size: 1.6rem;
      }

      &::before {
        content: "■";
      }
    }

    p {
      @include mq {
        font-size: 1.6rem;
      }
    }

    img {
      @include mq {
        margin-top: 35px;
        border-radius: 15px;
      }
      @include mq(medium, max) {
        margin-top: 35px;
        border-radius: 15px;
      }
    }
  }
}

.p-padding-top {
  @include mq {
    padding-top: 140px;
  }
  @include mq(medium, max) {
    padding-top: 60px;
  }
}
