.c-question-area {
  @include mq {
    @include pc-inner-width;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 60px;
  }
  @include mq(medium, max) {
    background-color: #f7f8fa;
  }

  &__box {
    @include mq {
      width: calc(calc(100% - 60px) / 3);
      height: 520px;
      margin-bottom: 20px;
      border-radius: 30px;
      border: 3px solid $reverse-color;
      position: relative;
      overflow: hidden;
      margin-right: 10px;
      margin-left: 10px;
    }
    @include mq(medium, max) {
      padding-top: 1px;
    }
  }

  &__list {
    @include mq {
      width: 100%;
      height: auto;
      border-radius: 30px;
      position: relative;
      margin: 70px auto;
    }
    @include mq(medium, max) {
    }
  }

  &__heading-area {
    @include mq {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include mq(medium, max) {
      display: flex;
    }

    &__icon {
      @include mq {
        @include box;
        width: 40px;
        height: 40px;
        max-width: 100%;
        transform: translateY(-20%);
        margin-right: 5px;
      }
      @include mq(medium, max) {
        width: 40px;
        height: 40px;
        transform: translateY(-20%);
        margin-right: 5px;
      }
    }

    &__text {
      @include mq {
        @include bold;
        color: $color-pink; //非対応のブラウザでの文字色を設定
        background: linear-gradient(to right, $color-pink, $color-orange); //背景色にグラデーションを指定
        -webkit-background-clip: text; //テキストでくり抜く
        -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
        font-size: 1.5rem;
      }
      @include mq(medium, max) {
        @include bold;
        color: $color-pink; //非対応のブラウザでの文字色を設定
        background: linear-gradient(to right, $color-pink, $color-orange); //背景色にグラデーションを指定
        -webkit-background-clip: text; //テキストでくり抜く
        -webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
        font-size: 1.5rem;
      }
    }

    &__border {
      @include mq {
        display: block;
        content: "";
        width: 40px;
        height: 2px;
        background-color: $color-pink;
        margin-top: 12px;
        margin-left: 10px;
      }
      @include mq(medium, max) {
        display: block;
        content: "";
        width: 25px;
        height: 2px;
        background-color: $color-pink;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    &__favorite-area {
      @include mq {
        display: flex;
        content: "";
        width: 180px;
        height: 40px;
        border-radius: 30px;
        position: absolute;
        right: 2%;
        background-color: $color-navy;
        justify-content: center;
      }
      @include mq(medium, max) {
        display: flex;
        content: "";
        width: 180px;
        height: 40px;
        border-radius: 30px;
        position: absolute;
        top: 165px;
        background-color: $color-navy;
        justify-content: center;
      }

      img {
        @include mq {
          width: 13px;
          height: auto;
          margin-right: 5px;
          transform: translateY(28%);
        }
        @include mq(medium, max) {
          // display: none;
          width: 13px;
          height: auto;
          margin-right: 5px;
          transform: translateY(18%);
        }
      }

      p {
        @include mq {
          @include bold;
          color: $reverse-color;
        }
        @include mq(medium, max) {
          // display: none;
          @include bold;
          color: $reverse-color;
        }
      }

      &.active {
        @include mq {
          display: flex;
          content: "";
          width: 180px;
          height: 40px;
          border-radius: 30px;
          position: absolute;
          right: 2%;
          color: $color-pink;
          background: linear-gradient(to right, $color-pink, $color-orange);
          justify-content: center;
        }
        @include mq(medium, max) {
          display: flex;
          content: "";
          width: 180px;
          height: 40px;
          border-radius: 30px;
          position: absolute;
          right: 2%;
          color: $color-pink;
          background: linear-gradient(to right, $color-pink, $color-orange);
          justify-content: center;
        }

        img {
          @include mq {
            width: 13px;
            height: auto;
            margin-right: 5px;
            transform: translateY(28%);
          }
          @include mq(medium, max) {
            // display: none;
          }
        }

        p {
          @include mq {
            @include bold;
            color: $reverse-color;
          }
          @include mq(medium, max) {
            // display: none;
          }
        }
      }

      &__reverse {
        @include mq {
          display: flex;
          content: "";
          width: 180px;
          height: 40px;
          border-radius: 30px;
          position: absolute;
          right: 2%;
          color: $color-pink;
          background: linear-gradient(to right, $color-pink, $color-orange);
          justify-content: center;
        }
        @include mq(medium, max) {
          display: flex;
          content: "";
          width: 180px;
          height: 40px;
          border-radius: 30px;
          position: absolute;
          top: 165px;
          color: $color-pink;
          background: linear-gradient(to right, $color-pink, $color-orange);
          justify-content: center;
        }

        img {
          @include mq {
            width: 13px;
            height: auto;
            margin-right: 5px;
            transform: translateY(28%);
          }
          @include mq(medium, max) {
            // display: none;
            width: 13px;
            height: auto;
            margin-right: 5px;
            transform: translateY(18%);
          }
        }

        p {
          @include mq {
            @include bold;
            color: $reverse-color;
          }
          @include mq(medium, max) {
            // display: none;
            color: $reverse-color;
            @include bold;
          }
        }
      }
    }
  }

  &__title {
    @include mq {
      @include bold;
      font-size: 1.5rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include mq(medium, max) {
      font-weight: 700;
      font-size: 1.6rem;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: auto;
      margin-left: auto;
      width: 330px;
    }
  }

  // &__content {
  //   @include mq(medium, max) {
  //     margin-right: auto;
  //     margin-left: auto;
  //     width: 330px;
  //   }
  // }

  &__title-big {
    @include mq {
      @include bold;
      font-size: 2rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include mq(medium, max) {
      margin-right: auto;
      margin-left: auto;
      width: 330px;
    }
  }

  &__sub {
    @include mq {
      @include bold;
      font-size: 1.2rem;
      color: $color-sub;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include mq(medium, max) {
      @include bold;
      width: 330px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 10px;
    }
  }

  &__answer-area {
    @include mq {
      display: flex;
      //margin-top: 20px;
      //margin-bottom: 20px;
    }
    @include mq(medium, max) {
      display: flex;
      padding-top: 15px;
      // padding-bottom: 5px;
    }

    &__icon {
      @include mq {
        @include box;
        width: 30px;
        height: 30px;
        transform: translateY(54%);
        margin-right: 5px;
      }
      @include mq(medium, max) {
        width: 30px;
        height: 30px;
        transform: translateY(-10%);
        margin-right: 5px;
      }
    }

    &__text {
      @include mq {
        @include bold;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @include mq(medium, max) {
      }
    }
  }

  &__answer {
    @include mq {
      @include bold;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include mq(medium, max) {
    }
  }

  &__text-area {
    @include mq {
      padding: 20px;
      height: 450px;
    }
    @include mq(medium, max) {
      margin-top: 30px;
      border-bottom: 2px solid #e6e6e6;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 25px;
      width: 330px;
    }
  }

  &__favorite {
    @include mq {
      @include box;
      position: absolute;
      width: 100%;
      height: 70px;
      background-color: $color-navy; //非対応のブラウザでの文字色を設定
      bottom: 0;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 0;
    }
    @include mq(medium, max) {
      display: none;
    }

    p {
      @include mq {
        @include bold;
        color: $reverse-color;
        transform: translateY(10px);
      }
      @include mq(medium, max) {
      }
    }

    &__icon-area {
      @include mq {
        width: 45px;
        height: 45px;
        background-color: #ffffff;
        border-radius: 50%;
        margin-right: 10px;
      }
      @include mq(medium, max) {
      }
    }

    img {
      width: 30px;
      height: 30px;
      transform: translateY(25%) translateX(25%);
    }

    &__reverse {
      @include mq {
        @include box;
        position: absolute;
        width: 100%;
        height: 70px;
        color: $color-pink; //非対応のブラウザでの文字色を設定
        background: linear-gradient(to right, $color-pink, $color-orange); //背景色にグラデーションを指定
        bottom: 0;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
      }
      @include mq(medium, max) {
      }

      p {
        @include mq {
          @include bold;
          color: $reverse-color;
          transform: translateY(10px);
        }
        @include mq(medium, max) {
        }
      }

      &__icon-area {
        @include mq {
          width: 45px;
          height: 45px;
          background-color: #ffffff;
          border-radius: 50%;
          margin-right: 10px;
        }
        @include mq(medium, max) {
        }
      }

      img {
        width: 30px;
        height: 30px;
        transform: translateY(25%) translateX(25%);
      }
    }
  }

  &__edit {
    position: fixed;
    width: 100%;
    background-color: $color-navy;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
    z-index: 10;
    display: flex;
    justify-content: center;

    @include mq {
      height: 70px;
    }
    @include mq(medium, max) {
    }

    p {
      color: $reverse-color;
      @include bold;
      text-align: center;
      line-height: 70px;
    }

    span {
      display: block;
      width: 45px;
      height: 45px;
      background-color: #ffffff;
      border-radius: 50%;
      margin-top: 12.5px;
      margin-bottom: 12.5px;
      margin-right: 10px;
    }

    img {
      width: 35px;
      height: 35px;
      transform: translateX(15%) translateY(16%);
    }
  }

  &__answer {
    @include mq(medium, max) {
      width: 330px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 30px;
      border-bottom: 2px solid #e6e6e6;
      padding-bottom: 25px;
    }
  }
}

.c-question-preview {
  @include mq {
    padding: 50px;
    background-color: $reverse-color;
    border-radius: 30px;
    border: 3px solid $reverse-color;
  }
  @include mq(medium, max) {
    padding-top: 1px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 25px;
    width: 300px;
    margin-bottom: 50px;
  }

  h1 {
    @include mq {
      font-size: 2.5rem;
      @include bold;
      padding-bottom: 20px;
    }
    @include mq(medium, max) {
      // margin-top: 30px;
      // font-size: 2.1rem;
      font-size: 1.8rem;
      @include bold;
      // padding-bottom: 30px;
    }
  }

  div {
    margin-top: 20px;
  }
}

.c-question-ranking {
  @include mq(medium, max) {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
  }
}

.c-mikaiketu {
  @include mq(medium, max) {
    display: flex;
    margin-top: 10px;
  }

  &__box {
    @include mq(medium, max) {
      border: 1px solid #222;
      background-color: #222;
      width: auto;
      margin-right: 5px;
      color: #ffffff;
      font-size: 1.2rem;
      padding: 5px;
      font-weight: 700;
      border-radius: 3px;
    }
  }

  &__time {
    @include mq(medium, max) {
      line-height: 30px;
      font-weight: 700;
      color: #929091;
    }
  }
}

.c-personal-info {
  @include mq(medium, max) {
    color: #919191;
    font-weight: 700;
  }
}
