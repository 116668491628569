.c-news {
  &__side-title-lv1 {
    @include mq {
      color: $color-lv1;
      position: absolute;
      top: -50px;
      right: 40px;
      @include default-height;
      font-size: 5rem;
      transform: rotate(90deg) translate(100%, 100%);
      transform-origin: 100% 100%;
      display: block;
      @include bold;
      text-shadow: 0 0 500px $color-lv1;
    }
    @include mq(medium, max) {
      color: $color-lv1;
      position: absolute;
      //z-index: -1;
      top: -30px;
      right: 0;
      @include default-height;
      font-size: 4.0rem;
      transform: rotate(90deg) translate(100%, 100%);
      transform-origin: 100% 100%;
      display: block;
      @include bold;
      text-shadow: 0 0 500px $color-lv1;
      white-space: nowrap;
      z-index: -2;
    }

    &::before {
      @include mq {
        filter: blur(200px);
        @include box;
        width: 300px;
        height: 150px;
        background-color: $color-lv1;
        position: absolute;
        transform: translateY(100%);
        left: 10%;
      }
      @include mq(medium, max) {
        filter: blur(110px);
        @include box;
        width: 200px;
        height: 200px;
        background-color: $color-lv1;
        position: absolute;
        left: 10%;
      }
    }
  }

  &__side-title-lv2 {
    @include mq {
      color: $color-lv1;
      position: absolute;
      top: 450px;
      left: 60px;
      @include default-height;
      font-size: 5rem;
      transform: rotate(-90deg);
      transform-origin: 0;
      display: block;
      @include bold;
      text-shadow: 0 0 500px $color-lv1;
    }
    @include mq(medium, max) {
      color: $color-lv1;
      // position: absolute;
      position: relative;
      z-index: -1;
      // top: 120px;
      // left: -135px;
      top: 290px;
      left: -168px;
      @include default-height;
      font-size: 4.0rem;
      transform: rotate(-90deg);
      display: block;
      @include bold;
      text-shadow: 0 0 500px $color-lv1;
      white-space: nowrap;
    }

    &::before {
      @include mq {
        filter: blur(200px);
        @include box;
        width: 300px;
        height: 150px;
        background-color: $color-lv1;
        position: absolute;
        transform: translateY(100%);
        left: 10%;
      }
      @include mq(medium, max) {
        filter: blur(100px);
        @include box;
        // width: 250px;
        // height: 250px;
        width: 150px;
        height: 150px;
        background-color: $color-lv1;
        position: absolute;
        top: 0;
        left: 360px;
      }
    }

    &--newtopics {
      @include mq(medium, max) {
        // left: -150px;
        top: 150px;
      }
    }

    &--ranking {
      @include mq(medium, max) {
        left: -230px;
        top: 240px;
      }

      &::before {
        @include mq(medium, max) {
          width: 200px;
          height: 200px;
          right: 0;
        }
      }
    }
  }

  &__title-lv1 {
    @include mq {
      @include auto;
      @include pc-inner-width;
      display: flex;
    }
    @include mq(medium, max) {
      @include auto;
      // @include sp-inner(p);
      padding-right: 20px;
      padding-left: 20px;
      position: relative;
      z-index: 1;
      padding-bottom: 10px;
    }

    &__main {
      color: $color-navy;
      @include mq {
        @include bold;
        font-size: 3.6rem;
        z-index: 1;
      }
      @include mq(medium, max) {
        z-index: 1;
        font-size: 2.2rem;
        @include bold;
        @include default-height;
        white-space: nowrap;
        display: inline-block;
      }
    }

    &__border {
      @include mq {
        @include box;
        width: 40px;
        height: 2px;
        background-color: $color-lv2;
        margin-top: 25px;
        margin-left: 10px;
        margin-right: 10px;
      }
      @include mq(medium, max) {
        @include box;
        display: inline-block;
        width: 40px;
        height: 2px;
        background-color: $color-lv2;
        // margin-top: 0;
        // margin-left: 10px;
        // margin-right: 10px;
        position: relative;
        top: -8px;
      }
    }

    &__sub {
      color: $color-navy;
      @include mq {
        font-size: 1.2rem;
        padding-top: 18px;
        @include bold;
      }
      @include mq(medium, max) {
        font-size: 1.4rem;
        padding-top: 10px;
        @include bold;
        @include default-height;
      }
    }
  }

  &__linkto-lv1 {
    @include mq {
      width: 300px;
      display: block;
      justify-content: space-between;
      @include auto;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      display: flex;
      margin-top: 60px;
      transition: 0.3s;
      position: relative;
      overflow: hidden;
    }
    @include mq(medium, max) {
      // // @include sp-inner(m)
      // padding: 22px;
      // margin-right: 20px;
      // margin-left: 20px;
      width: 300px;
      display: block;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-right: auto;
      margin-left: auto;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      display: -ms-flexbox;
      display: flex;
      margin-top: 60px;
      transition: 0.3s;
      position: relative;
      overflow: hidden;
      clear: both;
      z-index: 1;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      @include pink-to-orange;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: right;
      transform-origin: right;
      transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
      z-index: 1;
    }

    &:hover {
      &::before {
        @include mq {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          -webkit-transform-origin: left;
          transform-origin: left;
        }
      }

      span:nth-of-type(2) {
        @include mq {
          -webkit-transform: translate(15px, -50%);
          transform: translate(15px, -50%);
        }
      }
    }

    span {
      color: $reverse-color;
      pointer-events: relative;
      z-index: 2;

      &:nth-of-type(1) {
        @include mq {
          @include bold;
          display: block;
          letter-spacing: 2px;
        }
        @include mq(medium, max) {
          @include bold;
          display: block;
          letter-spacing: 3px;
        }
      }

      &:nth-of-type(2) {
        @include mq {
          z-index: 2;
          @include box;
          width: 100px;
          height: 20px;
          background: url("../images/arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-position: center;
          position: absolute;
          top: 50%;
          right: 28px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99),
          -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
        }
        @include mq(medium, max) {
          content: "";
          background: url("../images/arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: block;
          width: 100px;
          height: 20px;
          color: $reverse-color;
        }
      }
    }
  }

  &__linkto-lv2 {
    @include mq {
      width: 300px;
      height: 71px;
      justify-content: space-between;
      flex-direction: column-reverse;
      @include auto;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      display: flex;
      margin-top: 60px;
      transition: 0.3s;
      position: relative;
      overflow: hidden;
    }
    @include mq(medium, max) {
      display: flex;
      justify-content: space-between;
      // @include sp-inner(m);
      margin-right: auto;
      margin-left: auto;
      background-color: $color-navy;
      border-radius: 50px;
      padding: 25px;
      margin-top: 60px;
      height: 71px;
      width: 300px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      @include pink-to-orange;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: left;
      transform-origin: left;
      transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
      z-index: 1;
    }

    &:hover {
      &::before {
        @include mq {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          -webkit-transform-origin: right;
          transform-origin: right;
        }
      }

      span:nth-of-type(2) {
        @include mq {
          -webkit-transform: translate(-15px) scale(-1, 1);
          transform: translate(-15px) scale(-1, 1);
        }
        @include mq(medium, max) {
          transform: scale(-1, 1);
          -webkit-transition: -webkit-transform scale(-1, 1);
        }
      }
    }

    span {
      color: $reverse-color;
      pointer-events: relative;
      z-index: 2;

      &:nth-of-type(1) {
        @include mq {
          @include bold;
          display: block;
          letter-spacing: 2px;
          position: absolute;
          //top: 50%;
          right: 28px;
        }
        @include mq(medium, max) {
          @include bold;
          display: block;
          letter-spacing: 3px;
          position: absolute;
          right: 63px;
        }
      }

      &:nth-of-type(2) {
        @include mq {
          z-index: 2;
          @include box;
          width: 100px;
          height: 20px;
          background: url("../images/arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          //top: 50%;
          //right: 28px;
          -webkit-transform: translateX(50%);
          transform: scale(-1, 1);
          transition: transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99),
          -webkit-transform 0.5s cubic-bezier(0.51, 0.3, 0, 0.99);
        }
        @include mq(medium, max) {
          @include box;
          width: 100px;
          height: 20px;
          content: "";
          background: url("../images/arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          position: absolute;
          -webkit-transform: translateX(50%);
          transform: scale(-1, 1);
        }
      }
    }
  }

  &__search-box {
    @include mq {
      margin-top: 60px;
      position: relative;
      @include pc-inner-width;
      @include auto;
      padding: 25px 0;
    }
    @include mq(medium, max) {
      margin-top: 30px;
      position: relative;
      @include sp-inner(m);
    }

    &__overlay {
      @include mq {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        content: "";
        opacity: 0.5;
        background: inherit;
        background-color: $reverse-color;
        filter: blur(3px);
        z-index: 1;
      }
      @include mq(medium, max) {
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        content: "";
        opacity: 0.5;
        background: inherit;
        background-color: $reverse-color;
        filter: blur(3px);
        z-index: 1;
      }
    }

    &__inner {
      @include mq {
        padding: 20px 90px;
        display: flex;
      }
      @include mq(medium, max) {
        padding: 10px;
        display: flex;
      }
    }

    &__input-area {
      @include mq {
        border-radius: 50px 0 0 50px;
        position: relative;
        z-index: 2;
        background-color: $color-lv2;
        opacity: 0.5;
        display: block;
        width: 100%;
        padding: 20px;
        font-size: 1.8rem;
      }
      @include mq(medium, max) {
        border-radius: 25px 0 0 25px;
        position: relative;
        z-index: 2;
        background-color: $color-lv2;
        opacity: 0.5;
        display: block;
        width: 100%;
        padding: 10px 20px;
        height: 40px;
      }
    }

    &__button {
      @include mq {
        background-color: $color-navy;
        @include box;
        width: 130px;
        position: relative;
        z-index: 2;
        //padding: 20px;
        cursor: pointer;
        border-radius: 0 50px 50px 0;
        //transition: 0.5s;
      }
      @include mq(medium, max) {
        background-color: $color-navy;
        @include box;
        width: 65px;
        position: relative;
        z-index: 2;
        height: 40px;
        border-radius: 0 25px 25px 0;
      }

      &:hover {
        @include pink-to-orange;
      }

      span {
        @include mq {
          @include box;
          width: 30px;
          height: 30px;
          border: 2px solid $reverse-color;
          border-radius: 50%;
          position: relative;
          left: 50%;
          //top: 50%;
          transform: translateX(-50%);
          //padding-top: 10px;
        }
        @include mq(medium, max) {
          @include box;
          width: 15px;
          height: 15px;
          border: 2px solid $reverse-color;
          border-radius: 50%;
          position: relative;
          left: 50%;
          //top: -2px;
          transform: translateX(-50%);
        }

        &::after {
          @include mq {
            @include box;
            width: 10px;
            height: 10px;
            position: absolute;
            border-left: 2px solid $reverse-color;
            transform: rotate(45deg);
            bottom: -12px;
            left: -2px;
          }
          @include mq(medium, max) {
            @include box;
            width: 10px;
            height: 10px;
            position: absolute;
            border-left: 2px solid $reverse-color;
            transform: rotate(45deg);
            bottom: -10px;
            left: -5px;
          }
        }
      }
    }
  }

  &__tabpanel {
    @include mq {
      margin-top: 60px;
      @include pc-inner-width;
      @include auto;
      display: flex;
      justify-content: space-between;
    }
    @include mq(medium, max) {
      //margin-top: 30px;
      @include auto;
      justify-content: center;
    }

    &__box {
      @include mq {
        width: 50%;
      }
      @include mq(medium, max) {
        display: none !important;
      }

      &__inner {
        @include mq {
          display: flex;
          justify-content: center;
          background-color: $color-lv3;
          padding: 20px 0;
          position: relative;
        }
        @include mq(medium, max) {
          display: flex;
          justify-content: center;
          background-color: $color-lv3;
          padding: 20px 0;
          position: relative;
        }

        &--reverse {
          @include mq {
            @include pink-to-orange;
          }
          @include mq(medium, max) {
            @include pink-to-orange;
          }
        }
      }

      &__icon {
        @include mq {
          @include box;
          width: 40px;
          height: 40px;
          background-color: $reverse-color;
          border-radius: 50%;
          margin-right: 15px;
        }
        @include mq(medium, max) {
          @include box;
          width: 20px;
          height: 20px;
          background-color: $reverse-color;
          border-radius: 50%;
          margin-right: 10px;
        }

        &-lv1 {
          position: relative;
          @include pink-to-orange;

          &::after {
            @include mq {
              @include box;
              position: absolute;
              top: 5px;
              left: 5px;
              width: 30px;
              height: 30px;
              background: url("../images/new-arrival_icon_white.svg");
              background-position: center;
              background-repeat: no-repeat;
              z-index: 3;
            }
          }

          &--reverse {
            position: relative;

            &::after {
              @include mq {
                @include box;
                position: absolute;
                top: 5px;
                left: 5px;
                width: 30px;
                height: 30px;
                background: url("../images/new-arrival_icon_color.svg");
                background-position: center;
                background-repeat: no-repeat;
                z-index: 3;
              }
            }
          }
        }

        &-lv2 {
          @include pink-to-orange;
          position: relative;

          &::after {
            @include mq {
              @include box;
              position: absolute;
              top: 6px;
              left: 6px;
              width: 28px;
              height: 28px;
              background: url("../images/popular_icon_white.svg");
              background-position: center;
              background-repeat: no-repeat;
              z-index: 3;
            }
          }

          &--reverse {
            position: relative;
            background-color: $reverse-color;

            &::after {
              @include mq {
                @include box;
                position: absolute;
                top: 6px;
                left: 6px;
                width: 30px;
                height: 30px;
                background: url("../images/popular_icon_color.svg");
                background-position: center;
                background-repeat: no-repeat;
                z-index: 3;
              }
            }
          }
        }
      }

      &__text {
        @include mq {
          padding-top: 10px;
          font-size: 1.6rem;
        }

        &--reverse {
          color: $reverse-color;
        }
      }
    }
  }

  &__index {
    border-bottom: 2px solid #e6e6e6;
    content: "";
    flex-grow: 1;
    margin-bottom: 17%;

    li {
      @include mq {
        margin: 18px;
      }
      @include mq(medium, max) {
        margin-left: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        // margin: 18px;
      }
    }

    &__list {
      @include mq {
        text-decoration: underline;
      }
      @include mq(medium, max) {
        text-decoration: underline;
      }
    }

    &__listin {
      @include mq {
        color: #5eb8cf;
        font-size: 1.6rem;
      }
      @include mq(medium, max) {
        color: #5eb8cf;
      }
    }
  }

  &__index-header {
    @include mq {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 2.2rem;
      margin-top: 70px;
    }
    @include mq(medium, max) {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1.6rem;
      margin-top: 35px;
    }

    &::after {
      border-top: 2px solid #e6e6e6;
      content: "";
      flex-grow: 1;
      margin-left: 1rem;
    }
  }
}
