@charset "UTF-8";

// breadcrumbs
@import "./breadcrumbs/scss/style";

/* ==========================================================================
   Font
   ==========================================================================*/

@import url('//fonts.googleapis.com/earlyaccess/notosansjapanese.css');

/* ==========================================================================
   Foundation
   ==========================================================================*/
@import './foundation/_reset.scss';
@import './foundation/_mixin.scss';
@import './foundation/_base.scss';
@import "./foundation/_keyframe";
@import "./foundation/slick/_slick-theme";
@import "./foundation/slick/_slick";
@import "./foundation/tabpanel/_style";

/* ==========================================================================
   Layout
   ==========================================================================*/

@import "./layout/header/_header-lv1";
@import "./layout/header/_style";
@import "./layout/footer/_footer-lv1";
@import "./layout/main/_main-lv1";

/* ==========================================================================
   Object
   ========================================================================== */

/* Component
   ----------------------------------------------------------------- */
@import "./object/component/_main";
@import "./object/component/news/_news-01";
//@import "./object/component/news/_news-02";
@import "./object/component/news/_news-03";
@import "./object/component/common/_style";
@import "./object/component/question/_style";
@import "./object/component/mypage/_style";
@import "./object/component/trainer/_style";
@import "./object/component/reservation/_style";
@import "./object/component/top/_style";
@import "./object/component/menu/_style";
@import "./object/component/review/_style";
@import "./object/component/_advertise";

/* Project
----------------------------------------------------------------- */
// TOPページ
@import "./object/project/_index";
// TOPページ

// @import "./object/project/_portals";

// ニュース
@import "./object/project/news/_news-01";
@import "./object/project/news/_news-02";
@import "./object/project/news/_news-03";

/* Utility
   ----------------------------------------------------------------- */
@import "./object/utility/_utility";
@import "./object/utility/_price";
@import "./object/utility/_line";

/* plugin
   ----------------------------------------------------------------- */
