.u-line-button {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  @include mq(medium, max) {
    margin-bottom: 30px;
  }

  p {
    @include bold;
    margin-bottom: 20px;
    @include mq {
      font-size: 1.7rem;
    }
    @include mq(medium, max) {
      font-size: 1.6rem;
    }
  }

  div {
    width: 200px;
    margin: 0 auto;
  }

  hr {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    @include mq {
      width: 50vw;
    }
    @include mq(medium, max) {
      width: 80vw;
    }
  }
}