.p-index-sitemap {
  @include mq {
    margin-top: 100px;
  }
  @include mq(medium, max) {
    margin-top: 50px;
  }

  &__inner {
    @include mq {
      @include pc-inner-width;
      @include auto;
    }
    @include mq(medium, max) {
      @include sp-inner(p);
    }
  }

  &__list {
    &__theme {
      @include mq {
        @include bold;
        font-size: 5rem;
        @include default-height;
      }
      @include mq(medium, max) {
        @include bold;
        font-size: 3rem;
        @include default-height;
      }
    }

    &__data {
      @include mq {
        margin-top: 30px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__item {
        display: table;
        position: relative;
        margin-left: 20px;
        transition: 0.3s;
        @include default-height;

        &::before {
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          @include box;
          width: 10px;
          height: 2px;
          background-color: $color-lv2;
        }

        &:not(:first-child) {
          @include mq {
            margin-top: 15px;
          }
          @include mq(medium, max) {
            margin-top: 20px;
          }
        }

        span {
          color: $color-lv2;
          transition: 0.3s;

          &::before {
            content: "【";
          }

          &::after {
            content: "】";
          }
        }

        &:hover {
          color: $color-lv1;

          span {
            color: $color-lv1;
          }
        }
      }
    }
  }
}

.p-mainImage {
  img {
    @include mq {
      object-fit: cover;
      max-height: 880px;
      object-position: 0 0;
    }
    @include mq(medium, max) {
    }
  }
}
