.c-news-03 {
  &__tall {
    @include mq {
      margin-top: 75px;
    }
    @include mq(medium, max) {
      margin-top: 35px;
    }
  }

  &__medium {
    @include mq {
      margin-top: 50px;
    }
  }

  &__short {
    @include mq {
      margin-top: 35px;
    }
    @include mq(medium, max) {
      border-bottom: 2px solid #d2d2d2;
      padding-bottom: 30px;
      margin-bottom: 30px;
      color: #919191;
    }
  }

  &__linkto {
    @include mq {
      display: table;
      color: $color-lv5;
    }
    @include mq(medium, max) {
      display: table;
      color: $color-lv5;
    }
  }

  &__list-number {
    @include mq {
      margin-top: 15px;
    }
    @include mq(medium, max) {
      margin-top: 15px;
    }

    div {
      width: 30px;
      height: 30px;
      @include box;
      display: table;
      background-color: $default-black;
      position: relative;
      top: 30px;
    }

    span {
      color: $reverse-color;
      position: absolute;
      @include centering(xy);
    }

    p {
      @include mq {
        padding-left: 50px;
      }
      @include mq(medium, max) {
        padding-left: 50px;
      }
    }
  }

  &__wrapping {
    @include mq {
      border: 2px solid $color-lv6;
      border-radius: 25px;
      padding: 40px;
    }
    @include mq(medium, max) {
      border: 2px solid $color-lv6;
      border-radius: 25px;
      padding: 30px;
    }

    &--lv1 {
      @include mq {
        display: flex;
      }

      div {
        @include mq {
          width: calc(50% - 10px);
          margin-right: 20px;
        }

        img {
          margin: 0;
          border-radius: 25px;
        }
      }

      p {
        @include mq {
          width: calc(50% - 10px);
        }
        @include mq(medium, max) {
          margin-top: 25px;
        }
      }
    }
  }

  &__column-lv1 {
    @include mq {
      display: flex;
    }

    &__img {
      border-radius: 25px;
      @include mq {
        margin-right: 20px;
        width: 250px;
      }

      img {
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }

    &__box {
      @include mq {
        width: calc(100% - 270px);
      }
      @include mq(medium, max) {
        margin-top: 20px;
      }

      &__title {
        @include bold;
      }

      &__eng {
        @include mq {
          margin-top: 35px;
          display: block;
          font-size: 1.1rem;
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
          font-size: 1.1rem;
        }
      }

      &__name {
        @include mq {
          font-size: 1.1rem !important;
        }
        @include mq(medium, max) {
          font-size: 1.6rem !important;
        }
      }

      &__linkto {
        &__text {
          @include mq {
            color: $color-lv5;
            font-size: 1.1rem !important;
          }
          @include mq(medium, max) {
            color: $color-lv5;
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
}
