//mq
$mq-breakpoints: (
  'xsmall': 320px,
  'small' : 600px,
  'medium': 768px,
  'large' : 1024px,
  'xlarge': 1280px,
);
@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $mq-breakpoints - 1px;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

//color
$default-black: #000000;
$reverse-color: #ffffff;
$color-lv1: #ed8a8c;
$color-lv2: #cccccc;
$color-lv3: #e5e5e5;
$color-lv4: #f61d6a;
$color-lv5: #4b7691;
$color-lv6: #e6e6e6;
$color-lv7: #a3a3a3;
$color-lv8: #ff6b01;
$color-lv9: #f3f2f2;
//$color-navy: #131747;
$color-navy: #121f32;
$color-lv11: #d3d3d3;
$color-lv12: #181d5a;
$color-pink: #f61d6a;
$color-orange: #ff6b01;
$color-sub: #808080;
$color-black: #222222;
$color-disabled: #e5e5e5;
$color-lightgray: #f7f8f9;

//mixin
@mixin text-none {
  // background-image : url("image/置き換えたい画像.png");
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin box {
  display: block;
  content: "";
}

@mixin auto {
  margin-right: auto;
  margin-left: auto;
}

@mixin centering($direction) {
  position: absolute;
  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $direction==x {
    left: 50%;
    transform: translateX(-50%);
  } @else if $direction==y {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin pink-to-orange {
  background: linear-gradient(to right, $color-lv4, $color-lv8) !important;
}

@mixin blue {
  background: #131747 !important;
}

//font-weight
@mixin bold {
  font-weight: 700;
}

@mixin middle {
  font-weight: 200;
}

//line-height
@mixin default-height {
  line-height: 1.0;
}

// width
@mixin sp-inner($direction) {
  @if $direction==p {
    padding-right: 15px;
    padding-left: 15px;
  } @else if $direction==m {
    margin-right: 15px;
    margin-left: 15px;
  }
}

@mixin pc-inner($direction) {
  @if $direction==p {
    padding-right: 20px;
    padding-left: 20px;
  } @else if $direction==m {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@mixin pc-inner-width {
  width: 1020px;
  @include auto;
}



@mixin fs($num) {
  @if $num == 01 {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  } @else if $num == 02 {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  } @else if $num == 03 {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  } @else if $num == 04 {
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  } @else if $num == 05 {
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  } @else if $num == 06 {
    font-size: 40px;
    @media (max-width: 768px) {
      font-size:30px;
    }
  }
}



@mixin imgtag($args){
  aspect-ratio: $args;
  object-fit: cover;
  object-position: center center;
}

@mixin bgimg($args){
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $args;
}