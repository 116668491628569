.c-breadcrumbs {
  @include mq {
    padding: 30px 0;
    position: relative;
    overflow: hidden;
  }
  @include mq(medium, max) {
    padding: 20px 0;
    position: relative;
    overflow: hidden;
  }

  &::before {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    content: '';
    // opacity: .8;
    opacity: .2;
    background: inherit;
    background-color: $reverse-color;
    filter: blur(3px);
    z-index: 1;
  }

  &__inner {
    @include mq {
      @include pc-inner-width;
      @include auto;
      display: flex;
      justify-content: flex-end;
      z-index: 3;
      position: relative;
    }
    @include mq(medium, max) {
      @include sp-inner(p);
      @include auto;
      display: flex;
      z-index: 3;
      position: relative;
    }
  }

  &__item {
    @include mq {
      display: block;
      text-align: right;
      letter-spacing: 3px;
      font-size: 1.2rem;
    }
    @include mq(medium, max) {
      font-size: 1.0rem;
    }

    &:not(:last-child) {
      &::after {
        @include box;
        content: "＞";
        width: 10px;
        height: 10px;
        // border-right: 1px solid $default-black;
        // border-bottom: 1px solid $default-black;
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
}
