.c-plan {
  &__price {
    @include mq {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }
    @include mq(medium, max) {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
    }

    table {
      @include mq {
        margin: 10px auto;
      }
      @include mq(medium, max) {
        margin: 10px auto;
      }
    }

    td {
      @include mq {
        height: 47px;
      }
      @include mq(medium, max) {
        height: 47px;
      }
    }

    td:nth-of-type(1) {
      @include mq {
        font-size: 14px;
        color: #555;
        padding-bottom: 8px;
        margin-bottom: 20px;
      }
      @include mq(medium, max) {
        font-size: 14px;
        color: #555;
        padding-bottom: 8px;
        margin-bottom: 20px;
      }
    }

    td:nth-of-type(2) {
      @include mq {
        text-align: right;
        font-style: normal;
        font-size: 38px;
        font-weight: 600;
        color: #555;
        padding: 0 5px;
        line-height: 38px;
        font-family: "Avenir Next";
      }
      @include mq(medium, max) {
        text-align: right;
        font-style: normal;
        font-size: 38px;
        font-weight: 600;
        color: #555;
        padding: 0 5px;
        line-height: 38px;
        font-family: "Avenir Next";
      }
    }
  }
}

.c-top-demo {
  &__button-area {
    @include mq {
      display: flex;
      margin-top: 30px;
      justify-content: center;
    }
    @include mq(medium, max) {
      display: flex;
      margin-top: 30px;
      flex-wrap: wrap;
    }

    a {
      @include mq {
        width: calc((100% - 150px) / 3);
        // margin-right: 75px;
        margin-left: 42px;
      }
      @include mq(medium, max) {
        margin-bottom: 40px;
        width: 100%;
        margin-right: 40px;
        margin-left: 40px;
      }
    }

    a:nth-of-type(3) {
      @include mq {
        width: calc((100% - 150px) / 3);
        margin-right: 0;
      }
      @include mq(medium, max) {
      }
    }

    a {
      @include mq {
        text-align: center;
        background-color: darkorange;
        padding: 25px;
        border-radius: 25px;
      }
      @include mq(medium, max) {
        text-align: center;
        background-color: darkorange;
        padding: 25px;
        border-radius: 25px;
      }

      span {
        @include mq {
          color: #fff;
          z-index: 1;
          font-weight: 700;
        }
        @include mq(medium, max) {
          color: #fff;
          z-index: 1;
          font-weight: 700;
        }
      }
    }
  }
}

.c-faq {
  &__question {
    @include mq {
      position: relative;
      width: calc(100% - 60px);
      left: 60px;
    }
    @include mq(medium, max) {
      position: relative;
      width: calc(100% - 13.3333vw);
      left: 13.3333vw;
    }
  }

  &__answer {
    @include mq {
      position: relative;
      width: calc(100% - 60px);
      left: 60px;
    }
    @include mq(medium, max) {
      position: relative;
      width: calc(100% - 13.3333vw);
      left: 13.3333vw;
    }
  }

  &__question:before {
    @include mq {
      content: "Q";
      background: #999;
      top: 5px;
      left: -60px;
      width: 38px;
      height: 38px;
      position: absolute;
      display: block;
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      box-sizing: border-box;
      padding-top: 9px;
      font-size: 1.9rem;
    }
    @include mq(medium, max) {
      content: "Q";
      background: #999;
      top: 1.86667vw;
      left: -13.33333vw;
      width: 8.8vw;
      height: 8.8vw;
      position: absolute;
      display: block;
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      box-sizing: border-box;
      padding-top: 1.8vw;
      font-size: 1.9rem;
    }
  }

  &__answer:before {
    @include mq {
      content: "A";
      background: lightcoral;
      top: 5px;
      left: -60px;
      width: 38px;
      height: 38px;
      position: absolute;
      display: block;
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      box-sizing: border-box;
      padding-top: 9px;
      font-size: 1.9rem;
    }
    @include mq(medium, max) {
      content: "A";
      background: lightcoral;
      top: 1.86667vw;
      left: -13.33333vw;
      width: 8.8vw;
      height: 8.8vw;
      position: absolute;
      display: block;
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      box-sizing: border-box;
      padding-top: 1.8vw;
      font-size: 1.9rem;
    }
  }

}

.c-header {
  @include mq {
    line-height: 2.5;
  }
  @include mq(medium, max) {
    line-height: 2.5;
    padding-right: 25px;
    padding-left: 25px;
  }

  ul {
    @include mq {
      display: flex;
    }
    @include mq(medium, max) {
    }
  }

  li {
    @include mq {
      display: inline-block;
      padding-right: 15px;
      padding-left: 15px;
      border-right: 2px solid #cccccc;
    }
    @include mq(medium, max) {
      font-size: 1.6rem;
      color: $color-navy;
      position: relative;
    }
  }

  li:after {
    @include mq {
    }
    @include mq(medium, max) {
      content: "";
      display: inline-block;
      width: 40px;
      height: 1px;
      background-color: #cccccc;
      margin-top: 0;
      margin-left: 10px;
      margin-right: 10px;
      position: absolute;
      top: 20px;
    }
  }
}