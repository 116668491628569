.p-news-02 {
  &__section-lv1 {
    position: relative;
    @include mq {
      padding-bottom: 60px;
      padding-top: 80px;
      width: 90vw;
      margin-right: auto;
      margin-left: auto;
    }
    @include mq(medium, max) {
      padding-bottom: 25px;
      z-index: 1;
    }

    &__inner {
      @include mq(medium, max) {
        padding-top: 70px;
      }
    }

    &__category {
      @include mq {
        display: flex;
        flex-wrap: wrap;
        @include pc-inner-width;
        margin-top: 40px;
      }
      @include mq(medium, max) {
        display: flex;
        flex-wrap: wrap;
        // @include sp-inner(p);
        margin-top: 25px;
        margin-right: auto;
        margin-left: auto;
        width: 330px;
      }

      &__item {
        @include mq {
          padding: 12px 18px;
          border: 1px solid $default-black;
          border-radius: 50px;
          margin-top: 20px;
          transition: 0.3s;
        }
        @include mq(medium, max) {
          padding: 6px 9px;
          border: 1px solid $default-black;
          border-radius: 50px;
          margin-top: 12.5px;
        }

        &:not(:last-child) {
          @include mq {
            margin-right: 20px;
          }
          @include mq(medium, max) {
            margin-right: 15px;
          }
        }

        &:hover {
          @include mq {
            background-color: $color-navy;
            color: $reverse-color;
          }

          .p-news-02__section-lv1__category__item__icon {
            @include mq {
              background: url("../images/tag_icon_white.svg");
            }
          }

          .p-news-02__section-lv1__category__item__text {
            @include mq {
              color: $reverse-color;
            }
          }
        }

        &--current {
          background-color: $color-navy;

          .p-news-02__section-lv1__category__item__icon {
            background: url("../images/tag_icon_white.svg");
            background-repeat: no-repeat;
            background-size: cover;
          }

          .p-news-02__section-lv1__category__item__text {
            color: $reverse-color;
          }
        }

        &__icon {
          @include mq {
            transition: 0.3s;
            display: inline-block;
            content: "";
            width: 15px;
            height: 15px;
            background: url("../images/tag_icon_black.svg");
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 5px;
          }
          @include mq(medium, max) {
            display: inline-block;
            content: "";
            width: 15px;
            height: 15px;
            background: url("../images/tag_icon_black.svg");
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 5px;
            position: relative;
            top: 3px;
          }
        }

        &__text {
          @include mq {
            transition: 0.3s;
            font-size: 1.6rem;
            letter-spacing: 2px;
          }
          @include mq(medium, max) {
            font-size: 1.2rem;
            letter-spacing: 2px;
          }
        }
      }
    }

    &__caution {
      @include mq {
        @include pc-inner-width;
        @include auto;
        font-size: 2.6rem;
        @include bold;
        margin-top: 65px;
      }
      @include mq(medium, max) {
        @include pc-inner(p);
        @include auto;
        font-size: 2.1rem;
        @include bold;
        margin-top: 40px;
      }
    }

    &__list-lv1 {
      @include mq {
        margin-top: 65px;
      }
      @include mq(medium, max) {
        margin-top: 25px;
      }

      &__inner {
        @include mq {
          @include pc-inner-width;
          @include auto;
          display: flex;
          flex-wrap: wrap;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
        }
      }

      &__box {
        @include mq {
          width: calc(calc(100% / 3) - 20px);
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:not(:nth-of-type(3n)) {
          @include mq {
            margin-right: 30px;
          }
        }

        &:nth-of-type(n + 4) {
          @include mq {
            margin-top: 45px;
          }
        }

        &__thumbnail {
          @include mq(medium, max) {
            position: relative;
          }

          &__img {
            @include mq {
              transition: 0.3s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            &:hover {
              @include mq {
                border-radius: 50px;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }
            }
          }
        }

        &__content {
          @include mq {
            padding: 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          color: $color-lv7;
          @include mq {
            margin-top: 30px;
            display: block;
            @include default-height;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 30px;
          }

          &::before {
            content: "#";
            color: $default-black;
            margin-right: 20px;
          }
        }

        &__item-lv2 {
          @include mq {
            margin-top: 10px;
            display: block;
            @include default-height;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }

    &__list-lv2 {
      @include mq {
        margin-top: 70px;
      }
      @include mq(medium, max) {
        margin-top: 30px;
      }

      &__inner {
        @include mq {
          display: flex;
          flex-wrap: wrap;
          @include pc-inner-width;
          @include auto;
        }
        @include mq(medium, max) {
          @include sp-inner(p);
          @include auto;
        }
      }

      &__box {
        @include mq {
          width: calc(100% / 2);
          display: flex;
        }
        @include mq(medium, max) {
          margin-top: 30px;
          display: block;
        }

        &:nth-of-type(n + 3) {
          @include mq {
            margin-top: 45px;
          }
        }

        &__thumbnail {
          @include mq {
            width: 50%;
          }

          &__img {
            @include mq {
              transition: 0.3s;
            }
            @include mq(medium, max) {
              border-radius: 25px;
            }

            &:hover {
              @include mq {
                border-radius: 50px;
              }
              @include mq(medium, max) {
                border-radius: 25px;
              }
            }
          }
        }

        &__content {
          @include mq {
            width: 50%;
            padding: 0 20px;
          }
          @include mq(medium, max) {
            padding-top: 20px;
          }
        }

        &__title {
          @include mq {
            font-size: 1.6rem;
            @include bold;
          }
          @include mq(medium, max) {
            font-size: 1.6rem;
            @include bold;
          }
        }

        &__item-lv1 {
          @include mq {
            margin-top: 30px;
            display: block;
            @include default-height;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 30px;
          }
        }

        &__item-lv2 {
          font-style: italic;
          @include mq {
            margin-top: 10px;
            display: block;
            @include default-height;
            color: $color-lv2;
          }
          @include mq(medium, max) {
            @include default-height;
            display: block;
            margin-top: 15px;
            color: $color-lv2;
          }
        }
      }
    }

    &__linkto {
      @include mq {
        margin-top: 65px;
        display: flex;
        justify-content: center;
      }
      @include mq(medium, max) {
        @include sp-inner(p);
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        z-index: 2;
        position: relative;
      }

      &__item {
        @include mq {
          width: 50px;
          height: 50px;
          display: block;
          position: relative;
          border-radius: 50%;
        }
        @include mq(medium, max) {
          width: 50px;
          height: 50px;
          display: block;
          position: relative;
          border-radius: 50%;
        }

        &--current {
          @include mq {
            background-color: $color-navy;
          }
          @include mq(medium, max) {
            background-color: $color-navy;
          }

          span {
            color: $reverse-color;
          }
        }

        &:hover {
          @include mq {
            background-color: $color-navy;
          }

          span {
            color: $reverse-color;
          }
        }

        span {
          @include mq {
            position: absolute;
            @include centering(xy);
          }
          @include mq(medium, max) {
            position: absolute;
            @include centering(xy);
          }
        }
      }

      &__backto {
        @include mq {
          width: 50px;
          height: 50px;
          display: block;
          content: "";
          position: relative;
          border-radius: 50%;
          margin-right: 30px;
          background-color: $color-navy;
          //border: 1px solid $color-lv2;
          transition: 0.3s;
        }
        @include mq(medium, max) {
          width: 50px;
          height: 50px;
          display: block;
          content: "";
          position: relative;
          border-radius: 50%;
          margin-right: 30px;
          background-color: $color-navy;
          //border: 1px solid $color-lv2;
        }

        span {
          @include mq {
            @include box;
            width: 10px;
            height: 10px;
            border-left: 2px solid $color-lv2;
            border-bottom: 2px solid $color-lv2;
            position: absolute;
            @include centering(xy);
            transform: translate(-50%, -50%) rotate(45deg);
            transition: 0.3s;
          }
          @include mq(medium, max) {
            @include box;
            width: 10px;
            height: 10px;
            border-left: 2px solid $color-lv2;
            border-bottom: 2px solid $color-lv2;
            position: absolute;
            @include centering(xy);
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }

        &:hover {
          @include mq {
            background: linear-gradient(to right, $color-lv4, $color-lv8);
          }

          span {
            @include mq {
              border-left: 2px solid $reverse-color;
              border-bottom: 2px solid $reverse-color;
            }
            @include mq(medium, max) {
              border-left: 2px solid $reverse-color;
              border-bottom: 2px solid $reverse-color;
            }
          }
        }
      }

      &__next {
        @include mq {
          width: 50px;
          height: 50px;
          display: block;
          content: "";
          position: relative;
          border-radius: 50%;
          margin-left: 30px;
          background-color: $color-navy;
          //border: 1px solid $color-lv2;
          transition: 0.3s;
        }
        @include mq(medium, max) {
          width: 50px;
          height: 50px;
          display: block;
          content: "";
          position: relative;
          border-radius: 50%;
          margin-left: 30px;
          background-color: $color-navy;
          //border: 1px solid $color-lv2;
        }

        span {
          @include mq {
            @include box;
            width: 10px;
            height: 10px;
            border-right: 2px solid $color-lv2;
            border-top: 2px solid $color-lv2;
            position: absolute;
            @include centering(xy);
            transform: translate(-50%, -50%) rotate(45deg);
            transition: 0.3s;
          }
          @include mq(medium, max) {
            @include box;
            width: 10px;
            height: 10px;
            border-right: 2px solid $color-lv2;
            border-top: 2px solid $color-lv2;
            position: absolute;
            @include centering(xy);
            transform: translate(-50%, -50%) rotate(45deg);
            transition: 0.3s;
          }
        }

        &:hover {
          @include mq {
            background: linear-gradient(to right, $color-lv4, $color-lv8);
          }

          span {
            @include mq {
              border-right: 2px solid $reverse-color;
              border-top: 2px solid $reverse-color;
            }
            @include mq(medium, max) {
              border-right: 2px solid $reverse-color;
              border-top: 2px solid $reverse-color;
            }
          }
        }
      }
    }

    &__mini-category {
      @include mq {
        @include pc-inner-width;
        @include auto;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
      }
      @include mq(medium, max) {
        display: none;
      }

      a {
        @include mq {
          display: inline-block;
          font-size: 2.6rem;
          color: $color-lv11;
          margin-top: 15px;
          transition: 0.3s;
          font-weight: normal;
          border-bottom: 2px solid transparent;
        }
        @include mq(medium, max) {
          display: inline-block;
          font-size: 2rem;
          color: $color-lv11;
          margin-top: 15px;
          font-weight: normal;
          border-bottom: 2px solid transparent;
        }

        &:not(:last-child) {
          @include mq {
            margin-right: 35px;
          }
          @include mq(medium, max) {
            margin-right: 20px;
          }
        }

        &.is-current {
          color: $color-navy;
          border-bottom: 2px solid $color-navy;
          @include bold;
        }

        &:hover {
          @include mq {
            color: $color-navy;
            @include bold;
            border-bottom: 2px solid $color-navy;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
