.c-review {
  &__area {
    @include mq {
      padding: 20px;
      background-color: #ffffff;
      border: 3px solid #ffffff;
      margin-bottom: 30px;
    }
    @include mq(medium, max) {
      padding: 20px;
      background-color: #ffffff;
      border: 3px solid #ffffff;
      margin-bottom: 20px;
    }

    img {
      @include mq {
        height: auto;
        width: 140px;
        margin-right: 20px;
      }
      @include mq(medium, max) {
        height: auto;
        width: 80px;
        margin-right: 20px;
      }
    }
  }

  &__user-area {
    @include mq {
      display: flex;
    }
    @include mq(medium, max) {
      display: flex;
    }
  }

  &__image-area {
    @include mq {
      width: 150px;
    }
    @include mq(medium, max) {
      width: 100px;
    }

    img {
      @include mq {
        border-radius: 50% !important;
      }
      @include mq(medium, max) {
        border-radius: 50% !important;
      }
    }
  }

  &__title-area {
    @include mq {
      display: flex;
    }
    @include mq(medium, max) {
      display: flex;
    }
  }

  &__text-area {
    h3 {
      @include mq {
        font-size: 1.8rem;
        @include bold();
      }
      @include mq(medium, max) {
        @include bold();
      }
    }
  }

  &__button-area {
    @include mq {
      display: flex;
      margin-top: 20px;
    }
    @include mq(medium, max) {
      display: flex;
      margin-top: 10px;
    }

    a {
      @include mq {
        background-color: #121f32;
        padding: 15px 40px;
        display: inline-block;
        margin: 10px;
        border-radius: 5px;
      }
      @include mq(medium, max) {
        background-color: #121f32;
        padding: 10px 20px;
        display: inline-block;
        margin: 10px;
        border-radius: 5px;
      }

      span {
        @include mq {
          color: #fff;
          z-index: 1;
          font-weight: 700;
        }
        @include mq(medium, max) {
          color: #fff;
          z-index: 1;
          font-weight: 700;
        }
      }
    }
  }
}