/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

main {
  display: block;
  min-height: calc(100vh - 390px);
}

html {
  font-size: 62.5%;
  overflow: auto;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

* {
  box-sizing: border-box;
  //-webkit-appearance: none;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  font-family: -apple-system, YuGothic, ‘游ゴシック’, ‘Hiragino Kaku Gothic ProN’, Meiryo, ‘游ゴシック’, sans-serif;
}

body {
  font-family: -apple-system, blinkMacSystemFont, /* Macの欧文はSan Francisco (OS X 10.11以降) */
  'Helvetica Neue', /* Macの欧文 (OS X 10.10以前) */
  'Segoe UI', /* Windowsの欧文 */
  YuGothicM, /* Windows8.1以上は游ゴシック */
  YuGothic, /* Macの游ゴシック */
  Meiryo, /* 游ゴシックが入っていないWindows */
  sans-serif;
}

/* Windows */
@font-face {
  font-family: YuGothicM;
  font-weight: normal;
  src: local('YuGothic-Medium'), /* Mediumを明示的に指定 */
  local('Yu Gothic Medium'), /* Chrome用 */
  local('YuGothic-Regular'); /* Windows8.1ではMediumがないのでRegularを指定 */
}

@font-face {
  font-family: YuGothicM;
  font-weight: bold;
  src: local('YoGothic-Bold'), /* Boldはそのまま */
  local('Yu Gothic');     /* Chrome用 */
}

img {
  width: 100%;
  height: auto;
  display: block;
}
