@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  @include mq {
    cursor: pointer;
    transition: 0.3s;
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  &:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  @include mq {
    left: 100px;
    @include box;
    width: 70px;
    height: 70px;
    background: $color-navy;
    z-index: 3;
    border-radius: 50%;
    transition: 0.3s;
  }
  @include mq(medium, max) {
    left: 0;
    @include box;
    width: 50px;
    height: 50px;
    background: $color-navy;
    z-index: 3;
    border-radius: 50%;
    transition: 0.3s;
  }

  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }

  &:before {
    @include mq {
      @include box;
      width: 10px;
      height: 10px;
      border-right: 3px solid $reverse-color;
      border-top: 3px solid $reverse-color;
      transform: rotate(-135deg);
      position: absolute;
      left: 43%;
      top: 43%;
    }
    @include mq(medium, max) {
      @include box;
      width: 10px;
      height: 10px;
      border-right: 3px solid $reverse-color;
      border-top: 3px solid $reverse-color;
      transform: rotate(-135deg);
      position: absolute;
      left: 43%;
      top: 43%;
    }
  }

  &:hover {
    @include mq {
      @include pink-to-orange;
    }
  }
}

.slick-next {
  @include mq {
    right: 100px;
    @include box;
    width: 70px;
    height: 70px;
    background: $color-navy;
    z-index: 3;
    border-radius: 50%;
    transition: 0.3s;
  }
  @include mq(medium, max) {
    right: 0;
    @include box;
    width: 50px;
    height: 50px;
    background: $color-navy;
    z-index: 3;
    border-radius: 50%;
    transition: 0.3s;
  }

  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }

  &:before {
    @include mq {
      @include box;
      width: 10px;
      height: 10px;
      border-right: 3px solid $reverse-color;
      border-top: 3px solid $reverse-color;
      transform: rotate(45deg);
      position: absolute;
      right: 43%;
      top: 43%;
    }
    @include mq(medium, max) {
      @include box;
      width: 10px;
      height: 10px;
      border-right: 3px solid $reverse-color;
      border-top: 3px solid $reverse-color;
      transform: rotate(45deg);
      position: absolute;
      right: 43%;
      top: 43%;
    }
  }

  &:hover {
    @include mq {
      @include pink-to-orange;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    //background-color: $reverse-color;
    border-radius: 50%;

    button {
      border: 0;
      background: $color-lv2;
      border-radius: 50%;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      @include mq {
        transition: 0.3s;
      }

      &:hover, &:focus {
        outline: none;
        background: linear-gradient(to right, $color-lv4, $color-lv8) !important;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button {
      background: linear-gradient(to right, $color-lv4, $color-lv8) !important;
    }
  }
}
