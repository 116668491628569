.c-trainer-edit {
  position: fixed;
  width: 100%;
  //background-color: $color-navy;
  bottom: 0;
  //text-align: center;
  //vertical-align: middle;
  //justify-content: center;
  z-index: 10;
  display: flex;

  @include mq {
    height: 70px;
  }
  @include mq(medium, max) {
  }

  p {
    color: $reverse-color;
    @include bold;
    text-align: center;
    line-height: 70px;
  }

  div:nth-child(1) {
    //position: absolute;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    width: 50%;
    background-color: $color-navy;
    display: flex;
  }

  div:nth-child(2) {
    //position: absolute;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    width: 50%;
    @include pink-to-orange;
    //background-color: @include pink-to-orange;;
    display: flex;
  }

  span {
    display: block;
    width: 45px;
    height: 45px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-top: 12.5px;
    margin-bottom: 12.5px;
    margin-right: 10px;
  }

  img {
    width: 35px;
    height: 35px;
    transform: translateX(15%) translateY(16%);
  }
}

.c-trainer-info {
  @include mq {
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-40px);
  }
  @include mq(medium, max) {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-40px);
  }

  img {
    @include mq {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      object-fit: cover;
      transform: translateY(-40px);
    }
    @include mq(medium, max) {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
      transform: translateY(20px);
      margin-bottom: 60px;
    }
  }

  &__en-name {
    font-size: 1.6rem;
  }

  table {
    @include mq {
      width: 50%;
    }
    @include mq(medium, max) {
    }
  }

  td {
    @include mq {
      width: 50%;
      padding-top: 20px;
    }
    @include mq(medium, max) {
      width: 50%;
      padding-top: 20px;
    }
  }
}

.c-trainer-training {
  @include mq {
    width: 800px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 30px;
    border: 3px solid #ffffff;
    overflow: hidden;
    position: relative;
  }
  @include mq(medium, max) {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 30px;
    border: 3px solid #ffffff;
    overflow: hidden;
    position: relative;
  }

  &__border {
    @include mq {
      padding-bottom: 20px;
      border-bottom: 3px solid #ffffff;
    }
    @include mq(medium, max) {
      padding-bottom: 10px;
      border-bottom: 3px solid #ffffff;
      text-align: center;
      position: relative;
      top: 15px;
    }
  }

  table {
    @include mq {
      width: 100%;
    }
    @include mq(medium, max) {
    }
  }

  td:nth-of-type(1) {
    @include mq {
      width: 30%;
      padding-top: 20px;
      color: $color-navy;
    }
    @include mq(medium, max) {
    }
  }
}

.c-trainer-input {
  @include mq(medium, max) {
    width: 330px;
    margin-right: auto;
    margin-left: auto;
  }
}
