/**
BASIC
*/

/*html{
	background-color: #f7f8f9;
}*/

html {
  position: relative;
}

html > iframe#facebook-jssdk-iframe {
  position: absolute;
  top: 0;
  z-index: -9999;
}

#wrapper {
  width: 1200px;
  margin: 0 auto;
  padding: 0px;
  clear: both;
}

#contents {
  margin: 0;
}

#main_contents {
  max-width: 1020px;
  /*float: left;
  margin-right: 20px;*/
  margin: 0 auto;
}

#sidebar {
  float: left;
  width: 250px;
  display: none;
}

.inner {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}


/**
HEADER
*/

#header {
  background: #FFF;
  margin-bottom: 20px;
}

#header .inner {
}

/*HEADER_fixed

@media print {
		#header {
		position: static;
		}
} */

#header p.logo {
  margin: 5px 0;
  float: left;
}

#header p.logo img.title {
  height: 20px;
}

#header .box.info {
  float: right;
}

#header .box.info ul li.hours {
  text-align: center;
  margin-bottom: 5px;
}

#header .box.info ul li.tel span a {
  font-size: 25px;
  padding-left: 28px;
  background: url(../images/icon_tel.png) no-repeat center left;
  font-weight: bold;
  pointer-events: none;
  color: inherit;
  text-decoration: none;
}

#header .box.info ul.sub_navi {
  list-style: none;
}

#header .box.btn {
  float: right;
  margin-top: 10px;
}

#header .box.social ul li {
  list-style: none;
  display: inline;
  margin-left: 0px;
}

#header .box.social ul li img {
  width: 20px;
}

#header .head_front {
  /*background: url(../images/white-opacity-80.png);
  position:absolute;
  width: 100%;
  z-index: 1000;*/
  height: 34px;
  line-height: 34px;
  border-bottom: solid 1px #EEE;
}

#header h1 {
  height: 50px;
  line-height: 50px;
  display: block;
  display: table;
  border-spacing: 12px;
  margin-left: -12px;
  margin-bottom: -4px;
}

#header h1 span.logo img {
  max-height: 25px;
  max-width: 150px;
  vertical-align: middle;
  display: table-cell;
}

#header h1 span.title {
  font-size: 18px;
  line-height: 1.4;
  vertical-align: middle;
  display: table-cell;
}

#header h1 span.title a {
  color: #333;
}

#header .head_front span.home_link {
  display: inline-block;
}

#header .head_front span.home_link a {
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: 4px 8px 2px 8px;
  border: solid 1px #666;
  background-color: #FFF;
  color: #666;

  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

#header .head_front span.home_link a:hover {
  border: solid 1px #666;
  background-color: #666;
  color: #FFF;
  text-decoration: none;
}

#header .head_front .sub_menu {
  float: right;
  position: relative;
}

#header .head_front .panel-wrap i {
  color: #72932b;
}

#header .head_front p.login_last {
  display: inline;
  margin: 0 15px 0 0;
  line-height: 30px;
  font-size: 85%;
  float: right;
}


/**
SUB NAVI
*/


ul.sub_navi li {
  /*display: block;
  float: left;*/
  margin-left: 1em;
  line-height: 1.5;
  float: left;
  font-size: 13px;

  line-height: 34px;
  height: 34px;

  display: inline-block;
  vertical-align: middle;
}

ul.sub_navi li .selsct_lang {
  margin-top: -1px;
}

ul.sub_navi li i {
  color: #72932b;
}

ul.sub_navi li a {
  text-decoration: none;
  outline: 0;
}

ul.sub_navi li a:link {
  color: #555;
  outline: 0;
}

ul.sub_navi li a:visited {
  color: #555;
  outline: 0;
}

ul.sub_navi li a:active {
  color: #555;
  outline: 0;
}

ul.sub_navi li a:hover {
  color: #666;
  text-decoration: none;
  outline: 0;
}


/**
BOX_SEARCH
*/

.box_search.block {
  position: relative;
  /*background: rgba(227,232,237,0.8);
  background: rgba(161,160,156,0.5);*/
  border: solid 2px #4abaa7;
  padding: 10px 30px;
  border-radius: 3px;
}

.box_search.detail {
  /*background: #FFF;*/
  padding: 30px;
  border: none;
}

.box_search.detail p.title {
  /*margin-bottom: 0;
  margin-top: .5em;*/
}

.box_search.detail table.fixed td {
  padding: 10px 0 0 0;
}

.box_search.detail table.fixed td h4 {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: solid 2px rgba(0, 0, 0, 0.4);
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  /*color: rgba(74,186,167,0.85);*/
}

.box_search.detail td.plan {
  padding-bottom: 20px !important;
}

.box_search.detail table thead {
}

.box_search.detail td.room hr.line {
  margin: 1.5em auto 0 auto;
}


.box_search.detail p.info {
  margin: 0 0 1em 0;
}

.box_search.detail .block p.info {
  margin: 1em 0;
}

.box_search.detail p.more {
  margin: 5px 0 0 0;
  font-size: 12px;
}

.box_search.detail div.photo {
  position: relative;
  margin-bottom: 1em;
}

.box_search.detail div.photo {
  text-align: center;
  /*background: rgba(0,0,0,0.5) ;*/
}

.box_search.detail div.photo {
  height: 500px;
  overflow: hidden;
  position: relative;
}

.box_search.detail div.photo img {
  position: absolute;
  width: 20%;
  height: 0;
  width: 100%;
  height: 100%;
  /* padding-bottom: 20%;*/
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  object-fit: cover;
  font-family: 'object-fit: cover;'
}


.box_search.detail span.more {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 12px;
  line-height: 1;
}

.box_search.detail span.more a {
  position: relative;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  color: #FFF;
  z-index: 999;
}


.box_search.detail input[type="submit"].btn.full,
.box_search.detail button.full,
.box_search.detail a.btn.full,
input[type="submit"].btn.full.auto {
  width: inherit;
  margin: 0 auto;
}


.box_search ul {
  display: inline-block;
  width: auto;
  /*margin-right: 2em;*/
  margin-top: 0;
  vertical-align: top;
}

.box_search ul.num {
  /*border-left: solid 1px rgba(0,0,0,0.1);
  padding-left: 10px;*/
}

.box_search ul.num li.person {
  vertical-align: middle;
}

.box_search ul.num li.person ul.multiple li span {
  margin-top: 0px;
  margin-right: 10px;
  display: inline-block;
  padding: 3px 5px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  vertical-align: bottom;
  margin-bottom: 8px;
  font-size: 10px;
}

.box_search ul.num li {
  display: inline-block;
  float: none;
  /*margin-bottom: 10px;*/
  margin-right: 30px;
}

.box_search ul.num li:last-child {
  margin-right: 0;
}

.box_search ul.num li.person ul.multiple li {
  display: block;
}

.box_search ul.num li.person ul.multiple li ul li {
  display: inline-block;
  /*min-width: 82px;*/
}

.box_search ul.num.rooms li.person ul.multiple li ul li {
  display: inline-block;
}

.box_search ul.num li.person:last-child {
  margin-bottom: 0;
}

.box_search ul.num li ul li {
  display: inline-block;
}

.box_search ul.num li.room,
.box_search ul.num li.person {
  /*text-align:left;*/
}


.box_search ul.num li.room select,
.box_search ul.num li.person select {
  /*min-width: 80px;
  width: 60px;
  font-size: 120%;*/
}

.box_search ul.num li.person ul.multiple li:last-child {
  margin-top: 10px;
}

.box_search ul.num li.person ul.multiple li ul li:last-child {
  margin-top: 0px;
}

.box_search p.button {
  width: auto;
  float: right;
  margin: 12px 0 0 0;
}

.box_search ul {
  font-size: 12px;
}

.box_search ul p {
  font-size: 12px;
  margin: 0 0 5px 0;
}

.box_search ul.date li {
  margin-right: 15px;
  /*padding-right: 15px;
  border-right: solid 1px rgba(0,0,0,0.1);*/
}

.box_search ul.date li.checkin {
  /*padding-right: 5px;*/
}

.box_search ul.date li.checkbox {
  height: 63px;
  line-height: 63px;
  padding-top: 12px;
  margin-right: 30px;
  padding-right: 30px;
  border-right: solid 1px rgba(0, 0, 0, 0.1);
}

.box_search ul.num li.room {
  vertical-align: top;
  margin-right: 30px;
}

.box_search ul.num li.room select.select-box {
  font-size: 14px;
  width: 80px;
}


/**
BOX_DATA
*/

.box_data {
  /*border: solid 1px rgba(0,0,0,0.1);*/
  padding: 10px 10px 0 10px;
  position: relative;
  border-radius: 2px;

  border: solid 2px #4abaa7;
  border-radius: 3px;
}

.box_data ul {
  /*border-right: solid 1px rgba(0,0,0,0.1);
  padding-right: 2em;*/
  text-align: center;
  margin: 3px 0 10px 0;
}

.box_data ul li {
  padding-right: 1em;
  margin-right: 1em;
}

.box_data ul ul {
  border: none;
  margin-right: 0;
  padding-right: 0;
  border: none;
}

/*
.box_search ul.num li.person{
	border-bottom: solid 1px rgba(0,0,0,0.1);
}*/


.box_search ul.num li.person ul li {
  border: none;
}

.box_search ul.num li.person ul li p.room {
  line-height: 34px;
}

.box_search ul.num li.person ul li span.num {
  font-size: 28px;
  margin-right: 3px;
}

.box_data ul p.data {
  font-size: 16px;
  font-weight: bold;
}

.box_data p.price {
  position: absolute;
  right: 150px;
  top: 9px;
  display: inline-block;
  font-size: 150%;
  font-weight: bold;
  margin: 0;
}

.box_data p.price span.caution {
  font-size: 12px;
}

p.price span.yen {
  font-size: 80%;
  margin-right: .2em;
}

.box_data p.error {
  position: absolute;
  right: 15px;
  top: 12px;
  display: inline-block;
  margin: 0;
  line-height: 1.4;
  min-width: 300px;
  font-size: 12px;
  border: solid 1px #F00;
  padding: 5px 8px 2px 8px;
}

.box_data p.button {
  position: absolute;
  right: 10px;
  top: 7px;
  display: inline-block;
  margin: 0;
}

.box_data .add_option {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 12px;
  margin-bottom: 5px;
}

.box_data .add_option p {
  margin: .5em 0 0 0 !important;
}

.box_data .add_option ul {
  list-style: none;
  border: none;
  text-align: left;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 0;
}

.box_data .add_option ul li {
  list-style: disc;
  line-height: 1.5;
  margin: .2em 0;
}


/**
BOX_STEP
*/

.box_step {
  margin-bottom: 20px;
  /*margin-top: 20px;*/
}

.box_step li {
  display: block;
  float: left;
  width: 25%;
  box-sizing: border-box;
  background: #bace91;
  color: #FFF;
  padding: 20px 10px;
  text-decoration: none;
  text-align: center;
  margin: 0;
  border: solid 1px #f7f8f9;
  /*border: solid 1px #f7f8f9;*/
}

.box_step.part li {
  width: 33.333333%;
}

.box_step li span {
  display: block;
}

.box_step li span.select-title {
  padding-bottom: 10px;
  border-bottom: solid 1px #FFF;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 120%;
}

.box_step li span.select-name {
  height: 2em;
}

.box_step li:hover,
.box_step li.active {
  background: #F96;
  cursor: pointer;
}

.box_step li.select-none:hover,
.box_step li.select-none:active {
  background: #bace91;
  cursor: inherit;
}


/**
COMMON
*/

ul.box li h4 {
  border-left: none;
  padding-left: 0;

  height: initial;
  display: inherit;
  vertical-align: inherit;
  line-height: 1.4;
}

ul.box.service li h4,
ul.box.charge li h4 {
  padding-top: .6em;
  padding-bottom: .6em;
  padding-right: 120px;
}


ul.box.min li {
  padding: 0;
  min-height: 120px;
}

ul.box.min li ul li {
  min-height: inherit;
}

ul.box.min li.image_none h4,
ul.box.min li.image_none div.data {
  margin-left: 20px;
}

ul.box.min li p.image {
  float: none;
  display: table-cell;
}

ul.box.min li div.content {
  display: table-cell;
  vertical-align: top;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 130px;
  padding-bottom: 10px;
  height: 120px;
}

ul.box.min li.image_none div.content {
  padding-left: 0;
}

ul.box.min li h4 {
  margin: 0 0 8px 0;
  display: block;
  line-height: 1.3;
}

ul.box li p.image {
  float: left;
  margin: 0px 15px 0 0;
  width: 120px;
  height: 120px;
  text-align: center;
}

ul.box li .data i,
.box.main .data i,
ul.box li p.price i {
  color: #72932b;
}

.block {
  border: none;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  padding: 30px;
}

.column.half {
  width: 48%;
}

.box_inquiry {
  margin: 0 0 10px 0;
  background-color: #fef1da;
}

.title ul.menu li {
  list-style: none;
  display: inline-block;
}

.title ul.menu li a {
  padding: 5px 10px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #FFF;
  text-decoration: none;
}

.title ul.menu li a:hover,
.title ul.menu li.selected a {
  background-color: rgba(0, 0, 0, 0.4);
}

.title ul.menu {
  position: absolute;
  bottom: 3px;
  right: 0;
}


/**
HOME
*/

.main_image {
  height: 305px; /* html要素をウィンドウサイズにフィット */
  overflow: hidden;
  width: 100%;
  background: url(../images/front_main_image.jpg) no-repeat center; /* 背景画像をhtml要素に表示 */
  background-size: cover;
}


/*@media screen and (max-width: 1200px){
  img.bgscale {
  left: 50%;
  margin-left: -600px; }
}*/

.title h1 {
  z-index: 1000;
  font-size: 30px;
  position: absolute;
  top: 235px;
  left: 0;
  color: #FFF;
  text-shadow: 1px 0px 3px #333;
  width: 930px;
}

.title h1 a {
  color: #FFF;
}

.title .main_btn {
  z-index: 999;
  position: absolute;
  top: 250px;
  right: 0;
  margin: 0;
}

.title .main_btn a.btn.size_2l.decision {
  box-shadow: 0px 0px 3px #333;
  -moz-box-shadow: 0px 0px 3px #333;
  -webkit-box-shadow: 0px 0px 3px #333
}


.bx-wrapper .bx-viewport {
  -moz-box-shadow: 0 !important;
  -webkit-box-shadow: 0 !important;
  box-shadow: none !important;
  border: inherit !important;
  left: 0 !important;
  background: inherit !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

#sliderWrap ul li {
  list-style: none;
}

/**
COMMON
*/

.block {
  padding: 30px;
}

.box.title h2,
.box.title h3 {
  color: #72932b;
}

.box.title.fl_left,
.box.title.fl_right {
  display: inline-block;
  width: inherit;
  clear: none;
  margin: 0;
}

.box.title.fl_left h3 {
  /*line-height: 34px;*/
  margin-top: 15px;
}

.box.title.btn-fixed-wrap h3 {
  float: left;
}

.box.title.btn-fixed-wrap .box-btn-fixed {
  float: right;
}

.box.title.btn-fixed-wrap .box-btn-fixed a {
  background: #a6afb0;
  padding: 5px 8px;
  margin: 15px 10px 0 10px;
  display: inline-block;
  font-size: 13px;
  color: #fff;
}

.box.title.btn-fixed-wrap .box-btn-fixed a:hover {
  background-color: rgba(166, 175, 176, 0.8);
  text-decoration: none;
}

/**
FORM
*/


input[type="submit"].btn.decision,
a.btn.decision {
  background-color: #4abaa7;
}

input[type="submit"].btn.decision:hover,
a.btn.decision:hover {
  background-color: #4abaa7;
}


input[type="submit"].btn.search,
a.btn.search {
  background-color: rgba(197, 32, 51, 1.0); /*#c52033*/
}

input[type="submit"].btn.search:hover,
a.btn.search:hover {
  background-color: rgba(197, 32, 51, 0.9);
}


input[type="submit"].btn.buy,
a.btn.buy {
  background-color: rgba(233, 152, 27, 1.0); /*#c52033*/
}

input[type="submit"].btn.buy:hover,
a.btn.buy:hover {
  background-color: rgba(233, 152, 27, 0.9);
}


/**
CONTENTS
*/

/*#main_contents .column{
	float: left;
	width: 455px;
}*/


.head_link {
  text-align: right;
  margin-bottom: 10px;
  margin-top: 10px;
}

.head_link ul li {
  list-style: none;
  display: inline-block;
  margin-left: 2px;
}

.head_link ul li a {
  display: block;
  padding: 10px 5px;
  background-color: #a6afb0;
  color: #FFF;
  text-decoration: none;
}

.head_link ul li a:hover {
  background-color: rgba(166, 175, 176, 0.8);
}


ul.images li {
  list-style: none;
  margin-bottom: 10px;
}


dl.data dt {
  line-height: 1.5;
  font-weight: bold;
  clear: both;
  float: left;
  width: 20%;
  padding: 10px 0;
}

dl.data dd {
  line-height: 1.5;
  width: 80%;
  float: left;
  border-bottom: solid 1px #EEE;
  padding: 10px 0;
}


.box.status ul li.active {
  background-color: #F96;
}


.reserve_steps {
  padding: 2em 0;
  clear: both;
}

.reserve_steps dl {
  float: left;
  text-align: center;
}

.reserve_steps.step_4 dl {
  width: 25%;
}

.reserve_steps.step_3 dl {
  width: 33.3%;
}


.reserve_steps dl dt {
  padding: 0;
  line-height: 1.4;
}

.reserve_steps dl dt em {
  font-size: 130%;
}

.reserve_steps dl dd {
  padding: 10px;
}

.reserve_steps dl dd.result {
  border-top: solid 3px #DDD;
}

.reserve_steps dl dd.icon {
  padding: 10px 0 5px 0;
  color: #DDD;
}

.reserve_steps dl dd i {
  font-size: 30px;
}

.reserve_steps dl.complete,
.reserve_steps dl.complete i {
  color: #999;
}

.reserve_steps dl.complete dd.result {
  border-top: solid 3px #999;
  font-size: 120%;
}

.reserve_steps dl.active,
.reserve_steps dl.active dd i {
  color: #ff8a3b;
}

.reserve_steps dl.active dd.result {
  border-top: solid 3px #ff8a3b;
  font-size: 20px;
}


/**
SIDEBAR
*/

#sidebar .login_last {
  margin: 0 0 1em 0;
  text-align: right;
  font-size: 85%;
}

#sidebar ul.social {
  margin-bottom: 10px;
}

#sidebar ul.social li i {
  font-size: 40px;
  margin: 0 5px;
}


#sidebar .box.orner_info {
  padding: 20px;
  background-color: #e9ecee;
}

#sidebar .box.orner_info:hover {
  background-color: #fcf4e5;
  cursor: pointer;
}

#sidebar .box.orner_info p.image {
  margin: 0;
}

#sidebar .box.orner_info p.image img {
  width: 100%;
}

#sidebar .box.orner_info h3 {
  font-size: 16px;
  margin: 1em 0;
}

#sidebar .box.orner_info p.text {
  font-size: 85%;
  margin-bottom: 2em;
}

#sidebar .box.banner {
  text-align: center;
}

#sidebar .box.banner ul li {
  list-style: none;
  margin-bottom: 10px;
}


/**
SELECT_CALENDAR
*/

.select_calendar table td,
.select_calendar table th {
  /*	position: relative;*/
}

/*.select_calendar span.cover{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	background: rgba(0,0,0,0.3);
}*/

.select_calendar p.month {
  text-align: center;
  font-size: 140%;
  font-weight: bold;
  margin: 0 0 1em 0
}

.select_calendar p.month a {
  margin: 0 1em;
}

.select_calendar table.cal {
  width: 100%;
  /*border-spacing:1px;
  border-top:solid 1px #e3e8ed;
  border-left:solid 1px #e3e8ed;*/
  border-collapse: collapse;
  /*border-collapse: separate;
border-spacing: 2px; */
  font-size: 90%;
}


.select_calendar table.cal th,
.select_calendar table.cal td {
  text-align: center;
  border: solid 2px #FFF;
  background-color: #f6f6f6;
  padding: 10px;
  width: 14%;
}

.select_calendar table.cal.week th,
.select_calendar table.cal.week td {
  width: 12.5%;
  /*border-color: #DDD !important;*/
}

.select_calendar table.cal.week td {
  padding: 0;
}

.select_calendar table.cal.week td p {
  padding: 10px;
}

.select_calendar table.cal.week {
  /*border-collapse: separate;
  border-spacing: 2px;*/
}

.select_calendar table.cal td.non-active {
  /*filter:alpha(opacity=20);
    -moz-opacity: 0.2;
    opacity: 0.2;
  */
  border-bottom: solid 2px #949190 !important;
  border-right: solid 2px #949190 !important;

  pointer-events: none;
}

.select_calendar table.cal td.non-active div.wrap {
  position: relative;
}

.select_calendar table.cal td.non-active div.wrap div:before {
  z-index: 10;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.select_calendar table.cal td.non-active.last {
  border-right: #FFF !important;
}

.select_calendar table.cal td.non-active.bottom {
  /*	border-bottom: #FFF !important;*/
}

/*.select_calendar table.cal td.non-active:before {
    z-index: 0;
    content: "";
    display: block;
    position: absolute;
	top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background-color: rgb(95, 95, 95);
	opacity: 0.3;
  }*/

.select_calendar table.cal td.blank {
  background-color: #FFF;
  pointer-events: none;
}

.select_calendar table.cal th {
  background-color: #FFF;
}

.select_calendar table.cal.week th {
  color: #555;
  font-size: 120% !important;
  font-weight: bold;
  background-color: rgb(221, 221, 221);
}

.select_calendar table.cal.week tr.date th {
  background-color: #FFF;
  color: #555;
  font-size: 120% !important;
  font-weight: bold;
  border: none;
  padding: 0;
  white-space: nowrap;


  background-color: inherit;
  background-color: rgba(161, 153, 141, 1.0);
  padding: 0;
  white-space: nowrap;

}

.select_calendar table.cal.week tr.date th a {
  display: block;
  vertical-align: middle;
  color: #555;
  padding: 0;

  color: #FFF;
  display: block;
  vertical-align: middle;
  padding: 10px;
}

.select_calendar table.cal.week tr.date th a:hover {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.select_calendar table.cal.week tr.date th .sat,
.select_calendar table.cal.week tr.date th .sat a {
  color: rgb(0, 176, 240) !important;
}

.select_calendar table.cal.week tr.date th .sun,
.select_calendar table.cal.week tr.date th .sun a {
  color: rgb(255, 0, 0) !important;
}

.select_calendar table.cal.week tr.date th .hol,
.select_calendar table.cal.week tr.date th .hol a {
  color: rgb(255, 0, 0) !important;
}

.select_calendar table.cal.week tr.date th .thday {
  border: 1px solid rgb(221, 221, 221);
  margin: 1px;
  padding: 12px 10px;
}

.select_calendar table.cal td:hover,
.select_calendar table.cal td:active,
.select_calendar table.cal td.selected {
  border: none;
  background-color: #F96 !important;
  border: solid 2px #FFF;
  cursor: pointer;
  color: #FFF;
}

.select_calendar table.cal.week td.free {
  background-color: rgba(255, 153, 102, 0.15);
}

.select_calendar table.cal td.complete:hover {
  background: none !important;
}

.select_calendar table.cal.week th.time {
  white-space: nowrap;
}

.select_calendar table.cal td:hover p.icon.text span {
  background-color: transparent;
  border: solid 1px #FFF;
  height: 20px;
  line-height: 20px;
}

.select_calendar table.cal td.selected p.icon.cancel.text span {
  border: solid 1px #FFF;
}

.select_calendar table.cal td.full {
  cursor: default;
  pointer-events: none;
}

.select_calendar table.cal td.full i {
  /*font-size: 23px;*/
}

.select_calendar table.cal td span.day {
  font-size: 180%;
  white-space: nowrap;
  font-weight: bold;
}

.select_calendar table.cal td p.icon {
  position: relative;
  /*height: 1.5em;*/

}

.select_calendar table.cal td p.icon i {
  font-size: 20px;
  margin: 0;
  height: 20px;
  line-height: 20px;
  display: block;
}

.select_calendar table.cal.week td p.icon span {
  margin: 0;
}

.select_calendar table.cal td p.icon .genericon {
  height: 20px;
  line-height: 20px;
  display: block;
  font-size: 36px;
  margin-top: 14px;
}

.select_calendar table.cal td p.icon i,
.select_calendar table.cal td p.icon .genericon,
.select_time ul li p.icon .genericon {
  color: rgb(221, 221, 221);
}


.select_calendar table.cal td p.icon.sp,
.select_time ul li p.sp {
  display: none;
}

.select_calendar table.cal td p.icon.text {
  height: 20px;
  line-height: 20px;
  margin-bottom: 0;
}

.select_calendar table.cal td p.icon.text span {
  font-size: 10px;
  padding: 5px 8px;
  background-color: #ff9966;
  border: solid 1px #ff9966;
  color: #FFF;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}


.select_calendar table.cal td {
  /*position: relative;*/
}

.select_calendar table.cal td p.icon.circle i {
  color: #F96;
}

.select_calendar table.cal td.selected p.icon i,
.select_calendar table.cal td:hover p.icon i,
.select_calendar table.cal td.selected p.icon .genericon,
.select_calendar table.cal td.hover p.icon .genericon {
  color: #FFF;
}

.caution.sp {
  display: none;
}

.select_calendar table.cal th {
  text-align: center;
  padding: 5px 0;
}

.select_calendar table.week td .icon.cancel span {
  display: inline-block;
  width: 21px;
  height: 20px;
  background: url(../images/icon_wait_cal.png) no-repeat center center;
  background-size: 21px;
  line-height: 21px;
  vertical-align: top;
  margin-top: -1px;
}

.select_calendar table.week td:hover .icon.cancel span {
  display: inline-block;
  width: 22px;
  height: 21px;
  background: url(../images/icon_wait_cal_on.png) no-repeat center center;
  background-size: 20px;
  line-height: 20px;
  vertical-align: top;
  margin-top: -1px;
}

.select_calendar table.cal td.complete {
  background-color: #FFF !important;
  cursor: inherit;
  color: #EEE;
}

.select_calendar .cal_detail {
  padding: 15px;
  border: solid 2px #f96;
  margin-top: 1em;
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.select_calendar .cal_detail span.icon.delete {
  position: absolute;
  top: 7px;
  right: 3px;
}

.select_calendar .cal_detail span.icon.delete a {
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.select_calendar .cal_detail span.icon.delete a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.select_calendar .cal_detail span.icon.delete a i {
  color: #FFF;
}


.select_calendar .cal_detail dl dt {
  width: 8%;
}

.select_calendar .cal_detail dl dd {
  width: 90%;
}

.select_calendar .cal_detail ul.menu {
  padding: 10px 15px 5px 15px !important;
  border: solid 1px #DDD !important;
}

.select_calendar .cal_detail ul.menu li .checkbox.title {
  width: 100% !important;
}


/**
SELECT_TIME
*/


.select_time h4 {
  clear: both;
  padding: 1em 0 .5em 0;
}

.select_time ul li {
  list-style: none;
  float: left;
  width: 25%;
  background-color: #f6f6f6;
  border: solid 1px #FFF;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.select_time ul li:hover {
  cursor: pointer;
  background-color: #F96;
  color: #FFF;
}

.select_time ul li.selected {
  background-color: #F96;
  color: #FFF;
}

.select_time ul li.selected p.icon .genericon {
  color: #FFF;
}

.select_time ul li.complete {
  background-color: #FFF;
  cursor: inherit;
  color: #CCC;
}

.select_time ul li p.price {
  margin: .5em 0 0 0;
  font-size: 85%;
}

.select_time ul li p.text {
  margin: .5em 0 0 0;
  font-size: 85%;
}


/*.select_time ul li p.icon i{
	font-size: 20px;
}

.select_time ul li p.icon.circle i{
	color: #f96;
}
.select_time ul li.selected p.icon i,
.select_time ul li:hover p.icon i{
	color: #FFF;
}

*/

.select_time ul li.full {
  cursor: default;
  pointer-events: none;
}

.select_time ul li p.icon {
  margin: 0;
  height: 20px;
  line-height: 20px;
}

/*.select_time ul li p.icon .genericon{
	height: 20px;
	line-height: 20px;
	display: block;
	font-size: 36px;
}

.select_time ul li p.icon.text span{
	font-size: 85%;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	height: 20px;
	line-height: 20px;
}
.select_time ul li:hover p.icon.text span{
	background-color: transparent;
}
*/


.select_time ul li.available {
  background: url(../images/icon_circle-outline.png) center center no-repeat;
  background-size: 40px;
  background-color: #f6f6f6;
}

.select_time ul li.available:hover {
  background: url(../images/icon_circle-outline_on.png) center center no-repeat;
  background-size: 40px;
  background-color: #F96;
}

.select_time ul li.wait {
  background: url(../images/icon_wait.png) center center no-repeat;
  background-size: 40px;
  background-color: #f6f6f6;
}

.select_time ul li.wait:hover {
  background: url(../images/icon_wait_on.png) center center no-repeat;
  background-size: 40px;
  background-color: #F96;
}

.select_time ul li.selected {
  background: url(../images/icon_check-symbol.png) center center no-repeat;
  background-size: 40px;
  background-color: #F96;
}

.select_time ul li.full {
  background: url(../images/icon_close-button.png) center center no-repeat;
  background-size: 40px;
  background-color: #f6f6f6;
}


.select_time ul.half li {
  width: 50%;
}

.bottom_decision {
  font-weight: bold;
  font-size: 150%;
  color: #F96;
  text-align: center;
}


@media(min-width: 801px) and (max-width: 1230px) {

  /**
  BASIC
  */

  #wrapper {
    width: 97%;
  }

  #main_contents {
    width: 100%;
  }

  #sidebar {
    width: 27%;
  }

  .inner {
    width: 97%;
  }


  /**

  */
  .title h1 {
    max-width: 78%;
    font-size: 25px;
  }


  .box_step li span.select-title {
    font-size: 100%;
  }

  .box_step li span.select-name {
    font-size: 70%;
  }

  .select_calendar table.cal td p.icon.pc,
  .select_time ul li p.pc {
    display: none;
  }

  .select_calendar table.cal td p.icon.sp,
  .select_time ul li p.sp {
    display: block !important;
  }
  .select_calendar td .icon.cancel span {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(../images/icon_wait_cal.png) no-repeat center center;
    background-size: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-top: -1px;
  }
  .select_calendar td:hover .icon.cancel span {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(../images/icon_wait_cal_on.png) no-repeat center center;
    background-size: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-top: -1px;
  }

  .select_calendar td.selected .icon.cancel span {
    background: url(../images/icon_wait_cal_on.png) no-repeat center center;
    background-size: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-top: -1px;
  }

  .select_calendar table.cal td p.icon.sp i,
  .select_time ul li p.sp i {
    font-size: 130%;
  }

  .select_calendar table.cal td p.icon .genericon,
  .select_time ul li p.icon .genericon {
    margin-top: 15px;
  }


}


@media screen and (max-width: 800px) {

  .box_step li span.select-title {
    font-size: 120%;
  }

  .box_step li span.select-name {
    font-size: 100%;
  }

  #header {
    margin-bottom: 10px !important;
  }

  #header h1 {
    height: 36px;
    line-height: 36px;
  }

  #header h1 span.logo img {
    max-height: 30px;
    max-width: 100px;
    margin-right: 5px;
  }

  #header h1 span.title {
    font-size: 13px;
  }

  .box.title.fl_left {
    width: 100%;
  }
  .box.title.fl_left h3 {
    margin: 0 0 8px 0;
  }

  .box.title.btn-fixed-wrap .box-btn-fixed a {
    margin: 0 0 8px 2px;
    font-size: 11px;
  }

  .head_link.fl_right {
    float: none;
  }


  #header .box.info .panel-wrap {
    margin: 0 0 1em 0;
  }

  #header .menu_setting .panel-wrap {
    top: 72px;
    right: 0;
  }

  #header .head_front .panel-wrap {
    top: 3px;
  }

  #header .head_front .panel-wrap .name {
    display: none;
  }

  #header .title h1 {
    font-size: 20px;
    top: 110px;
    left: 0;
    color: #FFF;
    text-shadow: 1px 0px 3px #333;
    display: block;
    text-align: center;
    max-width: 100%;
    z-index: 999;
  }


  /**
  LAYOUT
  */

  #wrapper,
  #main_contents,
  #sidebar,
  .inner {
    float: none;
    width: 100%;
  }

  #sidebar {
  }


  /**
  BOX_SEARCH
  */

  .box_search ul.date,
  .box_search ul.num {
    margin-right: 0;
  }

  .box_search ul.date {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    margin: 0 0 1em 0;
  }
  .box_search ul.date li {
    margin: 0 0 1em 0;
  }
  .box_search ul.date li input[type="text"].date {
    width: 100% !important;
  }


  .box_search p.button {
    width: auto;
    float: none;
    margin: 15px 0 0 0;
    text-align: center;
  }
  .box_search p.button input {
    width: 100%;
  }


  /**
  BOX_DATA
  */

  .box_search.block {
    padding: 10px;
  }

  .box_search .box_data ul {
    margin-right: 0 !important;
    /*margin-bottom: 5px !important;*/
  }

  .box_search .box_data ul p {
    font-size: 10px;
  }
  .box_search .box_data ul p.data {
    font-size: 12px;
  }
  .box_search .box_data ul.date li.checkin p.data,
  .box_search .box_data ul.date li.checkout p.data {
    font-size: 14px;
  }
  .box_search .box_data ul.num li.room p.data span.num,
  .box_search .box_data ul.num li.person p.data span.num {
    font-size: 15px;
  }


  .box_search ul.date {
    width: 100%;
    border-bottom: none;
    margin-bottom: .5em;
    border-right: none;
  }
  .box_search ul.date li {
    width: 47%;
  }
  .box_search ul.date li.checkbox {
    display: block;
    clear: both;
    margin-right: 0;
    height: auto;
    line-height: inherit;
    border: none;
    /*padding-right: 0;
    border: none;*/
  }
  .box_search ul.date li.checkbox {
  }
  .box_search ul.num {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }
  .box_search ul.num li.room {
    width: 30%;
  }
  .box_search ul.num li.person {
    width: 63%;
    margin-right: 0;
  }
  .box_search.detail ul.num li.person {
    width: 100%;
    margin-right: 0;
  }

  .box_search ul.num li {
    margin-right: 20px;
  }
  .box_search.detail ul.num li {
    margin-right: 5px;
  }

  .box_search ul.num li.person ul.multiple li ul li {
    /*min-width: 75px;*/
  }
  .box_search ul.num li.person ul li
  .box_search ul.num.rooms li.person {
    width: 100%;
    margin-right: 0;
  }
  .box_search ul.num.rooms li.person ul.multiple li ul li {
    min-width: auto;
    width: 22.4%;
    margin-bottom: 2em;
  }
  .box_search ul.num.rooms li.person ul.multiple li ul li select {
    width: 100%;
  }
  .box_search ul.num.rooms li.person ul.multiple li ul {
    display: block;
  }


  .box_search .box_data ul.date {
    width: 70%;
  }
  .box_search .box_data ul.date li {
    width: 45%;
    text-align: center;
    white-space: nowrap;
  }
  .box_search .box_data ul.num {
    width: 28%;
  }
  .box_search .box_data ul.num li {
    width: 40% !important;
    text-align: center;
    white-space: nowrap;
  }

  .box_search .box_data ul.date li.checkout,
  .box_search .box_data ul.num li.person {
    margin-right: 0;
  }

  .box_data {
    padding: 10px;
  }

  .box_data ul {
    margin: 0 0 5px 0;
  }

  .box_search ul {
    width: 100%;
    /*margin: 0 1em 0 0;*/
  }

  .box_search.detail input[type="submit"].btn.full,
  .box_search.detail button.full,
  .box_search.detail a.btn.full,
  input[type="submit"].btn.full.auto {
    width: 100%;
    margin: 0 auto;
  }

  .box_search.detail div.photo {
    height: 200px;
  }

  .box_search ul p {
    font-size: 10.5px;
  }

  .box_search .box_data ul.date {
    margin: 0;
  }

  .box_search ul.date li {
    margin: 0 .5em 0 0;
    padding-right: .5em;
    /*width: 50%;*/
  }


  .box_search ul.num li.room {
    margin-right: 8px !important;
    padding-right: 0 !important;
  }

  .box_search p.price {
    border-top: solid 1px rgba(0, 0, 0, 0.05);
    padding: 1em 0 0 0;
  }

  .box_data p.price,
  .box_data p.error,
  .box_data p.button {
    position: inherit;
    text-align: center;
    right: inherit;
    top: inherit;
    width: 100%;
  }


  .box_data p.price,
  .box_data p.button {
    margin-top: 0;
    padding-top: 0;
  }

  .box_data p.price span.caution {
    display: inherit !important;
  }


  /**
  COMMON_TEXT
  */

  .box.title.h2_main {
    padding: 10px 5px;
    margin-bottom: 10px;
  }

  hr {
    margin: 1em 0;
  }

  /**
  HOME
  */

  .main_image {
    height: 240px;
  }

  .title .main_btn {
    top: 190px;
  }

  .title h1 {
    position: absolute;
    top: 60px;
    left: 10px;
  }

  .title .main_btn {
    position: inherit;
    text-align: center;
  }

  .box_step {
    margin-bottom: 0;
  }

  .box_step li {
    display: none;
  }

  .box_step li.active {
    display: block;
    width: 100%;
  }


  /**
  LIST
  */

  ul.list.staff li {
    float: none;
    margin: 10px auto;
    padding: 10px;
  }


  ul.list.gallery li p.image img {
    width: 100%;
  }

  ul.box.service li h4,
  ul.box.charge li h4 {
    padding: 0 0 .2em 0;
  }


  ul.box.min li {
    height: auto;
  }
  ul.box.min li div.content {
    padding-right: 10px;
    padding-top: 15px;
    vertical-align: top;
    height: auto;
  }

  ul.box.min li p.button {
    padding: 10px 0 20px 0;
  }

  ul.box.min li h4 {
    padding-right: 0;
  }


  /**
  CONTENTS
  */


  .head_link {
    clear: both;
    text-align: center;
    margin-bottom: 10px;
  }


  dl.data dt {
    display: block;
    float: none;
    width: 100%;
  }
  dl.data dd {
    display: block;
    float: none;
    width: 100%;
    padding-top: 0;
  }

  .reserve_steps dl dt em {
    display: block;
  }

  .reserve_steps dl.complete dd.result {
    font-size: 100%;
  }

  /**
  CALENDAR
  */

  .select_calendar table.cal td span.day {
    font-size: 120%;
    font-weight: normal;
  }

  .select_calendar table.cal td p.icon i,
  .select_time ul li p.icon i {
    font-size: 13px;
  }
  .select_calendar table.week td .icon.cancel span {
    background-size: 13px !important;
    line-height: 13px;
    padding: 0;
  }
  .select_calendar table.week td:hover .icon.cancel span {
    background-size: 13px;
    line-height: 13px;
  }
  .select_calendar table.cal td p.icon,
  .select_time ul li p.icon {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .select_calendar table.cal td p.icon .genericon,
  .select_time ul li p.icon .genericon {
    font-size: 22px;
    margin-top: 13px;
    padding: 0;
  }

  .select_calendar table.cal td p.icon.pc,
  .select_time ul li p.icon.pc,
  .select_time ul li p.text.pc {
    display: none !important;
  }
  .select_calendar table.cal td p.icon.sp,
  .select_time ul li p.sp {
    display: block !important;
  }
  .select_calendar table.cal td p.icon.sp i,
  .select_time ul li p.sp i {
    font-size: 11px;
  }

  .select_calendar td .icon.cancel span {
    display: inline-block;
    width: 21px;
    height: 21px;
    background: url(../images/icon_wait_cal.png) no-repeat center center;
    background-size: 13px;
    vertical-align: top;
    margin-top: 0px;
  }
  .select_calendar td:hover .icon.cancel span {
    width: 21px;
    height: 21px;
    background: url(../images/icon_wait_cal.png) no-repeat center center;
    background-size: 13px;
    vertical-align: top;
    margin-top: 0px;
  }

  .select_calendar td.selected .icon.cancel span {
    background: url(../images/icon_wait_cal_on.png) no-repeat center center;
    background-size: 13px;
    vertical-align: top;
    margin-top: 0px;
  }

  .select_calendar table.cal.week th,
  .select_calendar table.cal.week td {
    width: 12%;
  }
  .select_calendar table.cal.week th.time {
    width: 16%;
  }

  .select_calendar table.cal.week tr.date th a {
    //padding: 10px 2px;
  }
  .select_calendar table.cal.week tr.date th span {
    display: inline-block;
    line-height: 1.3;
    font-size: 80%;
  }

  .select_calendar p.month {
    font-size: 120%;
  }

  .select_calendar.price td {
    padding: 10px 0 !important;
  }

  .select_calendar.price td p.price {
    font-size: 10px;
  }


  .select_calendar table.cal td:hover {
    border: none;
    background-color: #f6f6f6 !important;
    cursor: inherit;
    color: inherit;
  }
  .select_calendar table.cal td:hover p.icon i,
  .select_calendar td:hover .icon span {
    color: #F96;
  }
  .select_calendar table.cal td.complete:hover {
    color: #EEE;
  }
  .select_calendar table.cal td.selected:hover {
    background: #F96 !important;
    color: #FFF !important;
  }


  .select_time ul li.available {
    background-size: 40px;
  }

  .select_time ul li.available:hover {
    background-size: 40px;
  }
  .select_time ul li.wait {
    background-size: 46px;
  }
  .select_time ul li.wait:hover {
    background-size: 46px;
  }

  .select_time ul li.selected {
    background-size: 40px;
  }

  .select_time ul li.full {
    background-size: 34px;
  }

  .caution.sp {
    display: block;
  }
  .caution.sp .cancel {
    font-size: 80%;
  }
  .caution.sp .cancel img {
    height: 10px;
  }
  .caution.sp .circle i {
    color: #F96;
  }

  .block {
    padding: 10px;
  }


  /**
  SELECT_CALENDAR
  */

  .select_calendar p.month {
    font-size: 120%;
  }


  @media screen and (max-width: 800px) {
    .fancybox-inner {
      max-height: 600px;
    }
  }
}